import DashboardLayout from "src/layout/DashboardLayout";
import {ArrowBackV2} from "src/assets/icons";
import {Space, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import AimInformation from '../AimInformation'
import AimListLevel2 from "src/pages/AimDetail/AimList/AimListLevel2";
import AimListLevel3 from "src/pages/AimDetail/AimList/AimListLevel3";
import {
  AimLevelEnums,
  ChildrenAimStatusType,
  HavingChildrenEnum,
  ManageTypesRoutes,
  SendResultTypeEnum
} from "src/utils/enums";
import {useNavigate, useParams} from "react-router";
import {handleGetAimDetail} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleGetListAction} from "src/services/actions.services";
import SendResult from "src/components/Form/ActionLevel3/SendResult";
import Button from "src/components/Button";
import {resetAimDetail} from "src/reducers/aim.slice";

const AimDetailLevel2 = ({ manaTypeRoute }: { manaTypeRoute: ManageTypesRoutes }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {aimIdLevel1, aimIdLevel2} = useParams();

  const {aimLevel2Detail, aimDetail} = useAppSelector((state) => state.aim);
  const [loading, setLoading] = useState(false)

  const {actionList} = useAppSelector((state) => state.action);

  const [openSentResultAction, setOpenSentResultAction] = useState(false);

  const isSendOrApprovedResultLevel1 = useMemo(() => {
    if(!aimDetail) return;
    return aimDetail.statusResult === ChildrenAimStatusType.APPROVED_RESULT ||
      aimDetail.statusResult === ChildrenAimStatusType.SEND_RESULT ||
      aimDetail.statusGoal === ChildrenAimStatusType.REGISTER_GOAL;
  }, [aimDetail]);

  useEffect(() => {
    if (!aimIdLevel2) return;
    setLoading(true);
    dispatch(handleGetAimDetail({id: aimIdLevel1 as string, level: AimLevelEnums.LEVEL_1}));
    dispatch(handleGetAimDetail({id: aimIdLevel2, level: AimLevelEnums.LEVEL_2}))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
          dispatch(handleGetListAction({goal: res.id}));
        }
      })
      .catch(() => setLoading(false))
    ;
  }, [aimIdLevel1, aimIdLevel2, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAimDetail(AimLevelEnums.LEVEL_2))
    }
  }, [])

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
          <div>
            <Typography className="dashboard-title">Chi tiết mục tiêu cấp 2</Typography>
            <Typography
              style={{
                color: '#737373',
                fontSize: '12px'
              }}
            >
              Mục tiêu/ {aimLevel2Detail?.parent?.name}/ <span
              style={{color: '#525252', fontWeight: 600}}>{aimLevel2Detail?.name || ''}</span>
            </Typography>
          </div>
        </Space>
      }
      actionButtons={
        [
          aimLevel2Detail?.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN &&
          !isSendOrApprovedResultLevel1 &&
          !(aimDetail?.statusGoal === ChildrenAimStatusType.REGISTER_GOAL || aimDetail?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL) &&
          <Button
            onClick={() => setOpenSentResultAction(true)}
            type="primary"
            key='sent-result'
          >
            Nộp kết quả
          </Button>
        ]
      }
    >
      <AimInformation level={AimLevelEnums.LEVEL_2} aimDetail={aimLevel2Detail} loading={loading}/>

      {
        aimLevel2Detail && aimLevel2Detail.levelCalculated === HavingChildrenEnum.HAVING_CHILDREN ? (
          <AimListLevel2
          isHidingButton={aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL}
            parentId={aimDetail?.id}
            childAimList={aimLevel2Detail?.childrens || []}
            manaTypeRoute={manaTypeRoute}
          />
        ) : (
          <AimListLevel3
            parentId={aimLevel2Detail?.id}
            childAimList={actionList}
            aimDetail={aimLevel2Detail}
          />
        )
      }

      {/*---------------------------- Nộp kết quả hành động mục tiêu cấp 2 nếu ko có muc tiêu cấp 3 -----------------------------------*/}
      <SendResult
        open={openSentResultAction}
        sendResultType={SendResultTypeEnum.AIM}
        detail={aimLevel2Detail}
        successCb={() => {
          dispatch(handleGetAimDetail({id: aimIdLevel2 as string, level: AimLevelEnums.LEVEL_2}));
          setOpenSentResultAction(false);
        }}
        errorCb={() => {
          setOpenSentResultAction(false);
        }}
      />
    </DashboardLayout>
  )
}

export default AimDetailLevel2;
