import {useEffect, useMemo} from 'react';
import dayjs from 'dayjs';
import {Radio, Space, Typography} from 'antd';
import {Controller, useFormContext} from 'react-hook-form';

import Input, {formatNumberFromString} from 'src/components/Input';
import DatePicker from 'src/components/DatePicker';
import ErrorMessage from 'src/components/ErrorMessage';
import {NUMBER_REGEX, NUMBER_REGEX_EXT} from 'src/utils/regex';
import {handleDateValidation} from 'src/utils/functions';
import Select from 'src/components/Select';
import {useAppSelector} from 'src/redux/hooks';
import {
  HavingChildrenEnum,
  SentResultActionType,
  StatusTrendEnum,
  TypeMeasureSelections,
  UnitSelections,
} from 'src/utils/enums';

const Form = () => {
  const {control, watch, trigger} = useFormContext();
  const {refAimList} = useAppSelector(state => state.aim);

  const fromDate = watch('fromDate');
  const toDate = watch('toDate');
  const typeMeasure = watch('typeMeasure');

  const referenceOptions = useMemo(() => {
    return refAimList?.data?.map(item => ({
      label: item.name as string,
      value: item.id,
    }));
  }, [refAimList]);

  useEffect(() => {
    if (fromDate || toDate) {
      trigger('fromDate');
      trigger('toDate');
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Loại mục tiêu <span style={{color: '#FF3141'}}>*</span>
      </Typography>

      <Controller
        control={control}
        name="levelCalculated"
        rules={{
          required: 'Vui lòng chọn loại mục tiêu',
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Radio.Group
                style={{
                  display: 'flex',
                }}
                onChange={e => {
                  onChange(e.target.value);
                }}
                value={value}>
                <Radio value={HavingChildrenEnum.HAVING_CHILDREN}>Có mục tiêu con</Radio>
                <Radio value={HavingChildrenEnum.NO_HAVING_CHILDREN}>Không có mục tiêu con</Radio>
              </Radio.Group>
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message} />}
            </div>
          );
        }}
      />

      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Tên mục tiêu <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="name"
        rules={{
          required: 'Vui lòng điền thông tin',
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Input value={value} onChange={onChange} placeholder="Nhập tên mục tiêu" />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message} />}
            </div>
          );
        }}
      />

      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Mục tiêu tham chiếu
      </Typography>

      <Controller
        control={control}
        name="reference"
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="--- Chọn mục tiêu tham chiếu ---"
                options={referenceOptions}
                value={value}
                onSelect={value => onChange(value)}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message} />}
            </div>
          );
        }}
      />

      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Tỷ trọng mục tiêu (%) <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="proportion"
        rules={{
          required: 'Vui lòng điền tỷ trọng mục tiêu',
          max: {
            value: 100,
            message: 'Tỷ trọng phải bé hơn hoặc bằng 100',
          },
          min: {
            value: 1,
            message: 'Tỷ trọng phải lớn hơn 0',
          },
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Input
                value={value}
                onChange={value => {
                  if (NUMBER_REGEX.test(value) || value === '') {
                    onChange(value);
                  }
                }}
                placeholder="Nhập tỷ trọng mục tiêu"
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message} />}
            </div>
          );
        }}
      />

      <Space
        style={{
          marginTop: '12px',
          width: '100%',
          justifyContent: 'space-between',
          gap: 12,
        }}
        styles={{item: {flex: 1}}}>
        <Space style={{width: '100%'}} direction="vertical">
          <Typography style={{color: '#525252'}}>
            Ngày bắt đầu <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={control}
            name="fromDate"
            rules={{
              required: 'Vui lòng chọn ngày',
              validate: () => {
                const fromDateTimestamp = dayjs(fromDate).valueOf();
                const toDateTimestamp = dayjs(toDate).valueOf();
                return handleDateValidation(fromDateTimestamp, toDateTimestamp);
              },
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <>
                  <DatePicker
                    value={value}
                    placeholder="--- Chọn ngày ---"
                    onChange={value => onChange(value)}
                    style={{
                      width: '100%',
                    }}
                  />
                  {fieldState?.error?.message && (
                    <ErrorMessage error={fieldState?.error?.message} />
                  )}
                </>
              );
            }}
          />
        </Space>

        <Space style={{width: '100%'}} direction="vertical">
          <Typography style={{color: '#525252'}}>
            Ngày kết thúc <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={control}
            name="toDate"
            rules={{
              required: 'Vui lòng chọn ngày',
              validate: () => {
                const fromDateTimestamp = dayjs(fromDate).valueOf();
                const toDateTimestamp = dayjs(toDate).valueOf();
                return handleDateValidation(fromDateTimestamp, toDateTimestamp);
              },
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <>
                  <DatePicker
                    value={value}
                    placeholder="--- Chọn ngày ---"
                    onChange={value => onChange(value)}
                    style={{
                      width: '100%',
                    }}
                  />
                  {fieldState?.error?.message && (
                    <ErrorMessage error={fieldState?.error?.message} />
                  )}
                </>
              );
            }}
          />
        </Space>
      </Space>

      {/*---------------------------------------------------------------------------------*/}
      {/*Khi muc tiêu cấp 2 ko có mục tiêu con thì sẽ cho chọn cách thức đo lường*/}
      <Typography style={{color: '#525252', marginTop: '12px', marginBottom: '4px'}}>
        Cách đo lượng mục tiêu <span style={{color: '#FF3141'}}>*</span>
      </Typography>

      <Controller
        control={control}
        name="typeMeasure"
        rules={{
          required: 'Vui lòng chọn cách thức đo lường',
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="--- Chọn hình thức đo kết quả ---"
                options={TypeMeasureSelections}
                value={value}
                onSelect={value => onChange(value)}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message} />}
            </div>
          );
        }}
      />

      {/* {levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN && ( */}
      <>
        {typeMeasure?.value === SentResultActionType.NUMBER && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 8,
              padding: 16,
              backgroundColor: '#eee',
              borderRadius: '6px',
              marginTop: '16px',
            }}>
            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Con số mục tiêu <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="measure"
                rules={{
                  required: 'Vui lòng điền con số mục tiêu',
                  min: {
                    value: 1,
                    message: 'Con số mục tiêu phải lớn hơn 0',
                  },
                }}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <div>
                      <Input
                        value={formatNumberFromString(
                          typeof value === 'number' ? value.toString() : value,
                        )}
                        onChange={value => {
                          if (NUMBER_REGEX_EXT.test(value) || value === '') {
                            onChange(value);
                          }
                        }}
                        placeholder="Nhập con số mục tiêu"
                      />
                      <Typography style={{fontSize: '9px', marginTop: '4px'}}>
                        VD: 1,000,000.012 (012 là phần thập phân)
                      </Typography>
                      {fieldState?.error?.message && (
                        <ErrorMessage error={fieldState?.error?.message} />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Đơn vị tính <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="unit"
                rules={{
                  required: 'Vui lòng đơn vị tính',
                }}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <div>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        placeholder="--- Chọn đơn vị tính ---"
                        options={UnitSelections}
                        value={value}
                        onSelect={value => onChange(value)}
                      />
                      {fieldState?.error?.message && (
                        <ErrorMessage error={fieldState?.error?.message} />
                      )}
                    </div>
                  );
                }}
              />
            </div>

            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Xu hướng <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="statusTrend"
                rules={{
                  required: 'Vui lòng chọn xu hướng',
                }}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <div>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        placeholder="--- Chọn xu hướng ---"
                        options={[
                          {
                            label: 'Xu hướng tăng',
                            value: StatusTrendEnum.UP,
                          },
                          {
                            label: 'Xu hướng giảm',
                            value: StatusTrendEnum.DOWN,
                          },
                        ]}
                        value={value}
                        onSelect={value => onChange(value)}
                      />
                      {fieldState?.error?.message && (
                        <ErrorMessage error={fieldState?.error?.message} />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}

        {typeMeasure?.value === SentResultActionType.STATUS && (
          <Typography
            style={{
              backgroundColor: '#eee',
              borderRadius: '6px',
              padding: '16px 4px',
              textAlign: 'center',
              marginTop: '12px',
            }}>
            Kết quả sẽ là <span style={{color: '#00B578'}}>hoàn thành</span> hoặc{' '}
            <span style={{color: '#FF3141'}}>chưa hoàn thành</span>
          </Typography>
        )}
      </>
      {/* // )} */}
    </div>
  );
};

export default Form;
