import {Button, Table, Tooltip} from 'antd';
import * as XLSX from 'xlsx';

import moment from 'moment';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {
  Fields,
  GoalEntity,
  excelDateToJSDate,
  rowsToGoals,
  validateColumns,
  validateRow,
} from './utils';

interface Props {
  goals: GoalEntity[];
  onChange: (a: GoalEntity[]) => void;
  onExcelError: (a: boolean) => void;
}
export const SelectedFile: FC<Props> = ({goals, onChange, onExcelError}) => {
  const [file, setFile] = useState<File | undefined>();
  const [rows, setRows] = useState<string[][]>([]);
  const [overall_error, setOverallError] = useState<string | undefined>();
  const [errors, setErrors] = useState<any[]>([]);
  const inputRef = useRef<any>(null);

  const validateFile = useCallback(
    (rows: string[][]) => {
      const msg = validateColumns(rows[0]);
      if (msg) {
        toast.error(msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      rows.shift();
      setRows(rows);
      onChange(rowsToGoals(rows) as GoalEntity[]);
      setErrors(rows.map(validateRow));
    },
    [onChange],
  );
  const compare = (a: number, b: number) => {
    return Math.abs(a - b) <= 0.0001;
  };


  const getOverallError = useCallback(() => {
    if (!file) {
      return undefined;
    }
    if (!goals || goals.length === 0) {
      return undefined;
    }
    const sum_percents = goals.reduce((sum, item) => (sum += item.proportion ?? 0), 0);
    if (!compare(sum_percents, 1)) {
      return 'Tổng tỷ trọng các mục tiêu cấp 2 phải là 100%';
    }
    const invalidGoal = goals.find(item => {
      if (!item.children || item.children.length === 0) {
        return false;
      }
      const sum_percents = item.children?.reduce((sum, item) => (sum += item.proportion ?? 0), 0);
      if (!compare(sum_percents, 1)) {
        return true;
      }
    });
    if (invalidGoal) {
      return `Mục tiêu cấp 2: "${invalidGoal.name.substring(
        0,
        Math.min(40, invalidGoal.name.length),
      )}..." có tổng tỷ trọng các mục tiêu cấp 3 không bằng 100%`;
    }

    const goal_error = errors.find((error: any) =>
      Object.values(error).find(item => item !== undefined),
    );
    if (goal_error) {
      return 'Vui lòng kiểm tra các ô chưa hợp lệ trong bảng dưới';
    }
    return undefined;
  }, [errors, file, goals]);

  useEffect(() => {
    setOverallError(getOverallError());
  }, [getOverallError]);

  const readFile = useCallback(() => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, {
        type: 'array',
        // cellDates: true,
        // cellNF: false,
        // cellText: false,
      });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows: any = XLSX.utils
        .sheet_to_json(worksheet, {header: 1})
        .slice(1)
        .filter((row: any) => row.length > 0);

      validateFile(rows);
    };
    reader.readAsArrayBuffer(file);
  }, [file, validateFile]);
  console.log('Overall erro: ', overall_error);
  useEffect(() => {
    onExcelError(overall_error != null);
  }, [onExcelError, overall_error]);

  const columns = Fields.map(({column_index, title, column_name, width, key, is_date}) => ({
    title: title ?? column_name,
    dataIndex: column_index,
    key,
    ...(width ? {width} : {}),
    render: (value: number, row: string[][], index: number) => {
      const error = errors[index][key];
      const content = is_date ? moment(excelDateToJSDate(value)).format('MM/DD/YYYY') : value;
      if (!error) {
        return <span style={{color: '#333'}}>{content}</span>;
      }
      return (
        <Tooltip title={error}>
          <span style={{color: '#FF150F'}}>{content}</span>
        </Tooltip>
      );
    },
  }));

  const getRowStyle = (record: any) => {
    return {
      backgroundColor:
        record[0] === 'Mục tiêu Cấp 2'
          ? '#00B578' + '33'
          : record[0] === 'Mục tiêu Cấp 3'
          ? '#00B578' + '11'
          : '#fff',
    };
  };
  useEffect(() => {
    readFile()
  }, [readFile])
  

  const no_goal = goals.length === 0;
  useEffect(() => {
    if (no_goal) {
      setRows([]);
      setFile(undefined);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [no_goal]);

  return (
    <div style={{marginTop: '10px', maxHeight: '600px', overflowY: 'scroll'}}>
      <input
        ref={inputRef}
        type="file"
        onChange={e => {
          setFile(e.target.files?.[0]);
        }}
      />
      {overall_error && (
        <div style={{fontSize: '16px', color: '#FF150F', marginTop: '20px'}}>
          {'*' + overall_error}{' '}
        </div>
      )}
      {!no_goal && (
        <Table
          dataSource={
            rows.map((item, index) => ({
              key: index,
              ...item,
            })) as any
          }
          columns={columns}
          onRow={record => ({
            style: getRowStyle(record),
          })}
          pagination={false}
          rowKey='id'
        />
      )}
    </div>
  );
};
