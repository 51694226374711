import {Space, Typography} from "antd";
import Input from "src/components/Input";
import React, {useMemo} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {FilterParams} from "src/hooks/useFilter";
import {debounce} from "lodash";
import Select from "src/components/Select";
import {useAppSelector} from "src/redux/hooks";
import {DropdownSelectProps} from "src/utils/types";

interface Props {
  onFilterToQueryString: (params: FilterParams) => void;
  filter: FilterParams
}

const PeriodListFilter = ({ onFilterToQueryString, filter }: Props) => {
  const { control } = useFormContext();
  const {myPeriodList} = useAppSelector((state) => state.period);

  const debouncedSearch = debounce(async (query) => {
    onFilterToQueryString({ ...filter, ...query })
  }, 300);

  async function updateQuery(query: FilterParams) {
    debouncedSearch(query);
  }

  const periodListFilter = useMemo(() => {
    return myPeriodList.data.map(item => ({
      label: item.name || '',
      value: item.id as string
    }))
  }, [myPeriodList.data])

  return (
    <Space style={{ padding: '8px 16px', borderRadius: '6px', backgroundColor: '#fff', width: '100%', gap: '32px' }}>
      <Space>
        <Typography>Mục tiêu</Typography>
        <Controller
          control={control}
          name="search"
          render={({field: {onChange, value}}) => {
            return (
              <Input
                type="search"
                style={{
                  width: '305px'
                }}
                value={value}
                onChange={(value) => {
                  updateQuery({ search: value })
                  onChange(value)
                }}
                placeholder="Tìm kiếm mục tiêu"
              />
            )
          }}
        />
      </Space>
      <Space>
        <Typography>Giai đoạn</Typography>
        <Controller
          control={control}
          name="phase"
          render={({field: {onChange, value}}) => {
            return (
              <div>
                <Select
                  style={{
                    width: '305px'
                  }}
                  placeholder="--- Chọn giai đoạn ---"
                  options={periodListFilter}
                  value={value}
                  onSelect={(value) => {
                    if (!value) {
                      onChange(undefined);
                      updateQuery({ phase: undefined })
                      return;
                    }
                    const phaseId = (value as DropdownSelectProps)?.value
                    updateQuery({ phase: phaseId })
                    onChange(value)
                  }}
                />
              </div>
            )
          }}
        />
      </Space>
    </Space>
  )
}

export default React.memo(PeriodListFilter);