import { Modal, Space, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { ChildrenAim, EditVWhite } from "src/assets/icons";
import Button from "src/components/Button";
import Table from "src/components/Table";
import { useFilter } from "src/hooks/useFilter";
import useRole from "src/hooks/useRole";
import DashboardLayout from "src/layout/DashboardLayout";
import { ProgressItem } from "src/pages/AimDetail/AimList/AimListLevel3";
import ManagerByMeFilter from "src/pages/ManagedByMe/ManagedByMeFilter";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { handleGetAimList } from "src/services/aims.services";
import { ChildrenAimStatusType, EmployeeTypeEnum, LeaderFilter } from "src/utils/enums";
import { handleChildrenAimState } from "src/utils/functions";
import { CreateAimResponseType } from "src/utils/types";
import ManagedByMeDetail from "./ManagedByMeDetail";

const ManagedByMe = () => {
  const { isReadEmployeeGoal, isWatchAndApprovedLeaderGoal } = useRole();
  const methodsFilter = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { aimList, loading } = useAppSelector((state) => state.aim);
  const { organizationList } = useAppSelector((state) => state.permission);
  const { periodList } = useAppSelector((state) => state.period);
  const [approveId, setApproveId] = useState<string>()

  const { filter, onFilterToQueryString } = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetAimList(filter));
    },
  })

  const displayPopup = (goal: any) => {
    if (goal.statusGoal == undefined) {
      return false
    }
    return ![ChildrenAimStatusType.DRAFT_GOAL].includes(
      goal.statusGoal
    )
  }
  const columns: ColumnsType<CreateAimResponseType> = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 20,
      render: (_, action) => {
        return (
          <div>
            {displayPopup(action) && (
              <Tooltip title="Duyệt nhanh mục tiêu">
                <Button
                  onClick={() => setApproveId(action.id)}
                  icon={<ChildrenAim />}
                  style={{
                    padding: 0,
                    border: "none",
                    boxShadow: "none",
                    width: "28px",
                    height: "28px",
                    marginRight: "8px"
                  }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Tên mục tiêu',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (_, action) => {
        return (
          <Tooltip title="Xem chi tiết + duyệt">
            <Typography.Text
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/managed-by-me/${action?.id}`)}>
              {action?.name || "-"}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, action) => {
        return (
          <Space>
            <Typography>{action?.created_by?.name || '-'}</Typography>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
      key: 'role',
      render: (_, action) => {
        return (
          <Space>
            <span
              style={{
                backgroundColor: action?.isGoalLeader ? '#FF3141' : '#52c41a',
                fontSize: '12px',
                lineHeight: '20px',
                padding: '2px 6px',
                color: '#fff',
                borderRadius: '6px',
              }}>
              {action?.isGoalLeader ? 'Leader' : 'Nhân viên'}
            </span>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Phòng ban',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, action) => {
        return (
          <div>{action?.organization?.name || '-'}</div>
        );
      },
      width: 225,
    },
    {
      title: 'Giai đoạn',
      dataIndex: 'phase',
      key: 'phase',
      width: 220,
      render: (_, action) => {
        const { phase } = action;
        return (
          <Typography>{phase?.name || '-'}</Typography>
        );
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'proportion',
      key: 'proportion',
      width: 150,
      render: (_, action) => {
        return (
          <ProgressItem data={action} />
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, { statusGoal, statusResult }) => {
        const status = handleChildrenAimState(statusGoal as ChildrenAimStatusType, statusResult as ChildrenAimStatusType);
        return (
          <Tag
            style={{
              color: status.textColor,
              backgroundColor: status.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none'
            }}
          >
            {status.value}
          </Tag>
        );
      },
    }
  ];

  useEffect(() => {
    const _organizationList = filter.organization;
    const periodSelected = periodList.data.find(item => item.id === filter?.phase);
    const employeeType = LeaderFilter.find(item => item.value === filter?.employeeType) || {
      label: 'Tất cả',
      value: EmployeeTypeEnum.ALL
    };
    methodsFilter.setValue('employeeType', employeeType)
    methodsFilter.setValue('phase', periodSelected ? {
      label: periodSelected?.name as string,
      value: periodSelected?.id as string
    } : undefined)
    methodsFilter.setValue('organization', _organizationList)
    methodsFilter.setValue('statusGoal', [...(filter?.statusGoal ? filter?.statusGoal as any : []), ...(filter?.statusResult ? filter?.statusResult as any : [])])
  }, [filter.organization, filter?.employeeType, organizationList, methodsFilter, filter?.phase, filter?.statusGoal, periodList.data]);

  // useEffect(() => {
  //   if (approveId != null) {
  //     return
  //   }

  //   dispatch(handleGetAimList(filter));
  // }, [approveId, dispatch, filter])

  if (!isReadEmployeeGoal && !isWatchAndApprovedLeaderGoal) {
    return <></>;
  }

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Mục tiêu quản lý bởi tôi</Typography.Text>
      }
      filterBox={
        <FormProvider {...methodsFilter}>
          <form>
            <ManagerByMeFilter onFilterToQueryString={onFilterToQueryString} filter={filter} />
          </form>
        </FormProvider>
      }>
      {approveId && (
        <Modal
          open={true}
          title="Duyệt nhanh mục tiêu"
          height={"70vh"}
          width={"85vw"}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => setApproveId(undefined)}
          onCancel={() => setApproveId(undefined)}>
          <Space
            style={{
              display: "flex",
              flex: 1
            }}>
            <ManagedByMeDetail
              goal_id={approveId}
              styles={{
                gap: 0,
                padding: 6,
                maxWidth: "80vw"
              }}
            />
          </Space>
        </Modal>
      )}
      <Table
        loading={loading}
        columns={columns}
        data={aimList?.data}
        total={aimList?.pagination?.total || 0}
        pagination={filter}
        handleItemPerPage={value => {
          onFilterToQueryString({ ...filter, per_page: Number(value.value) });
        }}
        handlePageNumber={value => {
          onFilterToQueryString({ ...filter, page: value });
        }}
        rowKey='id'
      />
    </DashboardLayout>
  );
}

export default ManagedByMe
