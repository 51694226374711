import { Progress, Space, Tooltip, Typography } from "antd";
import Button from "src/components/Button";
import { Delete, Edit, Plus, SentResult } from "src/assets/icons";
import React, { useMemo, useState } from "react";
import Modal from "src/components/Modal";
import { ChildrenAimStatusType, SendResultTypeEnum, SentResultActionType } from "src/utils/enums";
import { CreateAimResponseType, Pagination } from "src/utils/types";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { handleDeleteAction, handleGetListAction } from "src/services/actions.services";
import { ColumnsType } from "antd/es/table/interface";
import Table from "src/components/Table";
import CreateEditAction from "src/components/Form/ActionLevel3/CreateEdit";
import SendResult from "src/components/Form/ActionLevel3/SendResult";
import { handleCompletedPercent } from "src/utils/functions";
import { toast } from "react-toastify";
import { formatNumber } from "src/components/Input";

interface Props {
  parentId?: string;
  childAimList?: Pagination<CreateAimResponseType>;
  aimDetail: CreateAimResponseType | undefined
}

interface AimItemProps {
  data: CreateAimResponseType;
}

export const ProgressItem = ({ data }: AimItemProps) => {
  const result = useMemo(() => {
    return handleCompletedPercent(data);
  }, [data])
  const percent = +(result?.percent || 0)?.toFixed(2);

  return (
    <div style={{ width: 200 }}>
      <Progress
        format={() => `${percent}%`}
        percent={percent}
        strokeColor="#00B578"
        style={{ width: '100%', margin: 0 }}
      />
      <Typography style={{ fontWeight: 500 }}>{result?.measure}</Typography>
    </div>
  )
}

const AimListLevel3 = ({ parentId, childAimList }: Props) => {
  const dispatch = useAppDispatch();
  const [openCreateEditAction, setOpenCreateEditAction] = useState(false);
  const [openDeleteAction, setOpenDeleteAction] = useState(false);
  const [openSentResultAction, setOpenSentResultAction] = useState(false);

  const [selectedAction, setSelectedAction] = useState<CreateAimResponseType | undefined>(undefined);
  const { aimDetail: aimLevel1Detail } = useAppSelector((state) => state.aim);
  const { userInfo } = useAppSelector((state) => state.user);

  const successCb = () => {
    setSelectedAction(undefined);
    dispatch(handleGetListAction({ goal: parentId as string }));
  }

  const deleteAction = () => {
    if (!selectedAction) return;
    dispatch(handleDeleteAction(selectedAction?.id))
      .unwrap()
      .then(() => {
        setOpenDeleteAction(false);
        successCb();
        toast.success("Xóa hành động thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        setOpenDeleteAction(false);
        setSelectedAction(undefined);
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  const isApprovedLevel1 = useMemo(() => {
    if (!aimLevel1Detail) return;
    return aimLevel1Detail.statusGoal === ChildrenAimStatusType.APPROVED_GOAL
  }, [aimLevel1Detail]);

  const isSendOrApprovedResultLevel1 = useMemo(() => {
    if (!aimLevel1Detail) return;
    return aimLevel1Detail.statusResult === ChildrenAimStatusType.APPROVED_RESULT ||
      aimLevel1Detail.statusResult === ChildrenAimStatusType.SEND_RESULT;
  }, [aimLevel1Detail]);

  const columns: ColumnsType<CreateAimResponseType> = useMemo(() => {
    const defaultColumns: ColumnsType<CreateAimResponseType> = [
      {
        title: 'Tên hành động',
        dataIndex: 'name',
        key: 'name',
        width: 350,
        render: (_, action) => {
          return (
            <>
              <Typography style={{ fontWeight: 500 }}>{action.name}</Typography>
              <Typography style={{ fontSize: 12, color: '#737373' }}>Ngày
                tạo: {dayjs(action.created_at).format('DD/MM/YYYY')}</Typography>
            </>
          );
        },
      },
      {
        title: 'Con số mục tiêu',
        dataIndex: 'measure',
        key: 'measure',
        width: 150,
        render: (_, action) => {
          const { measure, result } = action;

          return (
            action.typeMeasure === SentResultActionType.STATUS ?
              <Typography>{`${result === 1 ? 'Hoàn thành 100%' : 'Chưa hoàn thành'}`}</Typography> :
              <Typography>{`${formatNumber(measure || 0)}`}</Typography>
          );
        },
      },
      {
        title: 'Thời gian bắt đầu',
        dataIndex: 'fromDate',
        key: 'fromDate',
        width: 180,
        render: (_, action) => {
          const { fromDate } = action;
          return (
            <Typography>{dayjs(fromDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Thời gian kết thúc',
        dataIndex: 'toDate',
        key: 'toDate',
        width: 180,
        render: (_, action) => {
          const { toDate } = action;
          return (
            <Typography>{dayjs(toDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Tỷ lệ hoàn thành',
        dataIndex: 'result',
        key: 'result',
        width: 252,
        render: (_, action) => {
          return (
            <ProgressItem data={action} />
          );
        },
      },
    ];

    if (isSendOrApprovedResultLevel1) return defaultColumns
    else return [
      ...defaultColumns,
      {
        title: 'Thao tác',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, action) => {
          const isSentResult = typeof action?.result === 'number'
          const isDisplayAction = isApprovedLevel1 || isSentResult
          return (
            <Space>
              {
                !isDisplayAction && (
                  <Tooltip placement="bottomRight" title="Chỉnh sửa">
                    <Button
                      style={{ display: 'flex', alignItems: 'center', padding: 0, border: 'none' }}
                      onClick={() => {
                        setOpenCreateEditAction(true);
                        setSelectedAction(action);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                )
              }

              {
                !isDisplayAction && (
                  <Tooltip placement="bottomRight" title="Xóa">
                    <Button
                      onClick={() => {
                        setOpenDeleteAction(true);
                        setSelectedAction(action);
                      }}
                      style={{ display: 'flex', alignItems: 'center', padding: 0, border: 'none' }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                )
              }

              {!isSendOrApprovedResultLevel1 &&
                !(aimLevel1Detail?.statusGoal === ChildrenAimStatusType.REGISTER_GOAL || aimLevel1Detail?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL) && (
                  <Tooltip placement="bottomRight" title="Nộp kết quả">
                    <Button
                      style={{ display: 'flex', alignItems: 'center', padding: 0, border: 'none' }}
                      onClick={() => {
                        setOpenSentResultAction(true);
                        setSelectedAction(action);
                      }}
                    >
                      <SentResult />
                    </Button>
                  </Tooltip>
                )}
            </Space>
          )
        },
        width: 150
      },
    ]
  }, [isApprovedLevel1, isSendOrApprovedResultLevel1])

  return (
    <Space className="aim-information" direction="vertical">
      <Space style={{ padding: 16, borderBottom: '1px solid #EEEEEE', width: '100%', justifyContent: 'space-between' }}>
        <Typography style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252' }}>Hành
          động</Typography>

        {
          !isApprovedLevel1 && userInfo.id === aimLevel1Detail?.user?.id && (
            <Button
              onClick={() => {
                setOpenCreateEditAction(true);
                setSelectedAction(undefined);
              }}
              style={{ padding: '3px 8px', backgroundColor: '#2560E5', display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <Plus />
              <Typography.Text style={{ color: '#fff' }}>Thêm hành động</Typography.Text>
            </Button>
          )
        }
      </Space>

      <Space style={{ padding: 16, width: '100%' }} direction="vertical">
        <Table
          loading={false}
          data={childAimList?.data || []}
          columns={columns}
          isHidePagination
          rowKey='id'
        />
      </Space>

      {/*---------------------------- Tạo hành động trong mục tiêu con cấp 3 -----------------------------------*/}
      <CreateEditAction
        data={selectedAction}
        selectedItem={selectedAction?.id}
        parentId={parentId}
        open={openCreateEditAction}
        errorCb={() => {
          setOpenCreateEditAction(false);
          setSelectedAction(undefined)
        }}
        successCb={() => {
          successCb()
          setOpenCreateEditAction(false)
        }}
      />

      {/*----------------- Xóa hành động ---------------------*/}
      <Modal
        open={openDeleteAction}
        modalType='confirm'
        title="Xóa hành động"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={deleteAction}
        onCancel={() => {
          setOpenDeleteAction(false);
          setSelectedAction(undefined);
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xoá hành động này
        </Typography>
      </Modal>

      {/*---------------------------- Nộp kết quả hành động mục tiêu cấp 3 -----------------------------------*/}
      <SendResult
        open={openSentResultAction}
        sendResultType={SendResultTypeEnum.ACTION}
        detail={selectedAction}
        successCb={() => {
          setSelectedAction(undefined);
          setOpenSentResultAction(false);
          dispatch(handleGetListAction({ goal: parentId as string }));
        }}
        errorCb={() => {
          setOpenSentResultAction(false);
          setSelectedAction(undefined);
        }}
      />
    </Space>
  )
}
export default AimListLevel3
