const ArrowBack = (props: any) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6087_34992)">
        <rect width="20" height="20" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.53353 15.5001C7.65853 15.6251 7.81686 15.6834 7.9752 15.6834C8.13353 15.6834 8.3002 15.6251 8.41686 15.5001C8.65853 15.2584 8.65853 14.8584 8.41686 14.6168L4.4251 10.625H17.0835C17.4252 10.625 17.7085 10.3417 17.7085 10C17.7085 9.65833 17.4252 9.375 17.0835 9.375H4.42529L8.41686 5.38343C8.65853 5.14176 8.65853 4.74176 8.41686 4.5001C8.1752 4.25843 7.7752 4.25843 7.53353 4.5001L2.4752 9.55843C2.23353 9.8001 2.23353 10.2001 2.4752 10.4418L7.53353 15.5001Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_6087_34992">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowBack