import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'src/reducers/auth.slice'
import periodReducer from 'src/reducers/period.slice'
import aimReducer from 'src/reducers/aim.slice'
import actionReducer from 'src/reducers/action.slice'
import userReducer from 'src/reducers/user.slice'
import permissionReducer from 'src/reducers/permission.slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    period: periodReducer,
    aim: aimReducer,
    action: actionReducer,
    user: userReducer,
    permission: permissionReducer
  },
  devTools: process.env.NODE_ENV !== "production",
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch