const EditWhite = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="28" height="26" rx="3.5" fill="white"/>
      <rect x="0.5" y="1" width="28" height="26" rx="3.5" stroke="#EEEEEE"/>
      <g clipPath="url(#clip0_7004_164844)">
        <rect width="15" height="15" transform="translate(7 6.5)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4625 18.6992C10.0812 18.6992 9.72499 18.568 9.46874 18.3242C9.14374 18.018 8.98749 17.5555 9.04374 17.0555L9.27499 15.0305C9.31874 14.6492 9.54999 14.143 9.81874 13.868L14.0958 9.34083L14.1036 9.33266L14.95 8.43674C16.2312 7.08049 17.5687 7.04299 18.925 8.32424C20.2812 9.60549 20.3187 10.943 19.0375 12.2992L13.9062 17.7305C13.6437 18.0117 13.1562 18.2742 12.775 18.3367L10.7625 18.6805C10.7243 18.6827 10.6878 18.6858 10.652 18.6888H10.652C10.5881 18.6941 10.5266 18.6992 10.4625 18.6992ZM10.5 14.5055L14.1989 10.5858C14.6611 11.8165 15.6659 12.7677 16.9191 13.1638L13.2187 17.0805C13.0937 17.2117 12.7937 17.3742 12.6125 17.4055L10.6 17.7492C10.3937 17.7805 10.225 17.743 10.1125 17.6367C9.99999 17.5305 9.94999 17.3617 9.97499 17.1555L10.2062 15.1305C10.2312 14.9492 10.375 14.6367 10.5 14.5055ZM18.35 11.6492L17.6591 12.3805C16.3135 12.1522 15.2408 11.1282 14.9455 9.79464L15.6312 9.06799C16.0562 8.61799 16.475 8.31799 16.9562 8.31799C17.3437 8.31799 17.775 8.51799 18.275 8.99924C19.4062 10.0617 19.125 10.8242 18.35 11.6492ZM8.875 20.7183H20.125C20.3813 20.7183 20.5938 20.5058 20.5938 20.2495C20.5938 19.9933 20.3813 19.7808 20.125 19.7808H8.875C8.61875 19.7808 8.40625 19.9933 8.40625 20.2495C8.40625 20.5058 8.61875 20.7183 8.875 20.7183Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_7004_164844">
          <rect width="15" height="15" fill="white" transform="translate(7 6.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditWhite;