import Modal from 'src/components/Modal';
import {FormProvider, useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import Form from './Form';
import {CreateAimResponseType, CreateAimType} from 'src/utils/types';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import dayjs from 'dayjs';
import {handleCreateNewAim, handleEditAim} from 'src/services/aims.services';
import {
  SentResultActionType,
  StatusTrendEnum,
  TypeMeasureSelections,
  UnitSelections,
} from 'src/utils/enums';
import {toast} from 'react-toastify';

interface Props {
  parentId?: string;
  selectedItem?: CreateAimResponseType;
  open: boolean;
  successCb?: () => void;
  errorCb?: () => void;
  closeCb?: () => void;
}

const defaultValues: CreateAimType = {
  name: '',
  fromDate: undefined,
  toDate: undefined,
  parent: null,
  user: '',
  proportion: 0,
  levelCalculated: undefined,
  measure: undefined,
  unit: undefined,
  statusTrend: undefined,
};

export const formatNumber = (val: any) => {
  if (val === undefined || val === null) return 0;
  if (typeof val === 'number') return val;
  if (typeof val === 'string') {
    const number = parseFloat(val.replace(/,/g, ''));

    return isNaN(number) ? 0 : number; // Trả về NaN nếu không chuyển đổi được
  }
};

const CreateOrEditAimLevel2 = ({
  parentId,
  selectedItem,
  open,
  successCb,
  errorCb,
  closeCb,
}: Props) => {
  const {aimLevel2Detail} = useAppSelector(state => state.aim);

  const dispatch = useAppDispatch();
  const {userInfo} = useAppSelector(state => state.user);

  const [loading, setLoading] = useState(false);

  const aimLevel2Methods = useForm({
    defaultValues,
  });

  const handleSuccess = (res: CreateAimResponseType) => {
    const messageNotice = res?.messageNotice;
    aimLevel2Methods.reset(defaultValues);
    setLoading(false);
    successCb && successCb();
    if (selectedItem) {
      toast.success('Chỉnh sửa mục tiêu cấp 2 thành công', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success('Tạo mới mục tiêu cấp 2 thành công', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    messageNotice &&
      toast.warning(messageNotice, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
  };

  const handleError = (err: any) => {
    setLoading(false);
    const message = err?.response?.data?.message;
    toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    errorCb && errorCb();
  };

  const createNewAimLevel2 = (data: CreateAimType) => {
    setLoading(true);
    const levelCalculated = data.levelCalculated;

    let formData: CreateAimType = {
      name: data?.name,
      proportion: Number(data.proportion),
      parent: parentId as string,
      reference: data?.reference ? data?.reference?.value : null,
      user: userInfo?.id,
      fromDate: dayjs(data.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(data.toDate).format('YYYY-MM-DD'),
      levelCalculated: data?.levelCalculated,
      unit: null,
      measure: null,
    };

    // if (levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {

    const typeMeasure = data?.typeMeasure?.value;

    if (typeMeasure === SentResultActionType.NUMBER) {
      formData = {
        ...formData,
        statusTrend: data?.statusTrend?.value as string,
        measure: formatNumber(data?.measure),
        unit: data?.unit?.value,
        typeMeasure,
      };
    }
    if (typeMeasure === SentResultActionType.STATUS) {
      formData = {
        ...formData,
        measure: 1,
        typeMeasure,
      };
    }
    // }

    if (selectedItem) {
      dispatch(handleEditAim({...formData, id: selectedItem?.id}))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    } else dispatch(handleCreateNewAim(formData)).unwrap().then(handleSuccess).catch(handleError);
  };

  useEffect(() => {
    if (!aimLevel2Detail) {
      aimLevel2Methods.reset(defaultValues);
      return;
    }

    const levelCalculated = aimLevel2Detail?.levelCalculated;
    const typeMeasure = TypeMeasureSelections.find(
      item => item.value === aimLevel2Detail?.typeMeasure,
    );
    const unit = UnitSelections.find(item => item.value === aimLevel2Detail?.unit);

    aimLevel2Methods.reset({
      name: aimLevel2Detail?.name,
      fromDate: dayjs(aimLevel2Detail?.fromDate),
      toDate: dayjs(aimLevel2Detail?.toDate),
      parent: parentId,
      proportion: Number(aimLevel2Detail?.proportion) * 100,
      reference: selectedItem?.reference
        ? {
            label: selectedItem?.reference?.name,
            value: selectedItem?.reference?.id,
          }
        : undefined,
      levelCalculated,
      ...// levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN &&
      {
        typeMeasure,
        measure: typeMeasure?.value === SentResultActionType.NUMBER ? aimLevel2Detail?.measure : 0,
        unit,
        statusTrend: {
          label:
            aimLevel2Detail?.statusTrend === StatusTrendEnum.DOWN
              ? 'Xu hướng giảm'
              : 'Xu hướng tăng',
          value: aimLevel2Detail?.statusTrend,
        },
      },
    });
  }, [aimLevel2Detail, aimLevel2Methods, parentId, selectedItem]);

  if (!open) return null;

  return (
    <FormProvider {...aimLevel2Methods}>
      <form onSubmit={aimLevel2Methods.handleSubmit(createNewAimLevel2)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          title={selectedItem ? 'Chỉnh sửa mục tiêu cấp 2' : 'Tạo mục tiêu cấp 2'}
          okText={selectedItem ? 'Cập nhật' : 'Xác nhận'}
          cancelText="Hủy"
          onCancel={() => {
            aimLevel2Methods.reset(defaultValues);
            closeCb && closeCb();
          }}
          style={{
            minWidth: '800px',
          }}>
          <Form />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default React.memo(CreateOrEditAimLevel2);
