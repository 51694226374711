import {useEffect, useMemo, useState} from 'react';

import {useAppDispatch} from 'src/redux/hooks';
import Select, {AutocompleteProps} from 'src/components/Select';
import {handleGetListOrganizationByRole} from 'src/services/permissions.services';

interface Props extends Omit<AutocompleteProps, 'options'> {
  organizationId?: string;
}

const SelectOrganization = ({organizationId, ...props}: Props) => {
  const [organizations, setOrganizations] = useState([]);

  const dispatch = useAppDispatch();

  const organizationListFilter = useMemo(() => {
    return organizations?.map((item: any) => ({
      label: item.name || '',
      value: item.id as string,
    }));
  }, [organizations]);

  useEffect(() => {
    const params = organizationId ? {organization: organizationId} : undefined;
    dispatch(handleGetListOrganizationByRole(params))
      .unwrap()
      .then((res: any) => {
        setOrganizations(res?.data);
      })
      .catch(() => {
        setOrganizations([]);
      });
  }, [dispatch, organizationId]);

  return <Select style={{flex: 5}} options={organizationListFilter} {...props} />;
};

export default SelectOrganization;
