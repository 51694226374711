import {useAppSelector} from "src/redux/hooks";
import {PermissionListEnum} from "src/utils/enums";
import {useMemo} from "react";

function useRole() {
  const {userInfo} = useAppSelector((state) => state.user);

  const permission = useMemo(() => {
    if (!userInfo) return;
    return userInfo?.permission
  }, [userInfo])

  const isAdmin =  useMemo(() => {
    if (!userInfo) return;
    return userInfo?.is_admin
  }, [userInfo])

  const isEmployee =  useMemo(() => {
    if (!userInfo) return;
    return !userInfo?.is_admin
  }, [userInfo])

  //  tạo, sửa, xóa mục tiêu cá nhân
  const isActionWithOwnerGoal = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.OWNER_PERMISSION_GOAL);
  }, [permission])

  // Duyệt mục tiêu leader
  const isWatchAndApprovedLeaderGoal = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_LEADER_GOAL);
  }, [permission])

  // Duyệt mục tiêu nhân viên
  const isApprovedEmployeeGoal = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_EMPLOYEE_GOAL);
  }, [permission])

  // Duyệt mục tiêu bản thân
  const isApprovedOwnerGoal = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_OWNER_GOAL);
  }, [permission])

  // Xác nhận kết quả của leader (làm sau)
  const isApprovedLeaderResult = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_LEADER_RESULT);
  }, [permission])

  // Xác nhận kết quả của nhân viên (làm sau)
  const isApprovedEmployeeResult = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_EMPLOYEE_RESULT);
  }, [permission])

  // Xác nhận kết quả của bản thân
  const isApprovedOwnerResult = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.APPROVED_OWNER_RESULT);
  }, [permission])

  // Xem mục tiêu của các cấp dưới
  const isReadEmployeeGoal = useMemo(() => {
    if (!permission) return;
    return permission?.goals?.includes(PermissionListEnum.READ_EMPLOYEE_GOAL);
  }, [permission])

  //  xem giai đoạn, tạo sửa xóa giai đoạn
  const isActionWithPeriod = useMemo(() => {
    if (!permission) return;
    return permission?.phases?.includes(PermissionListEnum.OWNER_PERMISSION_PHASE);
  }, [permission])

  //  quyền xuất file excel
  const isExportExcel = useMemo(() => {
    if (!permission) return;
    return permission?.report?.includes(PermissionListEnum.EXPORT_REPORT_GOAL);
  }, [permission])

  return {
    isAdmin,
    isEmployee,
    isActionWithOwnerGoal,
    isWatchAndApprovedLeaderGoal,
    isApprovedEmployeeGoal,
    isApprovedOwnerGoal,
    isApprovedLeaderResult,
    isApprovedEmployeeResult,
    isApprovedOwnerResult,
    isReadEmployeeGoal,
    isActionWithPeriod,
    isExportExcel
  }
}

export default useRole
