import React, { ReactNode, Ref } from 'react';
import type { PaginationProps, TableProps } from 'antd';
import { Pagination, Space, Table as TableAnt, Typography } from 'antd';
import { TableRowSelection } from "antd/es/table/interface";
import { NextButtonPagination, PrevButtonPagination } from "src/assets/icons";
import Select from "../Select";
import { AnyObject } from "antd/es/_util/type";
import { DropdownSelectProps } from "src/utils/types";
import './styles.scss'
import { FilterParams } from "src/hooks/useFilter";

interface Props<DataType> extends Omit<TableProps<DataType>, 'column' | 'onChange' | 'rowSelection' | 'pagination'> {
  data: AnyObject[] | undefined
  columns: AnyObject[] | undefined
  handleChange?: TableProps<AnyObject>['onChange']
  handleSelectedRows?: TableRowSelection<AnyObject>['onChange']
  handleItemPerPage?: (value: DropdownSelectProps) => void
  handlePageNumber?: (pageNum: number) => void
  selectedRowKeys?: React.Key[];
  isCheckbox?: boolean;
  total?: number;
  pagination?: FilterParams;
  isHidePagination?: boolean
}

function Table<DataType extends TableProps<AnyObject> & { children?: ReactNode, name?: string } & {
  ref?: Ref<HTMLDivElement> | undefined;
}>(props: Props<DataType>) {
  const {
    data,
    columns,
    handleChange,
    handleSelectedRows,
    selectedRowKeys,
    handleItemPerPage,
    handlePageNumber,
    isCheckbox = false,
    expandable,
    isHidePagination = false,
    total = 0,
    pagination = {
      per_page: 10,
      page: 1
    },
    ...rest
  } = props;

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectedRows,
  };

  const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
    if (type === 'page') {
      return page
    }
    if (type === 'jump-next' || type === 'jump-prev') {
      return <span>...</span>
    }
    return originalElement;
  };

  return (
    <div className="custom-table">
      <TableAnt
        rowKey='id'
        rowSelection={isCheckbox ? rowSelection : undefined}
        columns={columns}
        dataSource={data}
        onChange={handleChange}
        expandable={expandable}
        pagination={false}
        scroll={{
          x: "max-content",
        }}
        locale={{
          emptyText: (
            <Typography>Không có dữ liệu</Typography>
          ),
        }}
        {...rest}
      />
      {
        !isHidePagination && (
          <Space style={{
            display: 'flex',
            alignItems: 'center',
            padding: '12px 16px',
            justifyContent: 'space-between',
            backgroundColor: '#fff'
          }}>
            <Space>
              <Typography.Text style={{ color: '#737373' }}>Số hàng trên trang:</Typography.Text>
              <Select
                value={{ label: String(pagination?.per_page), value: String(pagination?.per_page) }}
                // showSearch={false}
                options={[
                  { label: '5', value: '5' },
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                ]}
                onSelect={(value) => handleItemPerPage && handleItemPerPage(value as DropdownSelectProps)}
                className="items-per-page"
                suffixIcon={
                  <NextButtonPagination style={{ rotate: '90deg' }} />
                }
                allowClear={false}
              />
            </Space>
            <Typography.Text>Hiển thị 1-10 trên số {total}</Typography.Text>
            {
              <Pagination
                showSizeChanger={false}
                defaultPageSize={10}
                pageSize={pagination?.per_page || 10}
                total={total || 0}
                itemRender={itemRender}
                className={"pagination"}
                prevIcon={<PrevButtonPagination />}
                nextIcon={<NextButtonPagination />}
                onChange={(page: number) => {
                  handlePageNumber && handlePageNumber(page)
                }}
                current={pagination?.page || 1}
              />
            }
          </Space>
        )
      }
    </div>
  );
}

export default React.memo(Table);