import React from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { DatePickerIcon } from "src/assets/icons";
import type { PickerProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import './styles.scss'

type DateCustom = PickerProps<Dayjs>

const DatePickerCustom = (props: Omit<DateCustom, 'onChange'> & { onChange: (value: Dayjs | Dayjs[], date: string | string[]) => void }) => {
  const {
    placeholder,
    onChange,
    value,
    ...rest
  } = props;

  return (
    <DatePickerAntd
      placeholder={placeholder}
      suffixIcon={<DatePickerIcon />}
      rootClassName="date-picker"
      allowClear={false}
      inputReadOnly
      value={value}
      onChange={(value: any, date) => onChange && onChange(value, date)}
      renderExtraFooter={() => null}
      // disabledDate={(current) => {
      //   return dayjs().add(-1, 'days')  >= current
      // }}
      {...rest}
    />
  )
}

export default React.memo(DatePickerCustom);
