import {Progress, Space, Tag, Typography} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {ChildrenAimStatusType} from 'src/utils/enums';
import {handleChildrenAimState} from 'src/utils/functions';

import {CreateAimResponseType} from 'src/utils/types';

interface GetReportColumns {
  navigate: any;
}

export const getReportColumns = ({
  navigate,
}: GetReportColumns): ColumnsType<CreateAimResponseType> => {
  return [
    {
      title: 'Tên mục tiêu cấp 1',
      dataIndex: 'name',
      key: 'name',
      width: 400,
      render: (_, action) => {
        return (
          <Typography.Text
            style={{cursor: 'pointer'}}
            onClick={() => navigate(`/report/${action?.id}`)}>
            {action?.name || '-'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, action) => {
        return (
          <Space>
            <Typography>{action?.created_by?.name || '-'}</Typography>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Vai trò',
      dataIndex: 'role',
      key: 'role',
      render: (_, action) => {
        return (
          <Space>
            <span
              style={{
                backgroundColor: action?.isGoalLeader ? '#FF3141' : '#52c41a',
                fontSize: '12px',
                lineHeight: '20px',
                padding: '2px 6px',
                color: '#fff',
                borderRadius: '6px',
              }}>
              {action?.isGoalLeader ? 'Leader' : 'Nhân viên'}
            </span>
          </Space>
        );
      },
      width: 250,
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'username',
      key: 'username',
      width: 220,
      render: (_, action) => {
        return <Typography>{action?.created_by?.username || '-'}</Typography>;
      },
    },
    {
      title: 'Phòng ban',
      dataIndex: 'organization',
      key: 'organization',
      render: (_, action) => {
        return <div>{action?.organization?.name || '-'}</div>;
      },
      width: 225,
    },
    {
      title: 'Giai đoạn',
      dataIndex: 'phase',
      key: 'phase',
      width: 220,
      render: (_, action) => {
        const {phase} = action;
        return <Typography>{phase?.name || '-'}</Typography>;
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'proportion',
      key: 'proportion',
      width: 280,
      render: (_, action) => {
        const percent = +((action?.result || 0) * 100).toFixed(2);
        return (
          <div style={{width: 200}}>
            <Progress
              format={() => `${percent}%`}
              percent={percent}
              strokeColor="#00B578"
              style={{width: '100%', margin: 0, fontSize: 14}}
            />
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, {statusGoal, statusResult}) => {
        const status = handleChildrenAimState(
          statusGoal as ChildrenAimStatusType,
          statusResult as ChildrenAimStatusType,
        );
        return (
          <Tag
            style={{
              color: status.textColor,
              backgroundColor: status.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none',
            }}>
            {status.value}
          </Tag>
        );
      },
    },
    {
      title: 'Số mục tiêu c2',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, action) => {
        return <Typography>{action?.lv2GoalCount || 0}</Typography>;
      },
    },
    {
      title: 'Số mục tiêu c3',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, action) => {
        return <Typography>{action?.lv3GoalCount || 0}</Typography>;
      },
    },
    {
      title: 'Số lượng hành động',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 200,
      render: (_, action) => {
        return <Typography>{action?.actionCount || 0}</Typography>;
      },
    },
  ];
};
