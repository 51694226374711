import {createSlice} from '@reduxjs/toolkit'
import {CreateAimResponseType, Pagination} from "src/utils/types";
import {AimLevelEnums} from "src/utils/enums";
import {isEmpty} from "lodash";

export interface AimState {
  aimList: Pagination<CreateAimResponseType>
  reportList: Pagination<CreateAimResponseType>
  refAimList: Pagination<CreateAimResponseType>
  myAimList: Pagination<CreateAimResponseType>
  aimDetail: CreateAimResponseType | undefined
  aimLevel2Detail: CreateAimResponseType | undefined
  aimLevel3Detail: CreateAimResponseType | undefined
  loading: boolean
}

const initialState: AimState = {
  loading: false,
  aimList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  reportList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  refAimList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  aimDetail: undefined,
  aimLevel2Detail: undefined,
  aimLevel3Detail: undefined,
  myAimList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
}

export const aimSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getAimList(state: AimState, action) {
      if (isEmpty(action.payload.data)) state.aimList = initialState.aimList
      else state.aimList = action.payload.data
    },
    getReportList(state: AimState, action) {
      if (isEmpty(action.payload.data)) state.aimList = initialState.aimList
      else state.reportList = action.payload.data
    },
    getReferenceAimList(state: AimState, action) {
      if (isEmpty(action.payload.data)) state.refAimList = initialState.refAimList
      else state.refAimList = action.payload.data
    },
    getMyAimList(state: AimState, action) {
      if (isEmpty(action.payload.data)) state.myAimList = initialState.myAimList
      else state.myAimList = action.payload.data
    },
    getAimDetail(state: AimState, action) {
      state.aimDetail = action.payload.data
    },
    getAimLevel2Detail(state: AimState, action) {
      state.aimLevel2Detail = action.payload.data
    },
    getAimLevel3Detail(state: AimState, action) {
      state.aimLevel3Detail = action.payload.data
    },
    resetAimDetail(state: AimState, action) {
      if (action.payload === AimLevelEnums.LEVEL_1) {
        state.aimDetail = undefined
      }
      if (action.payload === AimLevelEnums.LEVEL_2) {
        state.aimLevel2Detail = undefined
      }
      if (action.payload === AimLevelEnums.LEVEL_3) {
        state.aimLevel3Detail = undefined
      }
    },
    startLoading(state: AimState) {
      state.loading = true;
    },
    finishLoading(state: AimState) {
      state.loading = false;
    },
  }
})

export const {
  getAimList,
  getReferenceAimList,
  getMyAimList,
  getAimDetail,
  getAimLevel2Detail,
  getAimLevel3Detail,
  resetAimDetail,
  startLoading,
  finishLoading,
  getReportList
} = aimSlice.actions
export default aimSlice.reducer
