import {ReactNode} from "react"
import {Space} from "antd";
import './styles.scss';

interface Props {
  children: ReactNode | string;
  header: ReactNode;
  // title: string;
  actionButtons?: ReactNode[];
  filterBox?: ReactNode;
  styles?: any
}

const DashboardLayout = (props: Props) => {
  const {children, filterBox, actionButtons, header, styles} = props;
  return (
    <Space direction="vertical" style={{gap: 24, padding: 32, ...(styles ?? {})}} className="dashboard-wrapper">
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        {header}
        {/*<Typography.Text className="dashboard-title">{title}</Typography.Text>*/}
        <Space>
          {
            actionButtons && actionButtons.map(button => {
              return button
            })
          }
        </Space>
      </Space>
      {filterBox}
      {children}
    </Space>
  )
}

export default DashboardLayout
