const ChildrenAim = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9059 21.1058C13.8706 24.1411 8.29416 23.4352 4.4118 19.5882C0.564744 15.7058 -0.105845 10.0941 2.92945 7.09405L3.88239 6.14111L17.8942 20.1176L16.9059 21.1058Z" fill="#CC104A"/>
      <path d="M17.8233 20.188C14.788 23.2233 9.21157 22.5174 5.32921 18.6703C1.44686 14.788 0.776272 9.2115 3.81157 6.1762C6.84686 3.14091 12.4233 3.84679 16.2704 7.69385C17.4351 8.85856 18.3174 10.1997 18.8822 11.5409C19.0586 11.9997 19.2351 12.4233 19.341 12.8821C20.1174 15.635 19.6233 18.388 17.8233 20.188Z" fill="#F34B58"/>
      <path d="M15.3531 8.64687C18.3531 11.6469 18.8825 15.988 16.5531 18.3528C14.2237 20.7175 9.84723 20.1528 6.84723 17.1528C3.84723 14.1528 3.31781 9.81158 5.64723 7.44687C7.97664 5.08217 12.3531 5.64687 15.3531 8.64687Z" fill="white"/>
      <path d="M14.4351 9.56431C16.6234 11.7525 17.0116 14.929 15.3175 16.6231C13.6233 18.3172 10.4469 17.9643 8.25864 15.7408C6.07041 13.5525 5.68217 10.3761 7.37629 8.68195C9.07041 6.98784 12.2469 7.37607 14.4351 9.56431Z" fill="#F34B58"/>
      <path d="M13.4471 10.5528C14.8589 11.9645 15.1059 13.9763 14.0118 15.0704C12.9177 16.1645 10.9059 15.9175 9.49418 14.5057C8.08241 13.0939 7.83535 11.0822 8.92947 9.98807C10.0236 8.89395 12.0706 9.14101 13.4471 10.5528Z" fill="white"/>
      <path d="M12.5297 11.4704C13.2003 12.141 13.3414 13.1293 12.812 13.694C12.2473 14.2234 11.2591 14.1175 10.5885 13.4116C9.9179 12.741 9.77673 11.7528 10.3061 11.2234C10.8356 10.694 11.8238 10.7999 12.5297 11.4704Z" fill="#F34B58"/>
      <g opacity="0.2">
        <path d="M19.3764 12.9173L11.7175 12.9526C10.8352 12.9526 10.7999 11.6115 11.7175 11.6115L18.9175 11.5762C19.094 12.035 19.2352 12.4585 19.3764 12.9173Z" fill="#1D2943"/>
      </g>
      <path d="M6.14134 17.8588C5.22369 17.1882 4.48251 16.2706 3.9531 15.2824C3.42369 14.2588 3.10604 13.1294 3.14134 12C3.45898 13.0941 3.84722 14.1176 4.37663 15.0706C4.87075 16.0235 5.47075 16.9412 6.14134 17.8588Z" fill="white"/>
      <path d="M21.2116 2.78842C21.494 3.07078 21.494 3.49431 21.2116 3.74137L12.1763 12.7414C11.9293 12.9884 11.5057 12.9884 11.2234 12.7414C10.9763 12.4943 10.9763 12.0708 11.2234 11.7884L20.2587 2.78842C20.541 2.50607 20.9646 2.50607 21.2116 2.78842Z" fill="#1D2943"/>
      <path d="M21.0707 3.84717L22.6589 4.30599C22.9412 4.37658 23.0471 4.72952 22.8354 4.94129L22.4118 5.36482C22.2354 5.54129 21.953 5.61187 21.7059 5.54129L21.1765 5.40011L21.2824 5.50599C21.5648 5.78834 21.5648 6.24717 21.2824 6.52952L20.4001 7.41187C20.2236 7.58834 19.9412 7.65893 19.6942 7.58834L17.8589 7.05893L21.0707 3.84717Z" fill="#FFD06C"/>
      <path d="M20.153 2.89408L19.6942 1.30585C19.6236 1.02349 19.2706 0.91761 19.0589 1.12937L17.7883 2.39996C17.6118 2.57643 17.5412 2.85879 17.6118 3.10585L17.753 3.63526L17.4001 3.28232C17.2589 3.14114 17.0118 3.14114 16.8706 3.28232L16.5883 3.59996C16.4118 3.77643 16.3412 4.05879 16.4118 4.30585L16.9412 6.10585L20.153 2.89408Z" fill="#FFD06C"/>
      <path d="M17.7178 5.92951C18.0354 5.50598 18.4237 5.08245 18.8119 4.69422C19.0237 4.51774 19.6589 3.88245 19.4119 4.12951C19.6237 3.95304 19.8354 3.77657 20.0472 3.6001C19.8707 3.81186 19.6942 4.02363 19.5178 4.23539C19.3413 4.44716 19.1295 4.62363 18.9531 4.83539C18.5648 5.22363 18.1766 5.57657 17.7178 5.92951Z" fill="white"/>
    </svg>
  )
}
export default ChildrenAim