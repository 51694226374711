import type {MenuProps} from 'antd';
import {Image, Layout, Menu, Space, Typography} from 'antd';
import {Aim, ArrowBack, Humberger, ManagedByMe, Period, Role} from "src/assets/icons";
import ProjectIcon from 'src/assets/images/project-icon.png';
import Flower from 'src/assets/icons/flower.svg';

import {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router";
import useRole from "src/hooks/useRole";
import './styles.scss'
import * as process from "process";
import Report from "src/assets/icons/Report";

const siderStyle: React.CSSProperties = {
  backgroundColor: '#C92127',
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
};

type MenuItem = Required<MenuProps>['items'][number] & { path?: string };

enum MenuKeys {
  AIM = 'AIM',
  PERIOD = 'PERIOD',
  MANAGED_BY_ME = 'MANAGED_BY_ME',
  ROLE = 'ROLE',
  REPORT = 'REPORT'
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    path
  } as MenuItem;
}

const redirectHref = process.env.REACT_APP_LOGIN_PAGE as string

interface Props {
  collapsed: boolean
  handleCollapsed: () => void
}

const Sidebar = ({collapsed, handleCollapsed}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {isAdmin, isActionWithPeriod, isReadEmployeeGoal, isActionWithOwnerGoal, isExportExcel} = useRole();
  const [tab, setTab] = useState(MenuKeys.AIM);

  let defaultMenus: MenuItem[] = [];

  const items = useMemo(() => {
    if (isActionWithOwnerGoal) {
      defaultMenus = [
        ...defaultMenus,
        getItem('Mục tiêu', MenuKeys.AIM, <Aim/>, 'aims'),
      ]
    }

    if (isReadEmployeeGoal) {
      defaultMenus = [
        ...defaultMenus,
        getItem('Quản lý bởi tôi', MenuKeys.MANAGED_BY_ME, <ManagedByMe/>, 'managed-by-me'),
      ]
    }

    if (isActionWithPeriod) {
      defaultMenus = [
        ...defaultMenus,
        getItem('Giai đoạn', MenuKeys.PERIOD, <Period/>, 'periods'),
      ]
    }

    if (isAdmin) {
      defaultMenus = [
        ...defaultMenus,
        getItem('Phân quyền', MenuKeys.ROLE, <Role/>, 'role')
      ]
    }

    if (isExportExcel) {
      defaultMenus = [
        ...defaultMenus,
        getItem('Báo cáo KPI', MenuKeys.REPORT, <Report/>, 'report')
      ]
    }

    return defaultMenus;
  }, [defaultMenus, isActionWithPeriod, isAdmin, isReadEmployeeGoal, isActionWithOwnerGoal])

  const handleSelectedTab: MenuProps['onClick'] = (e) => {
    const selectedTab = items.find(item => item.key === e.key);
    navigate(selectedTab?.path as string)
    setTab(e.key as MenuKeys);
  };

  useEffect(() => {
    const {pathname} = location;
    const selectedTab = items.find(item => pathname.includes(item.path as string));
    setTab(selectedTab?.key as MenuKeys);
  }, [items, location])

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={collapsed ? 80 : 256}
      style={{
        ...siderStyle,
        backgroundImage: `url('${Flower}')`
      }}
      className="sidebar-wrapper"
    >
      <Space className="forlife-home">
        <ArrowBack onClick={() => window.open(redirectHref, '_self')}/>
        <Typography.Text style={{display: collapsed ? 'none' : 'inline-block', width: 'max-content'}} className="title">Forlife Home</Typography.Text>
      </Space>
      <Space className="forlife-project" style={{gap: '12px'}}>
        <Humberger style={{cursor: 'pointer'}} onClick={handleCollapsed}/>
        <Image
          style={{display: collapsed ? 'none' : 'inline-block'}}
          preview={false}
          width={32}
          src={ProjectIcon}
        />

        <Space direction="vertical" style={{gap: 0, display: collapsed ? 'none' : 'inline-block'}}
               styles={{item: {textAlign: 'start', width: 'max-content'}}}>
          <Typography.Text className="project-title">PMS</Typography.Text>
          <Typography.Text className="project-aim">Hệ thống quản lý hiệu suất</Typography.Text>
        </Space>
      </Space>
      <Menu
        onClick={handleSelectedTab}
        selectedKeys={[tab]}
        mode="inline"
        items={items}
        className={`custom-menu ${collapsed ? 'custom-collapse-menu' : ''}`}
      />
    </Layout.Sider>
  )
}

export default Sidebar
