import DashboardLayout from "src/layout/DashboardLayout";
import Table from "src/components/Table";
import Modal from "src/components/Modal";

import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table/interface";
import { Tag, Typography } from "antd";
import CreateOrEditAimLevel1 from "src/components/Form/CreateOrEditAimLevel1";
import Button from "src/components/Button";
import { Delete, Detail, Edit } from "src/assets/icons";
import { AimLevelEnums, ChildrenAimStatusType } from "src/utils/enums";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { CreateAimResponseType } from "src/utils/types";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { handleDeleteAim, handleGetAimDetail, handleGetMyAimList, handleGetRefAimList } from "src/services/aims.services";
import { useFilter } from "src/hooks/useFilter";
import PeriodListFilter from "src/pages/PeriodList/PeriodListFilter";
import { handleChildrenAimState } from "src/utils/functions";
import { toast } from "react-toastify";
import { handleGetListOrganization } from "src/services/permissions.services";
import useRole from "src/hooks/useRole";
import { handleGetMyPeriodList } from "src/services/periods.services";
import { ProgressItem } from "src/pages/AimDetail/AimList/AimListLevel3";

const Aims = () => {
  const { isActionWithOwnerGoal } = useRole();
  const dispatch = useAppDispatch();

  const { loading, myAimList } = useAppSelector((state) => state.aim);
  const { myPeriodList } = useAppSelector((state) => state.period);

  const [openCreateAim, setOpenCreateAim] = useState(false);
  const [openDeleteAim, setOpenDeleteAim] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CreateAimResponseType | undefined>();

  const navigate = useNavigate();
  const methodsFilter = useForm();

  const { filter, onFilterToQueryString } = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetMyAimList(filter));
    },
  })

  const handleSelectedAimDeleteItem = (action: CreateAimResponseType) => {
    setOpenDeleteAim(true);
    setSelectedItem(action)
  }

  const handleSelectedAimEdit = (action: CreateAimResponseType) => {
    setOpenCreateAim(true);
    setSelectedItem(action)
    dispatch(handleGetAimDetail({ id: action?.id, level: AimLevelEnums.LEVEL_1 }))
  }

  const columns: ColumnsType<CreateAimResponseType> = [
    {
      title: 'Tên mục tiêu',
      dataIndex: 'name',
      key: 'name',
      width: 500,
      render: (_, action) => {
        return (
          <Typography.Text style={{ cursor: 'pointer' }} onClick={() => navigate(`/aims/${action?.id}`)}>
            {action?.name || '-'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Số mục tiêu cấp 2',
      dataIndex: 'childrens',
      key: 'childrens',
      width: 200,
      render: (_, action) => {
        return (
          <Typography.Text>{action?.totalSub || 0}</Typography.Text>
        );
      },
    },
    {
      title: 'Giai đoạn',
      dataIndex: 'parent',
      key: 'parent',
      width: 300,
      render: (_, action) => {
        return (
          <Typography>{action?.phase?.name || '-'}</Typography>
        );
      },
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'result',
      key: 'result',
      width: 168,
      render: (_, action) => {
        return (
          <ProgressItem data={action} />
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusGoal',
      key: 'statusGoal',
      width: 168,
      render: (_, { statusGoal, statusResult }) => {
        const status = handleChildrenAimState(statusGoal as ChildrenAimStatusType, statusResult as ChildrenAimStatusType);
        return (
          <Tag
            style={{
              color: status.textColor,
              backgroundColor: status.bgColor,
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 12px',
              border: 'none'
            }}
          >
            {status.value}
          </Tag>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render: (_, action) => {
        const { childrens } = action;
        if (!isActionWithOwnerGoal) return null;
        return (
          <div>
            <Button
              onClick={() => navigate(`/aims/${action.id}`)}
              icon={<Detail />}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }} />

            <Button
              onClick={() => handleSelectedAimEdit(action)}
              icon={<Edit />}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }} />
            {
              (!childrens || childrens?.length === 0) && (
                <Button
                  onClick={() => handleSelectedAimDeleteItem(action)}
                  icon={<Delete />}
                  style={{ padding: 0, border: 'none', boxShadow: 'none', width: '28px', height: '28px' }}
                />
              )
            }
          </div>
        );
      },
    },
  ];

  const handleDeleteAimItem = () => {
    if (!selectedItem) return;
    dispatch(handleDeleteAim(selectedItem?.id)).unwrap()
      .then(() => {
        setSelectedItem(undefined);
        setOpenDeleteAim(false);
        dispatch(handleGetMyAimList(filter));
        toast.success("Xóa mục tiêu thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setSelectedItem(undefined)
        setOpenDeleteAim(false)
      })
  }

  useEffect(() => {
    const period = myPeriodList.data.find(item => item.id === filter.phase);
    methodsFilter.setValue('search', filter.search)
    period && methodsFilter.setValue('phase', {
      label: period?.name,
      value: period?.id
    })
  }, [filter.phase, filter.search, myPeriodList.data]);

  useEffect(() => {
    dispatch(handleGetListOrganization())
      .unwrap()
      .then(res => {
        const organizationList = res.data;
        if (organizationList.data.length == 1) {
          dispatch(handleGetRefAimList({ organization: organizationList.data[0].id }));
          dispatch(handleGetMyPeriodList({ organization: organizationList.data[0].id }));
        } else {
          dispatch(handleGetRefAimList());
          dispatch(handleGetMyPeriodList());
        }
      })
      ;
  }, [])

  if (!isActionWithOwnerGoal) {
    return <></>;
  }

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Danh sách mục tiêu</Typography.Text>
      }
      filterBox={
        <FormProvider {...methodsFilter}>
          <form>
            <PeriodListFilter onFilterToQueryString={onFilterToQueryString} filter={filter} />
          </form>
        </FormProvider>
      }
      actionButtons={[
        isActionWithOwnerGoal && (
          <Button
            type="primary"
            key='create-aim'
            onClick={() => setOpenCreateAim(true)}
          >
            Tạo mục tiêu
          </Button>
        )
      ]}
    >
      <Table
        loading={loading}
        data={myAimList?.data}
        total={myAimList?.pagination?.total || 0}
        columns={columns}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({ ...filter, per_page: Number(value.value) })
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({ ...filter, page: value })
        }}
        rowKey='id'
      />

      <CreateOrEditAimLevel1
        open={openCreateAim}
        selectedItem={selectedItem}
        errorCb={() => {
          setSelectedItem(undefined);
          setOpenCreateAim(false);
        }}
        closeCb={() => {
          setOpenCreateAim(false)
          setSelectedItem(undefined)
        }}
        successCb={() => {
          dispatch(handleGetMyAimList(filter));
          setOpenCreateAim(false)
          setSelectedItem(undefined)
        }}
      />
      {/*----------------------------------------------------------------------------*/}

      <Modal
        open={openDeleteAim}
        modalType='confirm'
        title="Xoá mục tiêu"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteAimItem}
        onCancel={() => {
          setSelectedItem(undefined)
          setOpenDeleteAim(false)
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xoá mục tiêu này
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default Aims
