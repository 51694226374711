import { Space, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "src/components/DatePicker";
import ErrorMessage from "src/components/ErrorMessage";
import Input, { formatNumberFromString } from "src/components/Input";
import Select from "src/components/Select";
import { SentResultActionType, StatusTrendSelections, TypeMeasureSelections, UnitSelections } from "src/utils/enums";
import { handleDateValidation } from "src/utils/functions";
import { NUMBER_REGEX, NUMBER_REGEX_EXT } from "src/utils/regex";

const Form = () => {
  const { control, watch, trigger } = useFormContext();
  const typeMeasure = watch('typeMeasure');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  useEffect(() => {
    if (fromDate || toDate) {
      trigger('fromDate')
      trigger('toDate')
    }
  }, [fromDate, toDate])

  return (
    <div>
      <Typography style={{color: '#525252', marginBottom: '4px'}}>
        Tên hành động {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="name"
        rules={{
          required: 'Vui lòng điền thông tin'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Input
                value={value}
                onChange={onChange}
                placeholder="Đặt tên hành động"
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />

      <Space style={{marginTop: '12px', width: '100%', justifyContent: 'space-between', gap: 12}}
             styles={{item: {flex: 1}}}>
        <Space style={{width: '100%'}} direction="vertical">
          <Typography style={{color: '#525252'}}>
            Ngày bắt đầu {' '}
            <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={control}
            name="fromDate"
            rules={{
              required: 'Vui lòng chọn ngày',
              validate: () => {
                const fromDateTimestamp = dayjs(fromDate).valueOf();
                const toDateTimestamp = dayjs(toDate).valueOf();
                return handleDateValidation(fromDateTimestamp, toDateTimestamp);
              }
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <>
                  <DatePicker
                    value={value}
                    placeholder="--- Chọn ngày ---"
                    onChange={(value) => onChange(value)}
                    style={{
                      width: '100%'
                    }}
                  />
                  {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                </>
              )
            }}
          />
        </Space>

        <Space style={{width: '100%'}} direction="vertical">
          <Typography style={{color: '#525252'}}>
            Ngày kết thúc {' '}
            <span style={{color: '#FF3141'}}>*</span>
          </Typography>
          <Controller
            control={control}
            name="toDate"
            rules={{
              required: 'Vui lòng chọn ngày',
              validate: () => {
                const fromDateTimestamp = dayjs(fromDate).valueOf();
                const toDateTimestamp = dayjs(toDate).valueOf();
                return handleDateValidation(fromDateTimestamp, toDateTimestamp);
              }
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <>
                  <DatePicker
                    value={value}
                    placeholder="--- Chọn ngày ---"
                    onChange={(value) => onChange(value)}
                    style={{
                      width: '100%'
                    }}
                  />
                  {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                </>
              )
            }}
          />
        </Space>
      </Space>

      <Typography style={{color: '#525252', marginTop: '12px'}}>
        Cách đo lường {' '}
      </Typography>
      <Typography style={{color: '#737373', fontSize: 12, lineHeight: '16px', marginBottom: '12px'}}>
        Bạn muốn đo đếm kết quả này như thế nào?
      </Typography>

      <Controller
        control={control}
        name="typeMeasure"
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Select
                style={{
                  width: '100%'
                }}
                placeholder="--- Chọn hình thức đo kết quả ---"
                options={TypeMeasureSelections}
                value={value}
                onSelect={(value) => onChange(value)}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />

      {
        typeMeasure?.value === SentResultActionType.NUMBER && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 8,
              padding: 16,
              backgroundColor: '#eee',
              borderRadius: '6px',
              marginTop: '16px'
            }}
          >
            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Con số mục tiêu {' '}
                <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="measure"
                rules={{
                  required: 'Vui lòng điền cho số mục tiêu',
                  min: {
                    value: 1,
                    message: 'Con số mục tiêu phải lớn hơn 0'
                  }
                }}
                render={({field: {onChange,value}, fieldState}) => {
                  
                  return (
                    <div>
                      <Input
                        value={ formatNumberFromString(typeof value === "number" ? value.toString() : value)  }
                        onChange={(value) => {
                          if (NUMBER_REGEX_EXT.test(value) || value === '') {
                            
                            onChange(value);
                          }
                        }}
                        placeholder="Nhập con số mục tiêu"
                      />
                      <Typography style={{fontSize: '9px', marginTop: '4px'}}>VD: 1,000,000.012 (012 là phần thập phân)</Typography>
                      {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                    </div>
                  )
                }}
              />
              
            </div>

            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Đơn vị tính {' '}
                <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="unit"
                rules={{
                  required: 'Vui lòng chọn đơn vị tính'
                }}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <div>
                      <Select
                        style={{
                          width: '100%'
                        }}
                        placeholder="--- Chọn đơn vị tính ---"
                        options={UnitSelections}
                        value={value}
                        onSelect={(value) => onChange(value)}
                      />
                      {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                    </div>
                  )
                }}
              />
            </div>

            <div style={{flex: 1}}>
              <Typography style={{color: '#525252'}}>
                Xu hướng {' '}
                <span style={{color: '#FF3141'}}>*</span>
              </Typography>
              <Controller
                control={control}
                name="statusTrend"
                rules={{
                  required: 'Vui lòng chọn xu hướng'
                }}
                render={({field: {onChange, value}, fieldState}) => {
                  return (
                    <div>
                      <Select
                        style={{
                          width: '100%'
                        }}
                        placeholder="--- Chọn xu hướng ---"
                        options={StatusTrendSelections}
                        value={value}
                        onSelect={(value) => onChange(value)}
                      />
                      {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
                    </div>
                  )
                }}
              />
            </div>
          </div>
        )
      }

      {
        typeMeasure?.value === SentResultActionType.STATUS && (
          <Typography
            style={{
              backgroundColor: '#eee',
              borderRadius: '6px',
              padding: '16px 4px',
              textAlign: 'center',
              marginTop: '12px'
            }}>
            Kết quả sẽ là <span style={{ color: '#00B578' }}>hoàn thành</span> hoặc <span style={{ color: '#FF3141' }}>chưa hoàn thành</span>
          </Typography>
        )
      }
    </div>
  )
}

export default Form
