import { Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/es/table/interface';

import { ExcelIcon } from 'src/assets/icons';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import Table from 'src/components/Table';
import { useFilter } from 'src/hooks/useFilter';
import useRole from 'src/hooks/useRole';
import DashboardLayout from 'src/layout/DashboardLayout';
import ReportFilter from 'src/pages/Report/ReportFilter';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { handleExportExcel, handleGetReportList } from 'src/services/aims.services';
import { EmployeeTypeEnum, LeaderFilter } from 'src/utils/enums';
import { CreateAimResponseType } from 'src/utils/types';

import { getReportColumns } from './utils';
import { ExportExcel } from './ExportExcel';
import instanceAxios from 'src/utils/axios';

const ReportPage = () => {
  const { isReadEmployeeGoal, isWatchAndApprovedLeaderGoal, isExportExcel } = useRole();
  const methodsFilter = useForm();

  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const { reportList, loading } = useAppSelector(state => state.aim);
  const { organizationList } = useAppSelector(state => state.permission);
  const { periodList } = useAppSelector(state => state.period);

  const [openExportExcel, setOpenExportExcel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { filter, onFilterToQueryString } = useFilter({
    onFetchData: filter => {
      if (!filter.organization) {
        return;
      }
      dispatch(handleGetReportList(filter));
    },
  });

  const columns: ColumnsType<CreateAimResponseType> = useMemo(() => {
    return getReportColumns({ navigate });
  }, [navigate]);

  useEffect(() => {
    const _organizationList = filter.organization;
    const periodSelected = periodList.data.find(item => item.id === filter?.phase);
    const employeeType = LeaderFilter.find(item => item.value === filter?.employeeType) || {
      label: 'Tất cả',
      value: EmployeeTypeEnum.ALL,
    };
    methodsFilter.setValue('employeeType', employeeType);
    methodsFilter.setValue(
      'phase',
      periodSelected
        ? {
          label: periodSelected?.name as string,
          value: periodSelected?.id as string,
        }
        : undefined,
    );
    methodsFilter.setValue('organization', _organizationList);
    methodsFilter.setValue('statusGoal', [...(filter?.statusGoal ? filter?.statusGoal as any : []), ...(filter?.statusResult ? filter?.statusResult as any : [])])
  }, [
    filter.organization,
    filter?.employeeType,
    organizationList,
    methodsFilter,
    filter?.phase,
    periodList.data,
  ]);

  useEffect(() => {
    if (!search && organizationList && (organizationList as any)[0]?.level === 1) instanceAxios.get(`/api/organization-unit`)
      .then((res: any) => {
        try {
          if (res?.data?.data?.data.length > 1) return
          let role = res?.data?.data?.data[0]
          for (let index = 0; index < res?.data?.data?.length; index++) {
            const element = res?.data?.data[index]
            if (element.level < role.level) role = element
          }
          const positions = role?.name_organization_path?.split('/');
          const organization = (organizationList as any).find((item: any) => item.name === positions[0])
          switch (role.level) {
            case 1: onFilterToQueryString({ ...filter, organization: [organization?.id] });
              break;
            case 2: onFilterToQueryString({ ...filter, organization: [organization?.id, role?.id] });
              break;
            case 3: onFilterToQueryString({ ...filter, organization: [organization?.id, role?.parent?.id, role?.id] });
              break;
            default:
              break;
          }
        } catch (error) {
          console.log(error)
        }
      })
  }, [search, organizationList])


  if (!isReadEmployeeGoal && !isWatchAndApprovedLeaderGoal) {
    return <></>;
  }

  return (
    <DashboardLayout
      header={<Typography.Text className="dashboard-title">Báo cáo KPI</Typography.Text>}
      actionButtons={[
        isExportExcel && (
          <ExportExcel />
        ),
      ]}
      filterBox={
        <FormProvider {...methodsFilter}>
          <form>
            <ReportFilter onFilterToQueryString={onFilterToQueryString} filter={filter} />
          </form>
        </FormProvider>
      }>
      {filter.organization ? (
        <Table
          loading={loading}
          columns={columns}
          data={reportList.data || []}
          total={reportList?.pagination?.total || 0}
          pagination={filter}
          handleItemPerPage={value => {
            onFilterToQueryString({ ...filter, per_page: Number(value.value) });
          }}
          handlePageNumber={value => {
            onFilterToQueryString({ ...filter, page: value });
          }}
          rowKey="id"
        />
      ) : (
        <Typography style={{ fontSize: '18px', color: '#333', fontWeight: '500' }}>
          Vui lòng chọn khối/phòng/nhóm cần xem mục tiêu trước.
        </Typography>
      )}

    </DashboardLayout>
  );
};

export default ReportPage;
