import {ReactNode} from "react";
import Cookies from "src/utils/cookies";
import * as process from "process";
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const ProtectedAuth = ({ children }: Props) => {
  const location = useLocation()
  const token = Cookies.getToken();

  if (!token) {
    window.location.href = `${process.env.REACT_APP_LOGIN_PAGE}/login?redirect_url=${process.env.REACT_APP_OKR_PAGE}${location.pathname}`
  }

  return <>{children}</>;
};

export default ProtectedAuth;
