import {createAsyncThunk} from "@reduxjs/toolkit";
import instanceAxios from "src/utils/axios";
import {getUserInformation} from "src/reducers/user.slice";

export const handleGetUserInformation = createAsyncThunk(
  'user/get-information',
  async (params: undefined, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/api/users/me`)
      thunkAPI.dispatch(getUserInformation(response.data))
      return response.data.data
    } catch(err) {
      return  thunkAPI.rejectWithValue(err)
    }
  }
)
