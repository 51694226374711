import React from 'react';
import {Button as ButtonAntd, ButtonProps} from 'antd';
import './styles.scss';

const Button = (props: ButtonProps) => {
  const {
    type = "default",
    loading,
    icon,
    onClick,
    disabled,
    ghost = false,
    children,
    ...rest
  } = props;

  return (
    <ButtonAntd
      type={type}
      icon={icon}
      loading={loading}
      rootClassName="custom-button"
      disabled={disabled}
      ghost={ghost}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ButtonAntd>
  );
};

export default React.memo(Button);