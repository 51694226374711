import {
  ChildrenAimStatusType,
  HavingChildrenEnum,
  LevelGoalEnum,
  PeriodStatus,
  SentResultActionType,
  StatusTrendEnum
} from "src/utils/enums";
import {ApartmentListItem, CreateAimResponseType, Pagination} from "src/utils/types";
import dayjs from "dayjs";
import {formatNumber} from "src/components/Input";

export const handlePeriodState = (state: PeriodStatus) => {
  switch (state) {
    case PeriodStatus.INACTIVE:
      return {
        value: 'Ngừng hoạt động',
        textColor: '#EF4444',
        bgColor: '#FEE2E2'
      };
    case PeriodStatus.ACTIVE:
      return {
        value: 'Hoạt động',
        textColor: '#16A34A',
        bgColor: '#DCFCE7'
      }
    case PeriodStatus.CLOSED:
      return {
        value: 'Kết thúc',
        textColor: '#4B5563',
        bgColor: '#F9FAFB'
      }
    default:
      return {
        value: 'Hoạt động',
        textColor: '#16A34A',
        bgColor: '#DCFCE7'
      }
  }
}

export const handleChildrenAimState = (statusGoal: ChildrenAimStatusType, statusResult: ChildrenAimStatusType) => {
  if (!statusResult) {
    switch (statusGoal) {
      case ChildrenAimStatusType.DRAFT_GOAL:
        return {
          value: 'Nháp',
          textColor: '#fff',
          bgColor: '#999999'
        };
      case ChildrenAimStatusType.REGISTER_GOAL:
        return {
          value: 'Chờ duyệt',
          textColor: '#F97316',
          bgColor: '#FFEDD5'
        };
      case ChildrenAimStatusType.APPROVED_GOAL:
        return {
          value: 'Đã duyệt',
          textColor: '#16A34A',
          bgColor: '#DCFCE7'
        }
      case ChildrenAimStatusType.REJECTED_GOAL:
        return {
          value: 'Không duyệt',
          textColor: '#EF4444',
          bgColor: '#FEE2E2'
        }
      default:
        return {
          value: 'Đã duyệt',
          textColor: '#16A34A',
          bgColor: '#DCFCE7'
        }
    }
  }

  switch (statusResult) {
    case ChildrenAimStatusType.SEND_RESULT:
      return {
        value: 'Đã gửi kết quả',
        textColor: '#2563EB',
        bgColor: '#DBEAFE'
      };
    case ChildrenAimStatusType.APPROVED_RESULT:
      return {
        value: 'Hoàn thành',
        textColor: '#16A34A',
        bgColor: '#DCFCE7'
      }
    case ChildrenAimStatusType.REJECTED_RESULT:
      return {
        value: 'Từ chối kết quả',
        textColor: '#EF4444',
        bgColor: '#FEE2E2'
      }
    default:
      return {
        value: 'Đã gửi kết quả',
        textColor: '#2563EB',
        bgColor: '#DBEAFE'
      }
  }
}

export const handleDateValidation = (fromDate: number, toDate: number) => {
  const fromDateTimestamp = dayjs(fromDate).valueOf();
  const toDateTimestamp = dayjs(toDate).valueOf();
  if (fromDateTimestamp > toDateTimestamp) return 'Ngày kết thúc phải lớn hơn ngày bắt đầu'
  return true
}

export const handleCompletedPercent = (data: CreateAimResponseType | undefined) => {
  if (!data) return {
    percent: 0
  };
  const typeMeasure = data?.typeMeasure;
  const levelGoal = data?.levelGoal;
  const levelCalculated = data?.levelCalculated;

  if (levelGoal === LevelGoalEnum.LEVEL_1 || (levelGoal === LevelGoalEnum.LEVEL_2 && levelCalculated === HavingChildrenEnum.HAVING_CHILDREN)) {
    return {
      percent: (data?.result as number) * 100 || 0
    }
  }

  if (typeMeasure === SentResultActionType.NUMBER) {
    if (data?.statusTrend === StatusTrendEnum.UP || data?.statusTrend == null) {
      return {
        percent: Number((data.result || 0) / (data.measure as number)) * 100 || 0,
        measure: `${formatNumber(data.result || 0)}/${formatNumber(data.measure || 0)}`
      }
    } else
      return {
        percent: data.result && data.result !== 0 ? Number((data.measure || 0) / (data.result as number)) * 100 : 0,
        measure: `${formatNumber(data.result || 0)}/${formatNumber(data.measure || 0)}`
      }
  }

  if (typeMeasure === SentResultActionType.STATUS) {
    return {
      percent: data?.result === 1 ? 100 : 0,
      measure: data.result === 1 ? 'Hoàn thành' : 'Chưa hoàn thành'
    }
  }
}

export const handleDisplayActionButton = (item?: CreateAimResponseType) => {
  let isDisplayEditButton: boolean = false;
  let isDisplayDeleteButton: boolean = false;
  let isDisplayFastCreateButton: boolean = false;
  let isSentOrApprovedResult: boolean = false;

  // Mục tiêu cấp 1
  if (item?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL || item?.statusGoal === ChildrenAimStatusType.REGISTER_GOAL) {
    isDisplayEditButton = true;
    isDisplayDeleteButton = true;
  }

  if (item?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL) {
    isDisplayFastCreateButton = true
  }
  if (item?.statusResult === ChildrenAimStatusType.APPROVED_RESULT || item?.statusResult === ChildrenAimStatusType.SEND_RESULT) {
    isSentOrApprovedResult = true;
  }

  return {
    isDisplayEditButton,
    isDisplayDeleteButton,
    isDisplayFastCreateButton,
    isSentOrApprovedResult
  }
}

export const handleLevelBadge = (levelGoal: LevelGoalEnum) => {
  switch (levelGoal) {
    case LevelGoalEnum.LEVEL_1:
      return '#52c41a'
    case LevelGoalEnum.LEVEL_2:
      return '#C92127'
    case LevelGoalEnum.LEVEL_3:
      return '#1677ff'
    default:
      return '#52c41a'
  }
}

export const handleOrganizationSelected = (selectedItems: string[], organizationList: Pagination<ApartmentListItem>) => {
  const _list: ApartmentListItem[] = [];
  selectedItems?.forEach(item => {
    const findItem = organizationList?.data?.find(it => it.id === item);
    if (findItem) {
      _list.push(findItem)
    }
  })

  return _list.map(item => ({
    label: item.name_organization_path || '',
    value: item.id as string
  }));
}
