import {createSlice} from '@reduxjs/toolkit'

export interface AuthState {
  isLogin: boolean,
  userInfo: any | null
}

const initialState: AuthState = {
  isLogin: false,
  userInfo: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.isLogin = true
      state.userInfo = action.payload
    },
    logout(state, action) {
      state.isLogin = false
      state.userInfo = null
    },
    getMe(state, action) {
      state.isLogin = true
      state.userInfo = action.payload
    },
  }
})

export const{ getMe, login, logout } = authSlice.actions
export default authSlice.reducer