import React, {useState} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';

import MainLayout from './layout/MainLayout';
import PeriodList from './pages/PeriodList';
import ManagedByMe from './pages/ManagedByMe';
import AimDetailLevel1 from './pages/AimDetail/AimDetailLevel1';
import AimDetailLevel2 from './pages/AimDetail/AimDetailLevel2';
import AimDetailLevel3 from './pages/AimDetail/AimDetailLevel3';
import ManagedByMeDetail from 'src/pages/ManagedByMe/ManagedByMeDetail';
import Role from 'src/pages/Role';
import Employees from 'src/pages/Employees';
import Aims from './pages/Aims';
import ProtectedAuth from 'src/components/ProtectedAuth';
import NotFound from 'src/pages/NotFound';
import useAppInit from 'src/hooks/useAppInit';
import ReportPage from 'src/pages/Report';
import ReportDetail from 'src/pages/Report/ReportDetail';
import {ManageTypesRoutes} from 'src/utils/enums';
import NotSupport from './components/NotSupport';

import './index.scss';

function App() {
  const [loading, setLoading] = useState(true);

  useAppInit(setLoading);

  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <React.Suspense fallback={loading}>
          <BrowserRouter>
            <div className="App">
              <Routes>
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route
                  path="/"
                  element={
                    <ProtectedAuth>
                      <MainLayout />
                    </ProtectedAuth>
                  }>
                  <Route path="periods" element={<PeriodList />} />
                  <Route path="managed-by-me" element={<ManagedByMe />} />
                  <Route path="managed-by-me/:id" element={<ManagedByMeDetail />} />
                  <Route
                    path="managed-by-me/:aimIdLevel1/:aimIdLevel2"
                    element={<AimDetailLevel2 manaTypeRoute={ManageTypesRoutes.MANAGED_BY_ME} />}
                  />
                  <Route
                    path="managed-by-me/:aimIdLevel1/:aimIdLevel2/:aimIdLevel3"
                    element={<AimDetailLevel3 />}
                  />
                  <Route path="report" element={<ReportPage />} />
                  <Route path="report/:id" element={<ReportDetail />} />
                  <Route
                    path="report/:aimIdLevel1/:aimIdLevel2"
                    element={<AimDetailLevel2 manaTypeRoute={ManageTypesRoutes.REPORT} />}
                  />
                  <Route
                    path="report/:aimIdLevel1/:aimIdLevel2/:aimIdLevel3"
                    element={<AimDetailLevel3 />}
                  />
                  <Route path="role" element={<Role />} />
                  <Route path="role/employees/:roleId" element={<Employees />} />
                  <Route path="aims" element={<Aims />} />
                  <Route path="aims/:aimIdLevel1" element={<AimDetailLevel1 />} />
                  <Route
                    path="aims/:aimIdLevel1/:aimIdLevel2"
                    element={<AimDetailLevel2 manaTypeRoute={ManageTypesRoutes.OWNER} />}
                  />
                  <Route
                    path="aims/:aimIdLevel1/:aimIdLevel2/:aimIdLevel3"
                    element={<AimDetailLevel3 />}
                  />
                </Route>
              </Routes>
              <NotSupport title="Chưa hỗ trợ trên mobile" />
            </div>
          </BrowserRouter>
        </React.Suspense>
      )}
    </>
  );
}

export default App;
