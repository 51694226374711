import {useEffect} from 'react'
import Cookies from "src/utils/cookies";
import {handleGetUserInformation} from "src/services/user.services";
import {useAppDispatch} from "src/redux/hooks";

function useAppInit(setLoading: React.Dispatch<React.SetStateAction<boolean>>) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const init = async () => {
      const token = Cookies.getToken()
      if (token) {
        dispatch(handleGetUserInformation())
          .unwrap()
          .then(() => {
            setLoading(false)
          })
          .catch(() => setLoading(false))
      } else {
        setLoading(false)
      }
    }
    init()
  }, [dispatch, setLoading])
}

export default useAppInit
