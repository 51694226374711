import DashboardLayout from "src/layout/DashboardLayout";
import {ArrowBackV2} from "src/assets/icons";
import {Space, Typography} from "antd";
import React, {useEffect, useState} from "react";
import AimInformation from "src/pages/AimDetail/AimInformation";
import {AimLevelEnums, ManageTypes, ManageTypesRoutes} from "src/utils/enums";
import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {handleGetAimDetail} from "src/services/aims.services";
import AimListLevel1 from "src/pages/AimDetail/AimList/AimListLevel1";
import useRole from "src/hooks/useRole";
import {toast} from "react-toastify";

const ReportDetail = () => {
  const {
    isApprovedEmployeeGoal,
  } = useRole();

  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const {aimDetail} = useAppSelector((state) => state.aim);
  const {userInfo} = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!id) return;
    dispatch(handleGetAimDetail({id, level: AimLevelEnums.LEVEL_1}))
      .unwrap()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        toast.error('Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }, [id]);

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
          <div>
            <Typography className="dashboard-title">Chi tiết mục tiêu</Typography>
            <Typography
              style={{
                color: '#737373',
                fontSize: '12px'
              }}
            >
              Mục tiêu/ <span style={{color: '#525252', fontWeight: 600}}>{aimDetail?.name}</span>
            </Typography>
          </div>
        </Space>
      }
    >
      <AimInformation level={AimLevelEnums.LEVEL_1} aimDetail={aimDetail} loading={loading}/>
      <AimListLevel1
        parentId={aimDetail?.id}
        childAimList={aimDetail?.childrens || []}
        loading={loading}
        manaType={ManageTypes.REPORT}
        manaTypeRoute={ManageTypesRoutes.REPORT}
      />
    </DashboardLayout>
  )
}

export default ReportDetail;
