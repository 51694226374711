import {createSlice} from '@reduxjs/toolkit'
import {ApartmentListItem, GroupDetail, GroupListItem, Pagination, Permission} from "src/utils/types";
import {isEmpty} from "lodash";

export interface PermissionState {
  permissionList: Pagination<Permission>
  groupsList: Pagination<GroupListItem>,
  userInGroupList: any,
  groupDetail: GroupDetail | undefined,
  organizationList: Pagination<ApartmentListItem>,
  loading: boolean
}

export const permissionInitialState: PermissionState = {
  loading: false,
  permissionList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  groupsList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  userInGroupList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  organizationList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  groupDetail: undefined
}

export const permissionSlice = createSlice({
  name: 'auth',
  initialState: permissionInitialState,
  reducers: {
    startLoading(state: PermissionState) {
      state.loading = true;
    },
    finishLoading(state: PermissionState) {
      state.loading = false;
    },
    getPermissionList(state: PermissionState, action) {
      if (isEmpty(action.payload.data)) state.permissionList = permissionInitialState.permissionList
      else state.permissionList = action.payload.data
    },
    getGroupList(state: PermissionState, action) {
      if (isEmpty(action.payload.data)) state.groupsList = permissionInitialState.groupsList
      else state.groupsList = action.payload.data
    },
    getUserInGroupList(state: PermissionState, action) {
      if (isEmpty(action.payload.data)) state.userInGroupList = permissionInitialState.userInGroupList
      else state.userInGroupList = action.payload.data
    },
    getOrganizationList(state: PermissionState, action) {
      if (isEmpty(action.payload.data)) state.organizationList = permissionInitialState.organizationList
      else state.organizationList = action.payload.data
    },
    getGroupDetail(state: PermissionState, action) {
      state.groupDetail = action.payload.data
    },
  }
})

export const {
  getPermissionList,
  getGroupList,
  getUserInGroupList,
  getGroupDetail,
  getOrganizationList,
  startLoading,
  finishLoading,
} = permissionSlice.actions

export default permissionSlice.reducer
