const ExcelIcon = () => {
  return (
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.35653 0H8.04236L12.8584 5.02452V14.786C12.8584 16.008 11.8665 17 10.6445 17H2.35653C1.13454 17 0.142578 16.008 0.142578 14.786V2.21395C0.142578 0.991964 1.13454 0 2.35653 0Z" fill="#00733B"/>
      <path opacity="0.302" fillRule="evenodd" clipRule="evenodd" d="M8.03516 0V4.98139H12.8584L8.03516 0Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.88672 12.4715H6.1438V13.3269H3.88672V12.4715ZM6.84824 8.10107H9.11252V8.94928H6.84824V8.10107ZM3.88672 8.10107H6.1438V8.94928H3.88672V8.10107ZM6.84824 9.53869H9.11252V10.3941H6.84824V9.53869ZM3.88672 9.53869H6.1438V10.3941H3.88672V9.53869ZM6.84824 11.0338H9.11252V11.8892H6.84824V11.0338ZM3.88672 11.0338H6.1438V11.8892H3.88672V11.0338ZM6.84824 12.4715H9.11252V13.3269H6.84824V12.4715Z" fill="white"/>
    </svg>
  )
}

export default ExcelIcon
