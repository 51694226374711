import {createSlice} from '@reduxjs/toolkit'
import {CreateAimResponseType, Pagination} from "src/utils/types";

export interface AimState {
  actionList: Pagination<CreateAimResponseType>
  actionDetail: CreateAimResponseType | undefined
}

const initialState: AimState = {
  actionList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  actionDetail: undefined
}

export const actionSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getActionList(state: AimState, action) {
      state.actionList = action.payload.data
    },
    getActionDetail(state: AimState, action) {
      state.actionDetail = action.payload.data
    },
  }
})

export const {
  getActionList,
  getActionDetail,
} = actionSlice.actions
export default actionSlice.reducer