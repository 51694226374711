import Modal from "src/components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {CreateAimResponseType, CreateAimType} from "src/utils/types";
import dayjs from "dayjs";
import {SentResultActionType, StatusTrendEnum, TypeMeasureSelections, UnitSelections} from "src/utils/enums";
import {handleCreateNewAim, handleEditAim} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import Form from './Form'
import {toast} from "react-toastify";
import { formatNumber } from "../CreateOrEditAimLevel2";

interface Props {
  parentId?: string;
  selectedItem?: string;
  open: boolean;
  successCb?: () => void;
  errorCb?: () => void;
  closeCb?: () => void
}

const defaultValues: CreateAimType = {
  name: '',
  fromDate: undefined,
  toDate: undefined,
  parent: null,
  user: '',
  proportion: 0,
  measure: undefined,
  unit: undefined,
  typeMeasure: undefined
}

const CreateOrEditAimLevel3 = ({ parentId, selectedItem, open, successCb, errorCb, closeCb }: Props) => {
  const [loading, setLoading] = useState(false);

  const {aimLevel3Detail} = useAppSelector((state) => state.aim);

  const {userInfo} = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const aimLevel3Methods = useForm({
    defaultValues
  });

  const handleSuccess = (res: CreateAimResponseType) => {
    const messageNotice = res?.messageNotice;
    setLoading(false);
    aimLevel3Methods.reset(defaultValues);
    successCb && successCb();
    if (selectedItem) {
      toast.success("Chỉnh sửa mục tiêu cấp 3 thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.success("Tạo mới mục tiêu cấp 3 thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    messageNotice && toast.warning(messageNotice, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }

  const handleError = (err?: any) => {
    setLoading(false);
    const message = err?.response?.data?.message;
    toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    errorCb && errorCb();
  }

  const createNewAimLevel3 = (data: CreateAimType) => {
    setLoading(true);
    const typeMeasure = data?.typeMeasure?.value;

    let formData: CreateAimType = {
      name: data?.name,
      parent: parentId as string,
      proportion: Number(data.proportion),
      user: userInfo?.id,
      fromDate: dayjs(data.fromDate).format('YYYY-MM-DD'),
      toDate: dayjs(data.toDate).format('YYYY-MM-DD'),
    }

    if (typeMeasure === SentResultActionType.NUMBER) {
      formData = {
        ...formData,
        statusTrend: data?.statusTrend?.value as string,
        measure: formatNumber(data.measure),
        unit: data?.unit?.value,
        typeMeasure,
      }
    }
    if (typeMeasure === SentResultActionType.STATUS) {
      formData = {
        ...formData,
        measure: 1,
        typeMeasure,
      }
    }

    if (selectedItem) {
      dispatch(handleEditAim({...formData, id: selectedItem}))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    } else
      dispatch(handleCreateNewAim(formData))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
  }

  useEffect(() => {
    if (!selectedItem || !aimLevel3Detail) {
      aimLevel3Methods.reset(defaultValues)
      return
    }

    const unit = UnitSelections.find(item => item.value === aimLevel3Detail?.unit);
    const typeMeasure = TypeMeasureSelections.find(item => item.value === aimLevel3Detail?.typeMeasure);
    aimLevel3Methods.reset({
      name: aimLevel3Detail?.name,
      fromDate: dayjs(aimLevel3Detail?.fromDate),
      toDate: dayjs(aimLevel3Detail?.toDate),
      parent: parentId,
      proportion: Number(aimLevel3Detail?.proportion) * 100,
      typeMeasure,
      unit,
      measure: typeMeasure?.value === SentResultActionType.NUMBER ? aimLevel3Detail?.measure : 0,
      statusTrend: {
        label: aimLevel3Detail?.statusTrend === StatusTrendEnum.DOWN ? 'Xu hướng giảm' : 'Xu hướng tăng',
        value: aimLevel3Detail?.statusTrend
      }
    })
  }, [aimLevel3Detail, aimLevel3Methods, parentId, selectedItem])

  if (!open) return null;

  return (
    <FormProvider {...aimLevel3Methods}>
      <form onSubmit={aimLevel3Methods.handleSubmit(createNewAimLevel3)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          title={selectedItem ? "Chỉnh sửa mục tiêu cấp 3" : "Tạo mới mục tiêu cấp 3"}
          okText={selectedItem ? "Cập nhật" : "Xác nhận"}
          cancelText="Hủy"
          onCancel={() => {
            aimLevel3Methods.reset(defaultValues)
            closeCb && closeCb();
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Form/>
        </Modal>
      </form>
    </FormProvider>
  )
}

export default React.memo(CreateOrEditAimLevel3)