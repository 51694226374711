const SentResultWhite = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" fill="white"/>
      <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" stroke="#EEEEEE"/>
      <g clipPath="url(#clip0_7004_164880)">
        <rect width="14" height="14" transform="translate(7 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1686 10.9368H12.8353C12.3386 10.9368 11.4611 10.9368 11.269 9.81767C9.89145 9.97924 9.18945 10.5903 9.18945 12.8329V16.3329C9.18945 18.4913 9.61529 19.3954 12.252 19.3954H15.752C18.3886 19.3954 18.8145 18.4913 18.8145 16.3329V12.8329C18.8145 10.5973 18.1168 9.98306 16.7478 9.8192C16.6727 10.3492 16.372 10.9368 15.1686 10.9368ZM15.8978 9.36366C15.8975 9.91563 15.8846 10.0618 15.1686 10.0618H12.8353C12.2672 10.0618 12.1112 10.0618 12.1062 9.36774C12.1068 9.35437 12.1068 9.34084 12.1061 9.32716C12.1069 8.60352 12.2592 8.60352 12.8353 8.60352H15.1686C15.7432 8.60352 15.8962 8.60352 15.8978 9.32151C15.8971 9.33567 15.8971 9.34973 15.8978 9.36366ZM16.7487 8.93883C16.5895 7.72852 15.6784 7.72852 15.1686 7.72852H12.8353C12.3248 7.72852 11.4119 7.72852 11.2545 8.9438C9.17052 9.16982 8.31445 10.3243 8.31445 12.8329V16.3329C8.31445 18.7654 8.97362 20.2704 12.252 20.2704H15.752C19.0303 20.2704 19.6895 18.7654 19.6895 16.3271V12.8271C19.6895 10.3237 18.8332 9.16956 16.7487 8.93883ZM13.3078 16.8866C13.197 16.8866 13.0861 16.8458 12.9986 16.7583L12.1236 15.8833C11.9545 15.7141 11.9545 15.4341 12.1236 15.265C12.2928 15.0958 12.5728 15.0958 12.742 15.265L13.3078 15.8308L15.332 13.8066C15.5011 13.6375 15.7811 13.6375 15.9503 13.8066C16.1195 13.9758 16.1195 14.2558 15.9503 14.425L13.617 16.7583C13.5353 16.8458 13.4186 16.8866 13.3078 16.8866Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_7004_164880">
          <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default SentResultWhite