const Detail = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" fill="#F5F5F5"/>
      <g clipPath="url(#clip0_6132_78455)">
        <rect width="15" height="15" transform="translate(7 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.40605 16.3743C9.93105 18.7618 12.1498 20.1368 14.4998 20.1368C16.8436 20.1368 19.0623 18.7618 20.5873 16.3743C21.2498 15.3368 21.2498 13.6556 20.5873 12.6243C19.0623 10.2368 16.8436 8.86182 14.4998 8.86182C12.1561 8.86182 9.9373 10.2368 8.40605 12.6243C7.74355 13.6618 7.74355 15.3431 8.40605 16.3743ZM9.1998 13.1306C10.5498 11.0118 12.4811 9.79932 14.4998 9.79932C16.5186 9.79932 18.4498 11.0118 19.7998 13.1306C20.2686 13.8618 20.2686 15.1368 19.7998 15.8681C18.4498 17.9868 16.5186 19.1993 14.4998 19.1993C12.4811 19.1993 10.5498 17.9868 9.1998 15.8681C8.73105 15.1368 8.73105 13.8618 9.1998 13.1306ZM11.7935 14.4996C11.7935 15.9933 13.006 17.2058 14.4998 17.2058C15.9935 17.2058 17.206 15.9933 17.206 14.4996C17.206 13.0058 15.9935 11.7933 14.4998 11.7933C13.006 11.7933 11.7935 13.0058 11.7935 14.4996ZM12.731 14.4996C12.731 13.5246 13.5248 12.7308 14.4998 12.7308C15.4748 12.7308 16.2685 13.5246 16.2685 14.4996C16.2685 15.4746 15.4748 16.2683 14.4998 16.2683C13.5248 16.2683 12.731 15.4746 12.731 14.4996Z" fill="#333333"/>
      </g>
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" stroke="#EEEEEE"/>
      <defs>
        <clipPath id="clip0_6132_78455">
          <rect width="15" height="15" fill="white" transform="translate(7 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Detail