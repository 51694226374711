import React from 'react';
import {Modal as ModalAntd, ModalFuncProps, Space, Form} from 'antd';
import Button from "../Button";
import './styles.scss';

type ModalType = 'form' | 'confirm' | 'none'

interface Props extends ModalFuncProps {
  children: React.ReactNode;
  anotherAction?: string;
  modalType?: ModalType;
  loadingSubmitButton?: boolean
}

const Modal = (props: Props) => {
  const {
    anotherAction,
    onOk,
    onCancel,
    okText = 'Ok',
    cancelText = "Cancel",
    children,
    open,
    modalType = 'form',
    loadingSubmitButton,
    ...rest
  } = props;

  const footerForm = () => {
    return (
      <Space className='modal-footer-form' style={{justifyContent: !anotherAction ? 'flex-end' : 'space-between'}}>
        {
          anotherAction && <Button>{anotherAction}</Button>
        }
        <Space>
          <Button key="back" onClick={onCancel}>{cancelText}</Button>
          <Button key="submit" type="primary" htmlType="submit" loading={loadingSubmitButton}>{okText}</Button>
        </Space>
      </Space>
    )
  }

  const footerConfirm = () => {
    return (
      <Space
        className='modal-footer-confirm'
        style={{
          justifyContent: 'end'
        }}
        classNames={{
          item: 'item-footer-confirm'
        }}
      >
        <Button key="back" onClick={onCancel}>{cancelText}</Button>
        <Button key="submit" type="primary" htmlType="submit" loading={loadingSubmitButton} onClick={(e) => {
          e.preventDefault()
          onOk && onOk()
        }}
        >
          {okText}
        </Button>
      </Space>
    )
  }

  return (
    <ModalAntd
      open={open}
      onCancel={onCancel}
      className="modal-wrapper"
      footer={null}
      {...rest}
    >
      <form>
        <div style={{padding: '16px 20px'}}>
          {children}
        </div>
        {
          modalType !== 'none' && (
            <div style={{padding: '16px 20px', borderTop: '1px solid #eee'}}>
              {modalType === 'form' && footerForm()}
              {modalType === 'confirm' && footerConfirm()}
            </div>
          )
        }
      </form>
    </ModalAntd>
  );
};

export default Modal