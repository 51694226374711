const ArrowBackV2 = (props: any) => {
  return (
    <svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="43" height="40" fill="white" fillOpacity="0.01"/>
      <rect x="0.5" y="0.5" width="42" height="39" rx="5.5" fill="white"/>
      <g clipPath="url(#clip0_6132_102154)">
        <rect width="17" height="17" transform="translate(13 11.5)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4029 24.674C19.5091 24.7802 19.6437 24.8298 19.7783 24.8298C19.9129 24.8298 20.0545 24.7802 20.1537 24.674C20.3591 24.4686 20.3591 24.1286 20.1537 23.9232L16.7607 20.5302H27.5204C27.8108 20.5302 28.0516 20.2893 28.0516 19.9989C28.0516 19.7085 27.8108 19.4677 27.5204 19.4677H16.7609L20.1537 16.0748C20.3591 15.8694 20.3591 15.5294 20.1537 15.324C19.9483 15.1186 19.6083 15.1186 19.4029 15.324L15.1033 19.6236C14.8979 19.829 14.8979 20.169 15.1033 20.3744L19.4029 24.674Z" fill="#333333"/>
      </g>
      <rect x="0.5" y="0.5" width="42" height="39" rx="5.5" stroke="#EEEEEE"/>
      <defs>
        <clipPath id="clip0_6132_102154">
          <rect width="17" height="17" fill="white" transform="translate(13 11.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowBackV2;