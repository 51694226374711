const ManagedByMe = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6132_53864)">
        <rect width="24" height="24" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0002 22.7501C11.3302 22.7501 10.6502 22.5801 10.0502 22.2301L4.11016 18.8001C2.91016 18.1001 2.16016 16.8101 2.16016 15.4201V8.58011C2.16016 7.19011 2.91016 5.90011 4.11016 5.20011L10.0502 1.77012C11.2502 1.07012 12.7402 1.07012 13.9502 1.77012L19.8902 5.20011C21.0902 5.90011 21.8402 7.19011 21.8402 8.58011V15.4201C21.8402 16.8101 21.0902 18.1001 19.8902 18.8001L13.9502 22.2301C13.3502 22.5801 12.6702 22.7501 12.0002 22.7501ZM12.0002 2.7501C11.5902 2.7501 11.1702 2.8601 10.8002 3.0701L4.86016 6.5001C4.12016 6.9301 3.66016 7.72011 3.66016 8.58011V15.4201C3.66016 16.2701 4.12016 17.0701 4.86016 17.5001L10.8002 20.9301C11.5402 21.3601 12.4602 21.3601 13.2002 20.9301L19.1402 17.5001C19.8802 17.0701 20.3402 16.2801 20.3402 15.4201V8.58011C20.3402 7.73011 19.8802 6.9301 19.1402 6.5001L13.2002 3.0701C12.8302 2.8601 12.4102 2.7501 12.0002 2.7501ZM11.9999 11.7498C10.2999 11.7498 8.91992 10.3698 8.91992 8.6698C8.91992 6.9698 10.2999 5.58984 11.9999 5.58984C13.6999 5.58984 15.0799 6.9698 15.0799 8.6698C15.0799 10.3698 13.6999 11.7498 11.9999 11.7498ZM11.9999 7.08984C11.1299 7.08984 10.4199 7.7998 10.4199 8.6698C10.4199 9.5398 11.1299 10.2498 11.9999 10.2498C12.8699 10.2498 13.5799 9.5398 13.5799 8.6698C13.5799 7.7998 12.8699 7.08984 11.9999 7.08984ZM15.25 16.6603C15.25 17.0703 15.59 17.4103 16 17.4103C16.41 17.4103 16.75 17.0703 16.75 16.6603C16.75 14.4503 14.62 12.6504 12 12.6504C9.38 12.6504 7.25 14.4503 7.25 16.6603C7.25 17.0703 7.59 17.4103 8 17.4103C8.41 17.4103 8.75 17.0703 8.75 16.6603C8.75 15.2803 10.21 14.1504 12 14.1504C13.79 14.1504 15.25 15.2803 15.25 16.6603Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_6132_53864">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ManagedByMe