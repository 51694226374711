const EditV2 = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6132_102197)">
        <rect width="13" height="13" transform="translate(0 0.5)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.00083 11.0723C2.67041 11.0723 2.36166 10.9585 2.13958 10.7473C1.85791 10.4819 1.72249 10.081 1.77124 9.6477L1.97166 7.8927C2.00958 7.56228 2.20999 7.12353 2.44291 6.8852L6.14971 2.96168L6.15643 2.95456L6.88999 2.17811C8.00041 1.0027 9.15958 0.970198 10.335 2.08061C11.5104 3.19103 11.5429 4.3502 10.4325 5.52561L5.98541 10.2327C5.75791 10.4764 5.33541 10.7039 5.00499 10.7581L3.26083 11.056C3.22777 11.058 3.1961 11.0606 3.16509 11.0632L3.16506 11.0632C3.10969 11.0678 3.05637 11.0723 3.00083 11.0723ZM3.03333 7.4377L6.23908 4.04062C6.63963 5.10723 7.51045 5.93159 8.59655 6.27489L5.38958 9.66936C5.28124 9.78311 5.02124 9.92395 4.86416 9.95103L3.11999 10.2489C2.94124 10.276 2.79499 10.2435 2.69749 10.1514C2.59999 10.0594 2.55666 9.91311 2.57833 9.73436L2.77874 7.97937C2.80041 7.82228 2.92499 7.55145 3.03333 7.4377ZM9.83666 4.96228L9.23789 5.59606C8.07167 5.39816 7.14205 4.51073 6.88611 3.35496L7.48041 2.7252C7.84874 2.3352 8.21166 2.0752 8.62874 2.0752C8.96458 2.0752 9.33833 2.24853 9.77166 2.66561C10.7521 3.58645 10.5083 4.24728 9.83666 4.96228ZM1.625 12.8221H11.375C11.5971 12.8221 11.7812 12.6379 11.7812 12.4159C11.7812 12.1938 11.5971 12.0096 11.375 12.0096H1.625C1.40292 12.0096 1.21875 12.1938 1.21875 12.4159C1.21875 12.6379 1.40292 12.8221 1.625 12.8221Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_6132_102197">
          <rect width="13" height="13" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
export default EditV2;