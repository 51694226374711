import {useEffect, useState} from "react";
import {Pagination} from "src/utils/types";
import {useAppDispatch} from "src/redux/hooks";

export interface FilterParams {
  page?: number;
  per_page?: number;
  is_managed?: boolean;
  is_public?: boolean;
  search?: string;
}

interface Props {
  onFetchData: (filter?: FilterParams) => any;
  defaultFilter?: FilterParams;
}

const initialData = {
  data: [],
  pagination: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0
  }
}

export const initialFilter = {
  page: 1,
  per_page: 10,
};

export function useInfinityScroll<T> ({
                                    onFetchData,
                                    defaultFilter = initialFilter,
                                  }: Props) {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Pagination<T>>(initialData);
  const [page, setPage] = useState(2);

  useEffect(() => {
    dispatch(onFetchData(defaultFilter))
      .unwrap()
      .then((res: any) => {
        setData(res?.status ? res.data : res)
      })
      .catch(() => {
        setData(initialData)
      })
  }, []);

  const handleLoadMore = (event: React.UIEvent<HTMLElement, UIEvent>) => {
    if (data.pagination.last_page < page) return;
    const target = event.target as HTMLElement;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      dispatch(onFetchData({...defaultFilter, page}))
        .unwrap()
        .then((res: any) => {
          if (res.status) {
            setData({
              data: [
                ...data.data,
                ...res.data.data
              ],
              pagination: res.data.pagination
            })
          } else {
            setData({
              data: [
                ...data.data,
                ...res.data
              ],
              pagination: res.pagination
            })
          }
        })
        .catch(() => {
          setData(initialData)
        });
      setPage(page + 1)
    }
  }

  return {
    data,
    handleLoadMore
  }
}
