import React from 'react';
import { Input as InputAntd, InputProps as InputPropsAntd } from 'antd';
import { NUMBER_REGEX } from "src/utils/regex";
import { omit } from "lodash";
import { PasswordIcon, PasswordUnHideIcon, SearchIcon } from "src/assets/icons";
import './styles.scss'

const InputPassword = InputAntd.Password
const TextArea = InputAntd.TextArea

type InputProps = Omit<InputPropsAntd, 'onChange'> & {
  style?: React.CSSProperties
  value: string;
  onChange: (value: string) => void;
  type?: Pick<InputPropsAntd, 'type'>;
  placeholder?: string;
  rows?: number
}

export const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

export const formatNumberFromString = (input: any) => {
  if (input) {
    const parts = input.toString().split('.');
    const integerPart = parts[0];
    // Loại bỏ tất cả các ký tự không phải số từ chuỗi đầu vào
    const numericString = (integerPart ?? '')?.replace(/\D/g, "");

    // Định dạng lại chuỗi số có dấu phẩy ngăn cách hàng nghìn
    const formattedNumber = (numericString ?? '')?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedNumber + (parts.length > 1 ? `.${parts[1]}` : '');
  }
  return input
}

export const formatToNumber = (str: any) => {
  // Loại bỏ dấu phẩy trong chuỗi
  const cleanStr = str.toString()?.replace(/,/g, '');
  // Chuyển chuỗi thành số và trả về
  return parseFloat(cleanStr);
}

const CustomInput = (props: InputProps) => {
  const {
    value = '',
    type,
    onChange,
    rows
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = e.target;
    switch (type) {
      case 'number':
        if (NUMBER_REGEX.test(inputValue) || inputValue === '' || inputValue === '-') {
          onChange(inputValue);
        }
        break;
      default:
        onChange(inputValue);
        break;
    }
  };

  const handleBlur = () => {
    switch (type) {
      case 'number':
        onChange(formatNumber(Number(value)));
        break;
      default:
        return;
    }
  };

  const handleFocus = () => {
    switch (type) {
      case 'number':
        const _number = String(value).includes('.') ? value.replaceAll('.', '') : value
        onChange(_number);
        break;
      default:
        return;
    }
  }

  if (type === 'textarea') {
    return (
      <TextArea
        value={props.value}
        onChange={handleChange}
        rootClassName="custom-input"
        rows={rows || 5}
        placeholder={props.placeholder}
      />
    )
  }

  if (type === 'password') {
    return (
      <InputPassword
        {...omit(props, 'type')}
        iconRender={(visible) => (visible ? <PasswordUnHideIcon /> : <PasswordIcon />)}
        onChange={handleChange}
        rootClassName="custom-input"
      />
    )
  }

  return (
    <InputAntd
      {...omit(props, 'type')}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      rootClassName={`${type === 'search' && 'search-input'} custom-input`}
      prefix={type === "search" ? <SearchIcon /> : undefined}
    />
  );
};

const Input = (props: InputProps) => {
  const {
    value = '',
    onChange,
    type = 'text',
    placeholder = '',
    ...rest
  } = props;

  return (
    <CustomInput
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      {...rest}
    />
  )
};

export default React.memo(Input);