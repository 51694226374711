import {useNavigate, useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {useFilter} from "src/hooks/useFilter";
import {ColumnsType} from "antd/es/table/interface";
import {Space, Typography} from "antd";
import Button from "src/components/Button";
import Table from "src/components/Table";
import AddEmployee from "src/components/Form/AddEmployee";
import {ArrowBackV2, Delete, Plus} from "src/assets/icons";
import DashboardLayout from "src/layout/DashboardLayout";
import React, {useEffect, useState} from "react";
import Modal from "src/components/Modal";
import {
  handleDeleteUserFromGroup, handleGetAllUser,
  handleGetListUserInGroup
} from "src/services/permissions.services";
import {toast} from "react-toastify";
import {handleGetUserInformation} from "src/services/user.services";

const Employees = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const dispatch = useAppDispatch();
  const {userInGroupList, loading} = useAppSelector((state) => state.permission);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [openDeleteEmployee, setOpenDeleteEmployee] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(undefined);

  const {filter, onFilterToQueryString} = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetListUserInGroup({
        filter,
        id: roleId as string
      }));
    },
  })

  const handleDeleteEmployee = () => {
    if (!roleId || !selectedEmployee) return;
    dispatch(handleDeleteUserFromGroup({
      roleId,
      users: [selectedEmployee?.id]
    }))
      .unwrap()
      .then(() => {
        setSelectedEmployee(undefined);
        setOpenDeleteEmployee(false);
        dispatch(handleGetUserInformation());
        dispatch(handleGetListUserInGroup({
          filter,
          id: roleId as string
        }));
        toast.success("Xóa nhân viên thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        const message = err?.response?.data?.message;
        setSelectedEmployee(undefined);
        setOpenDeleteEmployee(false);
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Nhân viên',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_, data) => {
        return <Typography.Text>{data.name}</Typography.Text>
      },
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'username',
      key: 'username',
      render: (_, data) => {
        return <Typography.Text>{data?.username}</Typography.Text>
      },
      width: 225,
    },
    {
      title: 'Phòng ban',
      dataIndex: 'company',
      key: 'company',
      render: (_, data) => {
        return <Typography.Text>{data?.company || '-'}</Typography.Text>
      },
      width: 225,
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, action) => {
        return (
          <Button
            onClick={() => {
              setSelectedEmployee(action);
              setOpenDeleteEmployee(true);
            }}
            icon={<Delete/>}
            style={{
              padding: 0,
              border: 'none',
              boxShadow: 'none',
              width: '28px',
              height: '28px',
              marginRight: '8px'
            }}/>
        );
      },
      width: 128
    },
  ];

  useEffect(() => {
    dispatch(handleGetAllUser({ page: 1, per_page: 10 }))
  }, [])

  return (
    <DashboardLayout
      header={
        <Space style={{gap: '24px'}}>
          <ArrowBackV2 style={{cursor: 'pointer'}} onClick={() => navigate(-1)}/>
          <Typography.Text className="dashboard-title">Danh sách nhân viên</Typography.Text>
        </Space>
      }
      actionButtons={[
        <Button
          key="employee"
          onClick={() => {
            setOpenAddEmployee(true);
          }}
          style={{padding: '3px 8px', backgroundColor: '#2560E5', display: 'flex', alignItems: 'center', gap: '8px'}}
        >
          <Plus/>
          <Typography.Text style={{color: '#fff'}}>Thêm nhân viên</Typography.Text>
        </Button>
      ]}
    >
      <Table
        loading={loading}
        columns={columns}
        data={userInGroupList.data || []}
        total={userInGroupList?.pagination?.total || 0}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({...filter, per_page: Number(value.value)})
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({...filter, page: value})
        }}
        rowKey='id'
      />

      {/*-------------------- THÊM NHÂN VIÊN -----------------------*/}
      <AddEmployee
        open={openAddEmployee}
        closeCb={() => {
          setOpenAddEmployee(false);
          setSelectedEmployee(undefined);
        }}
        successCb={() => {
          setOpenAddEmployee(false);
          dispatch(handleGetUserInformation());
          dispatch(handleGetListUserInGroup({
            filter,
            id: roleId as string
          }));
        }}
        errorCb={() => {
          setOpenAddEmployee(false);
        }}
        selectedRole={selectedEmployee}
        roleId={roleId}
      />

      {/*-------------------- XÓA NHÂN VIÊN -----------------------*/}
      <Modal
        open={openDeleteEmployee}
        modalType='confirm'
        title="Xóa nhân viên"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteEmployee}
        onCancel={() => {
          setSelectedEmployee(undefined);
          setOpenDeleteEmployee(false);
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xóa nhân viên
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default Employees
