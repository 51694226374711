import {createSlice} from '@reduxjs/toolkit'

export interface AuthState {
  userInfo: any
}

const initialState: AuthState = {
  userInfo: null
}

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getUserInformation(state: AuthState, action) {
      state.userInfo = action.payload.data
    },
  }
})

export const {
  getUserInformation
} = userSlice.actions
export default userSlice.reducer