import {Avatar, Space, Typography, Divider } from "antd";
import dayjs from "dayjs";

interface MessageRejectList {
  created_at: string;
  message: string;
  created_by: any
}

interface Props {
  data: MessageRejectList[]
}

const MessageReject = ({data}: Props) => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {
        data.map((item: MessageRejectList) => {
          return (
            <Space direction='vertical' key={item.created_at} style={{ width: '100%' }}>
              <Typography style={{ fontWeight: 500, fontSize: 13, color: '#737373' }}>Từ chối vào lúc: {dayjs(item?.created_at).format('DD/MM/YYYY HH:MM:ss') || '-'}</Typography>
              <Space direction='vertical' style={{ background: '#EEE', padding: '8px', borderRadius: '6px', width: '-webkit-fill-available' }}>
                <Space direction='horizontal'>
                  <Avatar src={item?.created_by?.avatar}>A</Avatar>
                  <Typography.Text>{item?.created_by?.name || '-'}</Typography.Text>
                </Space>
                <Typography.Text><span style={{ color: '#C92127', fontWeight: 500 }}>Lý do:</span> {item?.message || '-'}</Typography.Text>
              </Space>
              <Divider/>
            </Space>
          )
        })
      }
    </Space>
  )
}

export default MessageReject