import { Badge, Space, Tooltip, Typography } from "antd";
import Button from "src/components/Button";
import { ChildrenAim as ChildrenAimIcon, Plus } from "src/assets/icons";
import Table from "src/components/Table";
import React, { useCallback, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table/interface";
import { handleDisplayActionButton, handleLevelBadge } from "src/utils/functions";
import {
  AimLevelEnums,
  ChildrenAimStatusType,
  HavingChildrenEnum,
  LevelGoalEnum,
  ManageTypes,
  ManageTypesRoutes,
  SentResultActionType,
  StatusTrendEnum,
  UnitSelections
} from "src/utils/enums";
import Modal from "src/components/Modal";
import RoleActions from "../../RoleActions";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { AimResponseType, CreateAimResponseType, CreateAimType } from "src/utils/types";
import { handleDeleteAim, handleEditAim, handleGetAimDetail } from "src/services/aims.services";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import CreateOrEditAimLevel3 from "src/components/Form/CreateOrEditAimLevel3";
import CreateEditAction from "src/components/Form/ActionLevel3/CreateEdit";
import CreateOrEditAimLevel2 from "src/components/Form/CreateOrEditAimLevel2";
import { toast } from "react-toastify";
import { resetAimDetail } from "src/reducers/aim.slice";
import useRole from "src/hooks/useRole";
import { ProgressItem } from "src/pages/AimDetail/AimList/AimListLevel3";
import ActionTable from "src/pages/AimDetail/ActionItem/ActionTable";
import { formatNumber } from "src/components/Input";

interface Props {
  parentId?: string;
  childAimList?: AimResponseType[];
  loading: boolean;
  manaType: ManageTypes;
  manaTypeRoute: ManageTypesRoutes;
}

const defaultValues: CreateAimType = {
  name: "",
  fromDate: undefined,
  toDate: undefined,
  parent: null,
  reference: null,
  user: "",
  proportion: 0
};

const AimListLevel1 = ({
  parentId,
  childAimList,
  loading,
  manaType = ManageTypes.OWNER,
  manaTypeRoute
}: Props) => {
  const { isActionWithOwnerGoal } = useRole();
  const { aimDetail, aimLevel2Detail, aimLevel3Detail } = useAppSelector(state => state.aim);
  const { actionDetail } = useAppSelector(state => state.action);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // mục tiêu cấp 2
  const [openCreateEditAimLevel2, setOpenCreateEditAimLevel2] = useState(false);
  const [openDeleteAimLevel2, setOpenDeleteAimLevel2] = useState(false);

  // mục tiêu cấp 3
  const [openCreateEditAimLevel3, setOpenCreateEditAimLevel3] = useState(false);
  const [openCreateEditActionLevel3, setOpenCreateEditActionLevel3] = useState(false);

  const [selectedItem, setSelectedItem] = useState<CreateAimResponseType | undefined>();

  const aimLevel2Methods = useForm({
    defaultValues
  });

  const { isDisplayFastCreateButton } = handleDisplayActionButton(aimDetail);

  const handleOpenDeleteAimLevel = (action: CreateAimResponseType) => {
    setOpenDeleteAimLevel2(true);
    setSelectedItem(action);
  };

  // -------------------------------------------------------------------------------------------------

  const handleOpenEditAimChild = useCallback(
    (action: CreateAimResponseType) => {
      setSelectedItem(action);
      if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
        setOpenCreateEditAimLevel2(true);
        dispatch(handleGetAimDetail({ id: action.id, level: AimLevelEnums.LEVEL_2 }));
      }

      if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
        setOpenCreateEditAimLevel3(true);
        dispatch(handleGetAimDetail({ id: action.id, level: AimLevelEnums.LEVEL_3 }));
      }
    },
    [dispatch]
  );

  const handleAimDetail = useCallback(
    (action: CreateAimResponseType) => {
      if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
        navigate(`/${manaTypeRoute}/${action?.parent?.id}/${action?.id}`);
      }
      if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
        navigate(`/${manaTypeRoute}/${parentId}/${action?.parent?.id}/${action.id}`);
      }
    },
    [navigate, parentId]
  );

  const handleFastCreate = useCallback(
    (action: CreateAimResponseType) => {
      setSelectedItem(undefined);
      if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
        if (action.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
          setOpenCreateEditActionLevel3(true);
          dispatch(handleGetAimDetail({ id: action.id, level: AimLevelEnums.LEVEL_3 }));
        }
        if (action.levelCalculated === HavingChildrenEnum.HAVING_CHILDREN) {
          setOpenCreateEditAimLevel3(true);
          dispatch(handleGetAimDetail({ id: action.id, level: AimLevelEnums.LEVEL_2 }));
        }
      }

      if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
        setOpenCreateEditActionLevel3(true);
        dispatch(handleGetAimDetail({ id: action.id, level: AimLevelEnums.LEVEL_3 }));
      }
    },
    [dispatch]
  );

  const handleSentResultAction = (action: CreateAimResponseType) => {
    const formData = {
      id: action.id,
      result: 1
    };
    dispatch(handleEditAim(formData as any))
      .unwrap()
      .then(() => {
        dispatch(handleGetAimDetail({ id: parentId as string, level: AimLevelEnums.LEVEL_1 }));
        toast.success("Nộp kết quả hành động thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        const message = err?.response?.detail?.message;
        toast.error(message || "Đã có lỗi xảy ra. Vui lòng thử lại sau", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };

  const columns: ColumnsType<CreateAimResponseType> = useMemo(() => {
    const defaultColumns: ColumnsType<CreateAimResponseType> = [
      {
        title: "Tên mục tiêu",
        dataIndex: "name",
        key: "name",
        width: 240,
        render: (_, action) => {
          const { name, levelGoal } = action;
          const badgeColor = handleLevelBadge(levelGoal);
          return (
            <Badge
              size="small"
              count={levelGoal}
              style={{
                backgroundColor: badgeColor,
                fontSize: "10px",
                left: "-12px",
                top: "-6px",
                insetInlineEnd: "unset",
                transform: "none"
              }}>
              <Typography.Paragraph
                ellipsis={{
                  rows: 4,
                  tooltip: true
                }}
                style={{
                  cursor: "pointer"
                }}
                onClick={() => handleAimDetail(action)}>
                {name}
              </Typography.Paragraph>
            </Badge>
          );
        }
      },
      {
        title: "Tỷ trọng",
        dataIndex: "proportion",
        key: "proportion",
        width: 120,
        render: (_, action) => {
          const { proportion } = action;
          return <Typography>{Math.round(Number(proportion) * 100) || 0}%</Typography>;
        }
      },
      {
        title: "Con số mục tiêu",
        dataIndex: "measure",
        key: "measure",
        width: 150,
        render: (_, action) => {
          const { measure, result } = action;

          return action.typeMeasure === SentResultActionType.STATUS ? (
            <Typography>{`${result === 1 ? "Hoàn thành 100%" : "Chưa hoàn thành"}`}</Typography>
          ) : (
            <Typography>{`${formatNumber(measure || 0)}`}</Typography>
          );
        }
      },
      {
        title: "Đơn vị tính",
        dataIndex: "unit",
        key: "unit",
        width: 150,
        render: (_, action) => {
          const unit = UnitSelections.find(item => item.value === action?.unit);
          const { levelCalculated, levelGoal } = action;
          const isTrue =
            (levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN &&
              levelGoal === LevelGoalEnum.LEVEL_2) ||
            levelGoal === LevelGoalEnum.LEVEL_3;
          if (!unit?.label) {
            return (
              <Tooltip
                placement="bottomRight"
                title={
                  isTrue
                    ? "Đo lường theo kiểu hoàn thành/ không hoàn thành"
                    : "Mục tiêu cấp 2 đo lường theo mục tiêu cấp 3"
                }>
                -------
              </Tooltip>
            );
          }
          return <Typography>{unit?.label}</Typography>;
        }
      },
      {
        title: "Xu hướng",
        dataIndex: "statusTrend",
        key: "statusTrend",
        width: 150,
        render: (_, action) => {
          const { statusTrend, levelCalculated, levelGoal, typeMeasure } = action;
          const isTrue =
            (levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN &&
              levelGoal === LevelGoalEnum.LEVEL_2) ||
            levelGoal === LevelGoalEnum.LEVEL_3;

          if (typeMeasure === SentResultActionType.NUMBER) {
            return (
              <Typography>{statusTrend === StatusTrendEnum.DOWN ? "Giảm" : "Tăng"}</Typography>
            );
          }
          return (
            <Tooltip
              placement="bottomRight"
              title={
                isTrue
                  ? "Đo lường theo kiểu hoàn thành/ không hoàn thành"
                  : "Mục tiêu cấp 2 đo lường theo mục tiêu cấp 3"
              }>
              -------
            </Tooltip>
          );
        }
      },
      {
        title: "Thời gian bắt đầu",
        dataIndex: "fromDate",
        key: "fromDate",
        width: 180,
        render: (_, action) => {
          const { fromDate } = action;
          return <Typography>{dayjs(fromDate).format("DD/MM/YYYY")}</Typography>;
        }
      },
      {
        title: "Thời gian kết thúc",
        dataIndex: "toDate",
        key: "toDate",
        width: 180,
        render: (_, action) => {
          const { toDate } = action;
          return <Typography>{dayjs(toDate).format("DD/MM/YYYY")}</Typography>;
        }
      },
      {
        title: "Tỷ lệ hoàn thành",
        dataIndex: "result",
        key: "result",
        width: 250,
        render: (_, action) => {
          return <ProgressItem data={action} />;
        }
      }
    ];

    if (manaType === ManageTypes.OWNER) {
      return [
        ...defaultColumns,
        {
          title: "Thao tác",
          dataIndex: "actions",
          key: "actions",
          render: (_, action) => {
            if (!isActionWithOwnerGoal) return null;
            return (
              <RoleActions
                handleOpenDeleteAimLevel={handleOpenDeleteAimLevel}
                handleOpenEditAimChild={handleOpenEditAimChild}
                handleAimDetail={handleAimDetail}
                handleFastCreate={handleFastCreate}
                handleSentResultAction={handleSentResultAction}
                action={action}
              />
            );
          },
          width: 200
        }
      ];
    }

    return defaultColumns;
  }, [manaType, handleAimDetail, handleFastCreate, handleOpenEditAimChild, isActionWithOwnerGoal]);

  // ------------------------------------------------ XÓA MỤC TIÊU CON CẤP 2 --------------------------------------------------------
  const handleDeleteAimLevel2Item = () => {
    if (!selectedItem) return;
    dispatch(handleDeleteAim(selectedItem.id))
      .unwrap()
      .then(() => {
        setSelectedItem(undefined);
        setOpenDeleteAimLevel2(false);
        dispatch(handleGetAimDetail({ id: parentId as string, level: AimLevelEnums.LEVEL_1 }));
        toast.success("Xóa mục tiêu thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch(err => {
        const message = err?.response?.data?.message;
        toast.error(message || "Đã có lỗi xảy ra. Vui lòng thử lại sau", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setSelectedItem(undefined);
        setOpenDeleteAimLevel2(false);
      });
  };

  const getSumPercents = (goals?: any[]) => {
    return goals?.reduce((sum, { proportion }) => sum += (proportion ?? 0), 0) ?? 0
  }

  const compare = (a: number, b: number) => {
    return Math.abs(a - b) <= 0.001
  }

  const validSumLv2 = compare(getSumPercents(childAimList), 1)

  const lv2NoChildren =
    childAimList?.find((goal: any) => {
      if (goal.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
        return false;
      }
      return goal.childrens == null || goal.childrens.length === 0
    })

  const lv2InvalidSum = childAimList?.find((goal: any) => {
    if (goal.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
      return false;
    }
    return !compare(getSumPercents(goal.childrens ?? []), 1)
  })
  const readyApprove = !lv2NoChildren && !lv2InvalidSum && validSumLv2

  return (
    <Space className="aim-information" direction="vertical">
      <Space
        style={{
          padding: 16,
          borderBottom: '1px solid #EEEEEE',
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            <ChildrenAimIcon />
            <Typography
              style={{ fontWeight: 700, fontSize: '16px', lineHeight: '24px', color: '#525252' }}>
              {readyApprove ? 'Mục tiêu con' : 'Mục tiêu chưa được gửi lên duyệt vì:'}
            </Typography>
          </Space>
          {!validSumLv2 && (
            <Typography
              style={{
                color: '#C92226',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
              }}>
              * Tổng các mục tiêu cấp 2 chưa bằng 100%
            </Typography>
          )}
          {lv2NoChildren && (
            <Typography
              style={{
                color: '#C92226',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
              }}>
              {`* Mục tiêu cấp hai "${lv2NoChildren.name}" chưa khai báo mục tiêu cấp 3`}
            </Typography>
          )}
          {lv2InvalidSum && (
            <Typography
              style={{
                color: '#C92226',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
              }}>
              {`* Mục tiêu cấp hai "${lv2InvalidSum.name}" có tổng các mục tiêu cấp 3 nhỏ hơn 100%`}
            </Typography>
          )}
        </Space>
        {manaType === ManageTypes.OWNER && isActionWithOwnerGoal && isDisplayFastCreateButton && (
          <Button
            onClick={() => {
              aimLevel2Methods.reset(defaultValues);
              setOpenCreateEditAimLevel2(true);
            }}
            style={{
              padding: "3px 8px",
              backgroundColor: "#2560E5",
              display: "flex",
              alignItems: "center",
              gap: "8px"
            }}>
            <Plus />
            <Typography.Text style={{ color: "#fff" }}>Thêm mục tiêu</Typography.Text>
          </Button>
        )}
      </Space>

      <Space style={{ padding: 16, width: "100%" }} direction="vertical">
        <Table
          loading={loading}
          rowKey='id'
          expandable={{
            expandedRowRender: (record: any) => {
              if (record?.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
                return (
                  <ActionTable
                    isHidingButton={aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL}
                    childAimList={record?.childrenActions}
                    parentId={parentId}
                    aimDetail={record}
                    loading={false}
                    manaType={manaType}
                    level={AimLevelEnums.LEVEL_1}
                  />
                );
              } else
                return (
                  <Table
                    showHeader={false}
                    loading={false}
                    columns={columns}
                    data={record.childrens || []}
                    isHidePagination
                    rowKey='id'
                    expandable={{
                      expandedRowRender: (record: any) => {
                        return (
                          <ActionTable
                            isHidingButton={
                              aimDetail?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL
                            }
                            childAimList={record?.childrenActions}
                            parentId={parentId}
                            loading={false}
                            aimDetail={record}
                            manaType={manaType}
                            level={AimLevelEnums.LEVEL_1}
                          />
                        );
                      },
                      rowExpandable: (record: any) => {
                        return record.childrenActions && record.childrenActions.length > 0;
                      }
                    }}
                  />
                );
            },
            rowExpandable: (record: any) => {
              if (record?.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN) {
                return record.childrenActions && record.childrenActions.length > 0;
              } else return record.childrens && record.childrens.length > 0;
            }
          }}
          data={childAimList || []}
          columns={columns}
          isHidePagination
        />
      </Space>

      {/*----------------- Xóa mục tiêu con ---------------------*/}
      <Modal
        open={openDeleteAimLevel2}
        modalType="confirm"
        title="Xóa mục tiêu con"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteAimLevel2Item}
        onCancel={() => {
          setOpenDeleteAimLevel2(false);
          setSelectedItem(undefined);
        }}>
        <Typography
          style={{
            textAlign: "center"
          }}>
          Xác nhận xoá mục con tiêu này
        </Typography>
      </Modal>

      {/* -----------------Tạo nhanh/ Chỉnh sửa mục tiêu cấp 2 -----------------*/}
      <CreateOrEditAimLevel2
        open={openCreateEditAimLevel2}
        selectedItem={selectedItem}
        parentId={parentId}
        errorCb={() => {
          setSelectedItem(undefined);
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2));
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3));
        }}
        closeCb={() => {
          setOpenCreateEditAimLevel2(false);
          setSelectedItem(undefined);
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2));
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3));
        }}
        successCb={() => {
          setOpenCreateEditAimLevel2(false);
          setSelectedItem(undefined);
          dispatch(handleGetAimDetail({ id: parentId as string, level: AimLevelEnums.LEVEL_1 }));
        }}
      />

      {/*----------------- Tạo nhanh/ Chỉnh sửa mục tiêu cấp 3 -----------------*/}
      <CreateOrEditAimLevel3
        open={openCreateEditAimLevel3}
        parentId={aimLevel2Detail?.id}
        selectedItem={selectedItem?.id}
        errorCb={() => {
          setSelectedItem(undefined);
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2));
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3));
        }}
        closeCb={() => {
          setOpenCreateEditAimLevel3(false);
          setSelectedItem(undefined);
        }}
        successCb={() => {
          setOpenCreateEditAimLevel3(false);
          setSelectedItem(undefined);
          dispatch(handleGetAimDetail({ id: parentId as string, level: AimLevelEnums.LEVEL_1 }));
        }}
      />

      {/* ----------------- Tạo nhanh/ Chỉnh sửa hành động cấp 3 -----------------*/}
      <CreateEditAction
        data={actionDetail}
        parentId={aimLevel3Detail?.id}
        selectedItem={selectedItem?.id}
        open={openCreateEditActionLevel3}
        errorCb={() => {
          setOpenCreateEditActionLevel3(false);
          setSelectedItem(undefined);
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_2));
          dispatch(resetAimDetail(AimLevelEnums.LEVEL_3));
        }}
        successCb={() => {
          setOpenCreateEditActionLevel3(false);
          setSelectedItem(undefined);
          dispatch(handleGetAimDetail({ id: parentId as string, level: AimLevelEnums.LEVEL_1 }));
        }}
      />
    </Space>
  );
};
export default React.memo(AimListLevel1);
