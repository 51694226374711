import Button from "src/components/Button";
import {Delete, Detail, Edit, FastCreate, SentResult} from "src/assets/icons";
import React, { useState } from "react";
import {CreateAimResponseType} from "src/utils/types";
import {handleDisplayActionButton} from "src/utils/functions";
import useRole from "src/hooks/useRole";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import { ChildrenAimStatusType, HavingChildrenEnum, SendResultTypeEnum } from "src/utils/enums";
import { UploadOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import SendResult from "src/components/Form/ActionLevel3/SendResult";

interface Props {
  action: CreateAimResponseType
  handleAimDetail?: (action: CreateAimResponseType) => void
  handleOpenDeleteAimLevel?: (action: CreateAimResponseType) => void
  handleOpenEditAimChild?: (action: CreateAimResponseType) => void
  handleFastCreate?: (action: CreateAimResponseType) => void
  handleSentResultAction?: (action: CreateAimResponseType) => void
}

const RoleActions = (props: Props) => {
  const {
    action,
    handleOpenDeleteAimLevel,
    handleOpenEditAimChild,
    handleFastCreate,
    handleAimDetail,
    handleSentResultAction
  } = props;
  const [openSentResultAction, setOpenSentResultAction] = useState(false);
  const dispatch = useAppDispatch();

  const { isActionWithOwnerGoal } = useRole();
  const {aimDetail: aimDetailLevel1} = useAppSelector((state) => state.aim);

  const { isDisplayEditButton, isDisplayDeleteButton, isDisplayFastCreateButton, isSentOrApprovedResult } = handleDisplayActionButton(aimDetailLevel1);
  if (isActionWithOwnerGoal) {
    return <>
      <div style={{display: 'flex'}}>
        {
          isDisplayFastCreateButton && (
            <Button
              onClick={() => handleFastCreate && handleFastCreate(action)}
              icon={<FastCreate/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
          )
        }

        <Button
          onClick={() => handleAimDetail && handleAimDetail(action)}
          icon={<Detail/>}
          style={{
            padding: 0,
            border: 'none',
            boxShadow: 'none',
            width: '28px',
            height: '28px',
            marginRight: '8px'
          }}/>

        {
          isDisplayEditButton && (
            <Button
              onClick={() => handleOpenEditAimChild && handleOpenEditAimChild(action)}
              icon={<Edit/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
          )
        }
        {
          isDisplayDeleteButton && (
            <Button
              onClick={() => handleOpenDeleteAimLevel && handleOpenDeleteAimLevel(action)}
              icon={<Delete/>}
              style={{padding: 0, border: 'none', boxShadow: 'none', width: '28px', height: '28px', marginRight: '8px'}}
            />
          )
        }
        {
          (action.levelCalculated === HavingChildrenEnum.NO_HAVING_CHILDREN || action.levelGoal === HavingChildrenEnum.HAVING_CHILDREN ) &&
          !isSentOrApprovedResult &&
          !(aimDetailLevel1?.statusGoal === ChildrenAimStatusType.REGISTER_GOAL || aimDetailLevel1?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL) &&
          (
            <Tooltip placement="bottomRight" title="Nộp kết quả">
            <Button
              style={{display: 'flex', alignItems: 'center', padding: 0, border: 'none'}}
              onClick={() => {
                setOpenSentResultAction(true);
              }}
            >
              <SentResult/>
            </Button>
          </Tooltip>
          )
        }
      </div>
      {/*---------------------------- Nộp kết quả hành động  -----------------------------------*/}
      <SendResult
        open={openSentResultAction}
        sendResultType={SendResultTypeEnum.ACTION}
        detail={action}
        successCb={() => {
          setOpenSentResultAction(false)
        }}
        errorCb={() => {
          setOpenSentResultAction(false)
        }}
        handleSentResultAction={handleSentResultAction}
        quickSubmit={true}
      />
    </>
  }
  return null;
}

export default RoleActions;
