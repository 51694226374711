import './styles.scss';

interface Props {
  error: string;
}

const ErrorMessage = ({error}: Props) => {
  if (!error) return null;
  return <p className="error-message">{error}</p>;
};

export default ErrorMessage;
