import {createSlice} from '@reduxjs/toolkit'
import {CreatePeriodResponse, Pagination} from "src/utils/types";
import {isEmpty} from "lodash";

export interface PeriodState {
  periodList: Pagination<CreatePeriodResponse>
  myPeriodList: Pagination<CreatePeriodResponse>
  periodDetail: CreatePeriodResponse | null
  loadingPeriod: boolean
}

export const periodInitialState: PeriodState = {
  loadingPeriod: false,
  periodList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  myPeriodList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  },
  periodDetail: null
}

export const periodSlice = createSlice({
  name: 'tweet',
  initialState: periodInitialState,
  reducers: {
    getPeriodList(state: PeriodState, action) {
      if (isEmpty(action.payload.data)) state.periodList = periodInitialState.periodList
      else state.periodList = action.payload.data
    },
    getMyPeriodList(state: PeriodState, action) {
      if (isEmpty(action.payload.data)) state.myPeriodList = periodInitialState.myPeriodList
      else state.myPeriodList = action.payload.data
    },
    getPeriodDetail(state: PeriodState, action) {
      state.periodDetail = action.payload.data
    },
    startLoading(state: PeriodState) {
      state.loadingPeriod = true
    },
    finishLoading(state: PeriodState) {
      state.loadingPeriod = false
    }
  }
})

export const {
  startLoading,
  finishLoading,
  getMyPeriodList,
  getPeriodList,
  getPeriodDetail
} = periodSlice.actions
export default periodSlice.reducer
