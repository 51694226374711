const Delete = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" fill="#F5F5F5"/>
      <g clipPath="url(#clip0_6132_8371)">
        <rect width="15" height="15" transform="translate(7 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.9872 9.21152L11.8535 10.0073C11.2677 10.0391 10.683 10.0839 10.0997 10.1431L8.82467 10.2681C8.56842 10.2931 8.38092 10.5181 8.40592 10.7806C8.43092 11.0431 8.66217 11.2306 8.92467 11.2056L10.1997 11.0806C13.4684 10.7493 16.7684 10.8743 20.0747 11.2056H20.1247C20.3622 11.2056 20.5684 11.0243 20.5872 10.7806C20.6122 10.5243 20.4247 10.2931 20.1684 10.2681C19.1614 10.1692 18.1549 10.0882 17.15 10.0321L17.1497 10.0303L17.0122 9.21777L17.0116 9.2141C16.9178 8.63911 16.7778 7.78027 15.3184 7.78027H13.6809C12.2257 7.78027 12.0874 8.61034 11.9874 9.21024L11.9872 9.21152ZM16.0934 9.37402L16.1971 9.98661C15.066 9.94199 13.9374 9.93191 12.8125 9.96658L12.9184 9.36777L12.9194 9.36215C13.0123 8.81093 13.0269 8.72402 13.6872 8.72402H15.3247C15.9872 8.72402 16.0059 8.83027 16.0934 9.37402ZM12.4934 21.2175H16.5059C18.6872 21.2175 18.7747 20.0113 18.8434 19.0363L19.2497 12.7425C19.2684 12.48 19.0684 12.2613 18.8122 12.2425C18.5559 12.23 18.3309 12.4238 18.3122 12.68L17.9059 18.9738C17.8434 19.9238 17.8184 20.28 16.5059 20.28H12.4934C11.188 20.28 11.1622 19.9242 11.0935 18.9755L11.0934 18.9738L10.6872 12.68C10.6684 12.4238 10.4497 12.23 10.1872 12.2425C9.93092 12.2613 9.73092 12.4863 9.74967 12.7425L10.1559 19.0363C10.2247 20.0113 10.3122 21.2175 12.4934 21.2175ZM15.5372 17.7803H13.4559C13.1997 17.7803 12.9872 17.5678 12.9872 17.3115C12.9872 17.0553 13.1997 16.8428 13.4559 16.8428H15.5372C15.7934 16.8428 16.0059 17.0553 16.0059 17.3115C16.0059 17.5678 15.7934 17.7803 15.5372 17.7803ZM12.9372 15.2803H16.0622C16.3184 15.2803 16.5309 15.0678 16.5309 14.8115C16.5309 14.5553 16.3184 14.3428 16.0622 14.3428H12.9372C12.6809 14.3428 12.4684 14.5553 12.4684 14.8115C12.4684 15.0678 12.6809 15.2803 12.9372 15.2803Z" fill="#333333"/>
      </g>
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" stroke="#EEEEEE"/>
      <defs>
        <clipPath id="clip0_6132_8371">
          <rect width="15" height="15" fill="white" transform="translate(7 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Delete;