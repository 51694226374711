const Edit = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" fill="#F5F5F5"/>
      <g clipPath="url(#clip0_6132_8354)">
        <rect width="15" height="15" transform="translate(7 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4621 19.1992C10.0809 19.1992 9.72463 19.068 9.46838 18.8242C9.14338 18.518 8.98713 18.0555 9.04338 17.5555L9.27463 15.5305C9.31838 15.1492 9.54963 14.643 9.81838 14.368L14.0955 9.84083L14.1032 9.83266L14.9496 8.93674C16.2309 7.58049 17.5684 7.54299 18.9246 8.82424C20.2809 10.1055 20.3184 11.443 19.0371 12.7992L13.9059 18.2305C13.6434 18.5117 13.1559 18.7742 12.7746 18.8367L10.7621 19.1805C10.724 19.1827 10.6874 19.1858 10.6516 19.1888H10.6516C10.5877 19.1941 10.5262 19.1992 10.4621 19.1992ZM10.4996 15.0055L14.1986 11.0858C14.6608 12.3165 15.6655 13.2677 16.9187 13.6638L13.2184 17.5805C13.0934 17.7117 12.7934 17.8742 12.6121 17.9055L10.5996 18.2492C10.3934 18.2805 10.2246 18.243 10.1121 18.1367C9.99963 18.0305 9.94963 17.8617 9.97463 17.6555L10.2059 15.6305C10.2309 15.4492 10.3746 15.1367 10.4996 15.0055ZM18.3496 12.1492L17.6587 12.8805C16.3131 12.6522 15.2405 11.6282 14.9451 10.2946L15.6309 9.56799C16.0559 9.11799 16.4746 8.81799 16.9559 8.81799C17.3434 8.81799 17.7746 9.01799 18.2746 9.49924C19.4059 10.5617 19.1246 11.3242 18.3496 12.1492ZM8.87463 21.2183H20.1246C20.3809 21.2183 20.5934 21.0058 20.5934 20.7495C20.5934 20.4933 20.3809 20.2808 20.1246 20.2808H8.87463C8.61838 20.2808 8.40588 20.4933 8.40588 20.7495C8.40588 21.0058 8.61838 21.2183 8.87463 21.2183Z" fill="#333333"/>
      </g>
      <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" stroke="#EEEEEE"/>
      <defs>
        <clipPath id="clip0_6132_8354">
          <rect width="15" height="15" fill="white" transform="translate(7 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Edit;