import {Button} from "antd";
import {toast} from "react-toastify";

export const TemplateFile = () => {
  const handleDownload = async () => {
    const url = process.env.REACT_APP_TEMPLATE_LINK;
    if (!url) {
      toast.error("Chưa tải lên tệp template", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    toast.success("Tải template thành công", {
      position: toast.POSITION.TOP_RIGHT
    });
    window.location.href = url;
  };

  return (
    <Button type="primary" onClick={handleDownload}>
      Tải file mẫu
    </Button>
  );
};
