const Plus = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6183_85680)">
        <rect width="15" height="15" transform="translate(0 0.5)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 14.7178C3.79375 14.7178 0.78125 11.7053 0.78125 7.99902C0.78125 4.29277 3.79375 1.28027 7.5 1.28027C11.2063 1.28027 14.2188 4.29277 14.2188 7.99902C14.2188 11.7053 11.2063 14.7178 7.5 14.7178ZM7.5 2.21777C4.3125 2.21777 1.71875 4.81152 1.71875 7.99902C1.71875 11.1865 4.3125 13.7803 7.5 13.7803C10.6875 13.7803 13.2812 11.1865 13.2812 7.99902C13.2812 4.81152 10.6875 2.21777 7.5 2.21777ZM5 8.46777C4.74375 8.46777 4.53125 8.25527 4.53125 7.99902C4.53125 7.74277 4.74375 7.53027 5 7.53027H7.03125V5.49902C7.03125 5.24277 7.24375 5.03027 7.5 5.03027C7.75625 5.03027 7.96875 5.24277 7.96875 5.49902V7.53027H10C10.2563 7.53027 10.4688 7.74277 10.4688 7.99902C10.4688 8.25527 10.2563 8.46777 10 8.46777H7.96875V10.499C7.96875 10.7553 7.75625 10.9678 7.5 10.9678C7.24375 10.9678 7.03125 10.7553 7.03125 10.499V8.46777H5Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_6183_85680">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default Plus;