import {Checkbox, Typography, Tooltip, Space} from "antd";
import {Controller, useFormContext} from "react-hook-form";
import Input from "src/components/Input";
import ErrorMessage from "src/components/ErrorMessage";
import {Exclamation} from "src/assets/icons";
import React, {useMemo} from "react";
import {useAppSelector} from "src/redux/hooks";
import {PermissionList, PermissionListEnum} from "src/utils/enums";
import {Permission} from "src/utils/types";

const handleCustomLabel = (action: PermissionListEnum) => {
  if (action === PermissionListEnum.APPROVED_LEADER_GOAL) {
    return (
      <Space direction='horizontal' style={{ alignItems: 'center' }}>
        <Typography.Text>Duyệt mục tiêu leader</Typography.Text>
        <Tooltip zIndex={2000} overlayStyle={{ minWidth: 'fit-content' }} placement="rightBottom" title='Duyệt mục tiêu của các leader cấp dưới'>
          <Exclamation/>
          <div/>
        </Tooltip>
      </Space>
    )
  }

  if (action === PermissionListEnum.APPROVED_EMPLOYEE_GOAL) {
    return (
      <Space direction='horizontal' style={{ alignItems: 'center' }}>
        <Typography.Text>Duyệt mục tiêu nhân viên</Typography.Text>
        <Tooltip zIndex={2000} overlayStyle={{ minWidth: 'fit-content' }} placement="rightBottom" title='Duyệt mục tiêu của toàn bộ nhân viên cấp dưới'>
          <Exclamation/>
          <div/>
        </Tooltip>
      </Space>
    )
  }

  return PermissionList[action]
}

const Form = () => {
  const {permissionList} = useAppSelector((state) => state.permission);
  const {control} = useFormContext();

  const permissionListConverted = useMemo(() => {
    if (!permissionList) return;
    return permissionList.data.map((item: Permission) => ({
      label: handleCustomLabel(item.action),
      value: item.id
    }))
  }, [permissionList])

  return (
    <div>
      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Tên nhóm {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>
      <Controller
        control={control}
        name="name"
        rules={{
          required: 'Vui lòng điền tên nhóm'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Input
                value={value}
                onChange={onChange}
                placeholder="Nhập tên nhóm"
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />

      <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
        Phân quyền {' '}
        <span style={{color: '#FF3141'}}>*</span>
      </Typography>

      <Controller
        control={control}
        name="permissions"
        rules={{
          required: 'Vui lòng chọn quyền'
        }}
        render={({field: {onChange, value}, fieldState}) => {
          return (
            <div>
              <Checkbox.Group
                style={{
                  flexDirection: 'column',
                  padding: '16px', border: '1px solid #eee', borderRadius: '8px',
                  gap: 8,
                  width: '100%'
                }}
                options={permissionListConverted}
                value={value}
                onChange={onChange}
              />
              {fieldState?.error?.message && <ErrorMessage error={fieldState?.error?.message}/>}
            </div>
          )
        }}
      />
    </div>
  )
}

export default Form