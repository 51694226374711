const FastCreate = (props: any) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" fill="#FAFAFA"/>
      <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" stroke="#EEEEEE"/>
      <g clipPath="url(#clip0_6868_87402)">
        <rect width="14" height="14" transform="translate(7 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.5645 17.5C13.5645 17.7392 13.7628 17.9375 14.002 17.9375C14.2411 17.9375 14.4395 17.7392 14.4395 17.5V14.4375H17.502C17.7411 14.4375 17.9395 14.2392 17.9395 14C17.9395 13.7608 17.7411 13.5625 17.502 13.5625H14.4395V10.5C14.4395 10.2608 14.2411 10.0625 14.002 10.0625C13.7628 10.0625 13.5645 10.2608 13.5645 10.5V13.5625H10.502C10.2628 13.5625 10.0645 13.7608 10.0645 14C10.0645 14.2392 10.2628 14.4375 10.502 14.4375H13.5645V17.5Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_6868_87402">
          <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default FastCreate