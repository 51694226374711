import Modal from "src/components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import Form from "./Form";
import React, {useCallback, useEffect, useState} from "react";
import {CreateAimResponseType, CreateAimType} from "src/utils/types";
import dayjs from "dayjs";
import {handleCreateNewAim, handleEditAim} from "src/services/aims.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {toast} from "react-toastify";

interface Props {
  selectedItem?: CreateAimResponseType;
  open: boolean;
  successCb?: () => void;
  errorCb?: () => void;
  closeCb?: () => void;
}

const defaultValues: CreateAimType = {
  reference: null,
  user: "",
  phase: undefined,
  children: [],
};

const CreateOrEditAimLevel1 = ({selectedItem, open, successCb, errorCb, closeCb}: Props) => {
  const [loading, setLoading] = useState(false);
  const [is_excel_error, setIsExcelError] = useState<boolean>(false);

  const aimLevel1Methods = useForm({
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    formState: {errors},
  } = aimLevel1Methods;
  const dispatch = useAppDispatch();

  const {userInfo} = useAppSelector(state => state.user);
  const {periodList} = useAppSelector(state => state.period);

  const handleSuccess = useCallback(() => {
    setLoading(false);
    reset(defaultValues);
    successCb && successCb();
    if (selectedItem) {
      toast.success("Chỉnh sửa mục tiêu thành công", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success("Tạo mới mục tiêu thành công", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [reset, selectedItem, successCb]);

  const handleError = useCallback(
    (err: any) => {
      setLoading(false);
      const message = err?.response?.data?.message;
      reset(defaultValues);
      errorCb && errorCb();
      if (message === "GOAL_IS_EXISTED") {
        toast.error("Mục tiêu đã tồn tại", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(message || "Đã có lỗi xảy ra. Vui lòng thử lại sau", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [errorCb, reset],
  );

  const createOrEdit = useCallback(
    (data: CreateAimType) => {
      // setLoading(true);
      const periodSelected = periodList.data.find(item => item.id === data?.phase?.value);
      if (is_excel_error) {
        toast.error("Tệp excel chưa hợp lệ, vui lòng kiểm tra lại", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (data.user && (!data.children || data.children.length === 0)) {
        toast.error("Chưa nhập tệp excel khi tạo mục tiêu cho người khác", {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      console.log("data submit:", data);
      const formData = {
        ...data,
        user: data?.user?.value ?? userInfo?.id,
        fromDate: dayjs(periodSelected?.fromDate).format("YYYY-MM-DD"),
        toDate: dayjs(periodSelected?.toDate).format("YYYY-MM-DD"),
        phase: data?.phase?.value,
        organization: data?.organization?.value,
        children: data?.children ?? [],
      };

      if (selectedItem) {
        dispatch(
          handleEditAim({
            ...formData,
            id: selectedItem?.id,
          }),
        )
          .unwrap()
          .then(handleSuccess)
          .catch(handleError);
      } else dispatch(handleCreateNewAim(formData)).unwrap().then(handleSuccess).catch(handleError);
    },
    [
      dispatch,
      handleError,
      handleSuccess,
      is_excel_error,
      periodList.data,
      selectedItem,
      userInfo?.id,
    ],
  );

  useEffect(() => {
    if (!selectedItem) return;
    reset({
      name: selectedItem.name,
      phase: {
        label: selectedItem?.phase?.name as string,
        value: selectedItem?.phase?.id as string,
      },
      organization: {
        label: selectedItem?.organization?.name as string,
        value: selectedItem?.organization?.id as string,
      },
    });
  }, [selectedItem]);

  return (
    <FormProvider {...aimLevel1Methods}>
      <form onSubmit={handleSubmit(createOrEdit)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          modalType="form"
          title={selectedItem ? "Chỉnh sửa mục tiêu cấp 1" : "Tạo mới mục tiêu cấp 1"}
          okText={selectedItem ? "Cập nhật" : "Tạo mới"}
          cancelText="Hủy"
          onCancel={() => {
            reset(defaultValues);
            closeCb && closeCb();
          }}
          style={{
            minWidth: "1200px",
          }}>
          <Form onExcelError={setIsExcelError} />
        </Modal>
      </form>
    </FormProvider>
  );
};

export default React.memo(CreateOrEditAimLevel1);
