const DeleteWhite = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="28" height="26" rx="3.5" fill="white"/>
      <rect x="0.5" y="1" width="28" height="26" rx="3.5" stroke="#EEEEEE"/>
      <g clipPath="url(#clip0_7004_164861)">
        <rect width="15" height="15" transform="translate(7 6.5)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.9878 8.7125L11.8541 9.50824C11.2683 9.54005 10.6836 9.58483 10.1003 9.64404L8.82528 9.76904C8.56903 9.79404 8.38153 10.019 8.40653 10.2815C8.43153 10.544 8.66278 10.7315 8.92528 10.7065L10.2003 10.5815C13.469 10.2503 16.769 10.3753 20.0753 10.7065H20.1253C20.3628 10.7065 20.569 10.5253 20.5878 10.2815C20.6128 10.0253 20.4253 9.79404 20.169 9.76904C19.162 9.6702 18.1555 9.58917 17.1506 9.53308L17.1503 9.53125L17.0128 8.71875L17.0122 8.71508C16.9184 8.14009 16.7784 7.28125 15.319 7.28125H13.6815C12.2263 7.28125 12.088 8.11131 11.988 8.71121L11.9878 8.7125ZM16.094 8.875L16.1977 9.48758C15.0667 9.44297 13.938 9.43289 12.8131 9.46756L12.919 8.86875L12.92 8.86313C13.0129 8.31191 13.0275 8.225 13.6878 8.225H15.3253C15.9878 8.225 16.0065 8.33125 16.094 8.875ZM12.494 20.7185H16.5065C18.6878 20.7185 18.7753 19.5122 18.844 18.5372L19.2503 12.2435C19.269 11.981 19.069 11.7622 18.8128 11.7435C18.5565 11.731 18.3315 11.9247 18.3128 12.181L17.9065 18.4747C17.844 19.4247 17.819 19.781 16.5065 19.781H12.494C11.1886 19.781 11.1628 19.4252 11.0942 18.4764L11.094 18.4747L10.6878 12.181C10.669 11.9247 10.4503 11.731 10.1878 11.7435C9.93153 11.7622 9.73153 11.9872 9.75028 12.2435L10.1565 18.5372C10.2253 19.5122 10.3128 20.7185 12.494 20.7185ZM15.5378 17.2812H13.4565C13.2003 17.2812 12.9878 17.0688 12.9878 16.8125C12.9878 16.5562 13.2003 16.3438 13.4565 16.3438H15.5378C15.794 16.3438 16.0065 16.5562 16.0065 16.8125C16.0065 17.0688 15.794 17.2812 15.5378 17.2812ZM12.9378 14.7812H16.0628C16.319 14.7812 16.5315 14.5687 16.5315 14.3125C16.5315 14.0563 16.319 13.8438 16.0628 13.8438H12.9378C12.6815 13.8438 12.469 14.0563 12.469 14.3125C12.469 14.5687 12.6815 14.7812 12.9378 14.7812Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_7004_164861">
          <rect width="15" height="15" fill="white" transform="translate(7 6.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeleteWhite;