import { Typography } from 'antd';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Select from 'src/components/Select';
import { FilterParams } from 'src/hooks/useFilter';
import { useInfinityScroll } from 'src/hooks/useInfinityScroll';
import { handleGetPeriodList } from 'src/services/periods.services';
import { CreatePeriodResponse, DropdownSelectProps } from 'src/utils/types';
import SelectOrganization from 'src/components/SelectOrganization';
import {
  ChildrenAimStatusType,
  EmployeeTypeEnum,
  LeaderFilter,
  StatusGoalReportFilter,
} from 'src/utils/enums';

import './styles.scss';

interface Props {
  onFilterToQueryString: (params: FilterParams) => void;
  filter: FilterParams;
}

const ReportFilter = ({ onFilterToQueryString, filter }: Props) => {
  const { control } = useFormContext();

  const { data: periods, handleLoadMore: handleLoadMorePeriod } =
    useInfinityScroll<CreatePeriodResponse>({
      onFetchData: handleGetPeriodList,
      defaultFilter: { page: 1, per_page: 10, is_public: true },
    });

  const updateQuery = async (query: FilterParams) => {
    const debouncedSearch = debounce(async query => {
      onFilterToQueryString({ ...filter, ...query });
    }, 300);
    debouncedSearch(query);
  };

  const periodOptions = useMemo(() => {
    return periods.data.map(item => ({
      label: item.name || '',
      value: item.id as string,
    }));
  }, [periods.data]);

  return (
    <div className="filter-wrap">
      <div className="filter-wrap-item">
        <div className="filter-item">
          <Typography style={{ flex: 1 }}>Khối</Typography>
          <Controller
            control={control}
            name="organization"
            render={({ field: { value } }) => {
              return (
                <SelectOrganization
                  placeholder="--- Chọn khối ---"
                  value={filter.organization?.[0] || value}
                  onSelect={(value: any) => {
                    updateQuery({
                      organization: value ? [value.value] : undefined,
                    });
                  }}
                />
              );
            }}
          />
        </div>
        {filter.organization?.[0] && (
          <div className="filter-item">
            <Typography style={{ flex: 1 }}>Phòng</Typography>
            <Controller
              control={control}
              name="organization"
              render={({ field: { onChange } }) => {
                return (
                  <SelectOrganization
                    placeholder="--- Chọn phòng ---"
                    organizationId={filter.organization?.[0]}
                    value={filter.organization?.[1] as any}
                    onSelect={(value: any) => {
                      if (filter.organization && filter.organization[0]) {
                        const organizationFilter = [...filter.organization];
                        if (value) {
                          organizationFilter.splice(1, 2, value.value);
                        } else {
                          organizationFilter.splice(1, 2);
                        }
                        updateQuery({
                          organization: organizationFilter,
                        });
                        onChange(value);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        )}
        {filter.organization?.[1] && (
          <div className="filter-item">
            <Typography style={{ flex: 1 }}>Nhóm</Typography>
            <Controller
              control={control}
              name="organization"
              render={({ field: { onChange } }) => {
                return (
                  <SelectOrganization
                    placeholder="--- Chọn nhóm ---"
                    organizationId={filter.organization?.[1]}
                    value={filter.organization?.[2] as any}
                    onSelect={(value: any) => {
                      if (filter.organization && filter.organization[1]) {
                        const organizationFilter = [...filter.organization];
                        if (value) {
                          organizationFilter.splice(2, 1, value.value);
                        } else {
                          organizationFilter.splice(2, 1);
                        }
                        updateQuery({
                          organization: organizationFilter,
                        });
                        onChange(value);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        )}
        <div className="filter-item">
          <Typography style={{ flex: 1 }}>Chức vụ</Typography>
          <Controller
            control={control}
            name="employeeType"
            rules={{
              required: 'Vui lòng chọn quyền',
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  style={{ flex: 5 }}
                  placeholder="--- Chọn điều kiện lọc ---"
                  options={LeaderFilter}
                  value={value}
                  onSelect={value => {
                    onChange((value as DropdownSelectProps).value);
                    if ((value as DropdownSelectProps).value === EmployeeTypeEnum.ALL) {
                      updateQuery({ employeeType: undefined });
                    } else {
                      updateQuery({
                        employeeType: (value as DropdownSelectProps).value as EmployeeTypeEnum,
                      });
                    }
                  }}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="filter-wrap-item">
        <div className="filter-item">
          <Typography style={{ flex: 1 }}>Giai đoạn</Typography>
          <Controller
            control={control}
            name="phase"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  style={{ flex: 5 }}
                  placeholder="--- Chọn giai đoạn ---"
                  options={periodOptions}
                  value={value}
                  onSelect={value => {
                    if (!value) {
                      onChange(undefined);
                      updateQuery({ phase: undefined });
                      return;
                    }
                    const phaseId = (value as DropdownSelectProps)?.value;
                    updateQuery({ phase: phaseId });
                    onChange(value);
                  }}
                  onPopupScroll={event => handleLoadMorePeriod(event)}
                />
              );
            }}
          />
        </div>
        <div className="filter-item">
          <Typography style={{ flex: 1 }}>Trạng thái</Typography>
          <Controller
            control={control}
            name="statusGoal"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  style={{ flex: 5 }}
                  placeholder="--- Chọn trạng thái ---"
                  options={StatusGoalReportFilter}
                  value={value}
                  mode="multiple"
                  onSelect={value => {
                    onChange(value)
                    if (!value) {
                      updateQuery({ statusGoal: undefined, statusResult: undefined });
                      return;
                    }
                    const _statusGoal: any[] = [];
                    const _statusResult: any[] = [];
                    (value as DropdownSelectProps[]).map(
                      (it: DropdownSelectProps) => {
                        if (['draft_goal', 'register_goal', 'rejected_goal', 'approved_goal'].includes(it?.value as string)) _statusGoal.push(it?.value)
                        if (['send_result', 'approved_result', 'rejected_result'].includes(it?.value as string)) _statusResult.push(it?.value)
                      }
                    );
                    updateQuery({ statusGoal: _statusGoal as ChildrenAimStatusType[], statusResult: _statusResult as ChildrenAimStatusType[] });
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
