import instanceAxios from 'src/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { FilterParams } from 'src/hooks/useFilter';
import {
  getPermissionList,
  getGroupList,
  getUserInGroupList,
  getGroupDetail,
  getOrganizationList,
  startLoading,
  finishLoading,
} from 'src/reducers/permission.slice';

// danh sách quyền
export const handleGetListPermission = createAsyncThunk(
  'permission/get-permission',
  async (params: undefined, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/api/v1/permissions`);
      thunkAPI.dispatch(getPermissionList(response.data));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// tạo nhóm quyền
export const handleCreateNewGroup = createAsyncThunk(
  'groups/create',
  async (params: { name: string; permissions: string[] }, thunkAPI) => {
    try {
      const response = await instanceAxios.post(`/api/v1/groups`, params);
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// chỉnh sửa nhóm quyền
export const handleEditGroup = createAsyncThunk(
  'groups/edit',
  async (params: { id: string; name: string; permissions: string[] }, thunkAPI) => {
    try {
      const response = await instanceAxios.patch(`/api/v1/groups/${params.id}`, {
        name: params.name,
        permissions: params.permissions,
      });
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// lấy ra chi tiết nhóm quyền
export const handleGetDetailGroup = createAsyncThunk(
  'groups/get-detail',
  async (id: string, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/api/v1/groups/${id}`);
      thunkAPI.dispatch(getGroupDetail(response.data.data));
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// danh sách nhóm quyền
export const handleGetListGroup = createAsyncThunk(
  'groups/list',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response = await instanceAxios.get(`/api/v1/groups`, { params });
      thunkAPI.dispatch(getGroupList(response.data));
      thunkAPI.dispatch(finishLoading());
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(finishLoading());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// danh sách user thuộc nhóm quyền
export const handleGetListUserInGroup = createAsyncThunk(
  'groups/list',
  async (params: { filter: FilterParams; id: string } | undefined, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response = await instanceAxios.get(`/api/v1/groups/${params?.id}/users`, {
        params: params?.filter,
      });
      thunkAPI.dispatch(getUserInGroupList(response.data));
      thunkAPI.dispatch(finishLoading());
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(finishLoading());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// thêm user vào nhóm quyền
export const handleAddUserToGroup = createAsyncThunk(
  'groups/add-user',
  async (params: { roleId: string; users: string[] }, thunkAPI) => {
    try {
      const response = await instanceAxios.post(`/api/v1/groups/${params.roleId}/users`, {
        users: params.users,
      });
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// lấy danh sách phòng ban
export const handleGetListOrganization = createAsyncThunk(
  'groups/organization-list',
  async (params: (FilterParams & { is_managed?: boolean }) | undefined, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`/api/organization-unit`, { params });
      thunkAPI.dispatch(getOrganizationList(response.data));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// lấy danh sách phòng ban
export const handleGetListOrganizationByRole = createAsyncThunk(
  'groups/organization-list/by-role',
  async (
    params: (Omit<FilterParams, 'organization'> & { organization?: string }) | undefined,
    thunkAPI,
  ) => {
    try {
      const response = await instanceAxios.get(`/api/organization-unit/by-role`, { params });
      thunkAPI.dispatch(getOrganizationList(response.data));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// xóa quyền
export const handleDeleteGroup = createAsyncThunk('groups/delete', async (id: string, thunkAPI) => {
  try {
    const response = await instanceAxios.delete(`/api/v1/groups/${id}`);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

// xóa user khỏi quyền
export const handleDeleteUserFromGroup = createAsyncThunk(
  'groups/delete',
  async (params: { roleId: string; users: string[] }, thunkAPI) => {
    try {
      const response = await instanceAxios.delete(`/api/v1/groups/${params.roleId}/users`, {
        data: { users: params.users },
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

// lấy ra danh sách tất cả user
export const handleGetAllUser = createAsyncThunk(
  'users/get-all',
  async (params: FilterParams | undefined, thunkAPI) => {
    try {
      const response = await instanceAxios.get(`${process.env.REACT_APP_PORTAL_URL}/api/users`, { params });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
