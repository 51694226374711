import Cookies from "js-cookie";

export const SHARE_COOKIE_DOMAIN = process.env.REACT_APP_SHARE_COOKIE_DOMAIN || '127.0.0.1'

console.log(SHARE_COOKIE_DOMAIN, 'SHARE_COOKIE_DOMAIN')

const api = Cookies.withAttributes({ path: '/', domain: SHARE_COOKIE_DOMAIN })

export default class CookiesCustom {
  static get(key: string) {
  }

  static set = (key: string, value: string) => {
    return api.set(key, value)
  }

  static remove(key: string) {
    return api.remove(key)
  }

  static removeToken = () => {
    return api.remove(`token`)
  }

  static removeRefreshToken = () => {
    return api.remove(`refresh_token`)
  }

  static getToken = () => {
    const data = api.get(`token`)
    return data || null
  }

  static getRefreshToken = () => {
    const data = api.get(`refresh_token`)
    return data || null
  }

  static setToken = async (token: string, expiresIn: string) => {
    this.removeToken()
    api.set('token', token, { expires: parseInt(expiresIn), secure: true })
  }

  static setRefreshToken = async (token: string, expiresIn: string) => {
    this.removeRefreshToken()
    api.set('refresh_token', token, { expires: parseInt(expiresIn), secure: true })
  }
}
