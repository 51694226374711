const NextButtonPagination = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2757_218182)">
        <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
        <path d="M10.1351 6.99524L8.82174 5.6819L6.68174 3.5419C6.22841 3.09524 5.45508 3.41524 5.45508 4.05524V8.20857V11.9486C5.45508 12.5886 6.22841 12.9086 6.68174 12.4552L10.1351 9.0019C10.6884 8.45524 10.6884 7.54857 10.1351 6.99524Z" fill="#737373"/>
      </g>
      <defs>
        <clipPath id="clip0_2757_218182">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default NextButtonPagination