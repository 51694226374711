import type { ThemeConfig } from 'antd';

export const configThemes: ThemeConfig = {
  token: {
    fontFamily: 'Be Vietnam Pro, sans-serif',
    colorPrimary: '#2560E5',
    colorTextBase: '#333',
    colorBorder: '#EEEEEE'
  },
  components: {
    Divider: {
      marginLG: 12
    },
    Spin: {
      colorPrimary: '#C92127',
      dotSize: 32
    },
    DatePicker: {
      colorText: '#494E50',
      colorTextHeading: '#494E50',
      colorBorder: '#EEE',
    },
    Input: {
      colorBorder: '#EEE',
      colorPrimaryHover: '#EEE',
      colorText: '#333',
    },
    Button: {
      colorPrimary: '#2560E5',
      fontSize: 14,
      lineHeight: 1.25
    },
    Pagination: {
      itemActiveBg: '#DB1B33',
    },
    Table: {
      colorText: '#737373',
      colorTextHeading: '#525252',
      fontWeightStrong: 700
    },
    Typography: {
      colorText: '#333',
    },
    Checkbox: {
      colorBorder: '#CCCCCC'
    },
    Menu: {
      itemSelectedColor: '#fff',
      itemSelectedBg: '#EF4444',
      itemColor: '#fff',
      itemMarginBlock: 0,
      itemMarginInline: 0,
      itemBorderRadius: 0,
      itemPaddingInline: 24,
      itemHoverColor: '#fff',
      iconSize: 24
    },
    Badge: {
      textFontSize: 12
    },
    Tabs: {
      colorText: '#333333',
      itemActiveColor: '#C92127',
      itemHoverColor: '#C92127',
      itemSelectedColor: '#C92127',
      inkBarColor: '#C92127',
    },
    Tooltip: {
      colorTextLightSolid: '#333',
      colorBgSpotlight: '#fff'
    },
    Collapse: {
      colorBorder: 'none',
      headerBg: 'transparent',
      colorBgBase: 'transparent',
      contentBg: 'transparent',
    }
  }
};
