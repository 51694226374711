import {ColumnType} from 'antd/es/table';
import moment from 'moment';
import {
  LevelGoalEnum,
  SentResultActionType,
  StatusTrendEnum,
  UnitEnums,
  UnitSelections,
} from 'src/utils/enums';

export interface GoalEntity {
  name: string;
  proportion?: number;
  levelCalculated: LevelGoalEnum;
  typeMeasure: SentResultActionType;
  statusTrend: StatusTrendEnum;
  measure: number;
  unit: UnitEnums;
  fromDate: string;
  toDate: string;
  children?: GoalEntity[];
}
export interface FieldConfig {
  column_name: string;
  title?: string;
  width?: number;
  column_index: number;
  key: keyof GoalEntity;
  is_date?: boolean;
  getValue: (a: string) => any | undefined;
  getError: (a: string) => string | undefined;
}

const maplevel: Record<string, LevelGoalEnum> = {
  'Mục tiêu Cấp 2': LevelGoalEnum.LEVEL_2,
  'Mục tiêu Cấp 3': LevelGoalEnum.LEVEL_3,
};
const mapType: Record<string, SentResultActionType> = {
  'Dạng Số': SentResultActionType.NUMBER,
  'Dạng Hoàn thành/Chưa Hoàn thành': SentResultActionType.STATUS,
};

const mapTrend: Record<string, StatusTrendEnum> = {
  Tăng: StatusTrendEnum.UP,
  Giảm: StatusTrendEnum.DOWN,
};

const mapDay = (a: string) => {
  if (a == null) {
    return null;
  }
  let d = null;
  try {
    if (!isNaN(Number(a))) {
      d = moment(excelDateToJSDate(Number(a)));
    } else {
      d = moment(a, 'DD/MM/YYYY');
    }
  } catch (e) {
    /* empty */
  }
  console.log('validate day', a, d);
  return d;
};

const compareStr = (a: string | null, b: string | null) => {
  return a?.toLowerCase()?.trim() === b?.toLowerCase()?.trim();
};
export const Fields: FieldConfig[] = [
  {
    column_name: 'Cấp Mục tiêu',
    title: 'Cấp',
    column_index: 0,
    key: 'levelCalculated',
    width: 100,
    getValue: (a: string) => maplevel[a?.trim()],
    getError: (a: string) => {
      if (a == null) {
        return 'Thiếu giá trị';
      }
      if (!Object.keys(maplevel).includes(a?.trim())) {
        return `Giá trị phải là ${Object.keys(maplevel).join(', ')}`;
      }
    },
  },
  {
    column_name: 'NỘI DUNG MỤC TIÊU',
    title: 'Mục tiêu',
    column_index: 1,
    width: 400,
    key: 'name',
    getError: (a: string) => {
      if (a == null) {
        return 'Thiếu giá trị';
      }
      if (a.length === 0) {
        return 'Thiếu giá trị';
      }
    },
    getValue: (a: string) => {
      return a;
    },
  },
  {
    column_name: 'TỶ TRỌNG MỤC TIÊU',
    title: 'Tỷ trọng',
    column_index: 2,
    key: 'proportion',
    getError: (a: string) => {
      if (a == null) {
        return 'Thiếu giá trị';
      }
      if (isNaN(Number(a))) {
        return 'Không đúng định dạng số';
      }
      const num = Number(a);
      if (num < 0 || num > 100) {
        return 'Tỷ lệ phần trăm phải trong khoảng từ 0 đến 100';
      }
    },
    getValue: (a: string) => {
      return isNaN(Number(a)) ? null : Number(a) / 100;
    },
  },
  {
    column_name: 'Cách đo lường mục tiêu',
    title: 'Loại',
    column_index: 3,
    key: 'typeMeasure',
    getValue: (a: string) => {
      return mapType[a?.trim()];
    },
    getError: (a: string) => {
      if (a == null) {
        return 'Thiếu giá trị';
      }
      if (!Object.keys(mapType).includes(a?.trim())) {
        return `Giá trị phải là ${Object.keys(mapType).join(', ')}`;
      }
    },
  },
  {
    column_name: 'Xu hướng mục tiêu',
    title: 'Xu hướng',
    column_index: 4,
    key: 'statusTrend',
    getValue: (a: string) => {
      return mapTrend[a?.trim()] ?? null;
    },
    getError: (a: string) => {
      if (a == null) {
        return undefined;
      }
      if (mapTrend && !Object.keys(mapTrend).includes(a?.trim())) {
        return `Giá trị phải là ${Object.keys(mapTrend).join(', ')}`;
      }
    },
  },
  {
    column_name: 'CHỈ SỐ CỦA MỤC TIÊU',
    column_index: 5,
    title: 'Chỉ số',
    key: 'measure',
    getValue: (a: string) => {
      return isNaN(Number(a)) ? null : Number(a);
    },
    getError: (a: string) => {
      if (a == null) {
        return undefined;
      }
      if (isNaN(Number(a))) {
        return 'Định dạng không phải là số';
      }
    },
  },
  {
    column_name: 'Đơn vị tính',
    title: 'Đơn vị',
    column_index: 6,
    key: 'unit',
    getValue: (a: string) => {
      return UnitSelections.find(item => compareStr(item.label, a))?.value ?? null;
    },
    getError: (a: string) => {
      if (a == null) {
        return undefined;
      }
      const value = UnitSelections.find(item => compareStr(item.label, a))?.value;
      if (value == null) {
        return 'Giá trị không hợp lệ ';
      }
    },
  },
  {
    column_name: 'THỜI GIAN BẮT ĐẦU',
    title: 'Từ ngày',
    column_index: 7,
    key: 'fromDate',
    is_date: true,
    getValue: (a: string) => {
      return mapDay(a);
    },
    getError: (a: string) => {
      if (a === null) {
        return 'Thiếu giá trị';
      }
      const value = mapDay(a);
      if (value == null) {
        return 'Thời gian không hợp lệ';
      }
    },
  },
  {
    column_name: 'THỜI GIAN KẾT THÚC',
    title: 'Đến ngày',
    column_index: 8,
    key: 'toDate',
    is_date: true,
    getValue: (a: string) => {
      return mapDay(a);
    },
    getError: (a: string) => {
      if (a === null) {
        return 'Thiếu giá trị';
      }
      const value = mapDay(a);
      if (value == null) {
        return 'Thời gian không hợp lệ';
      }
    },
  },
];
export const validateColumns = (row: string[]) => {
  return null;
  for (const {column_index, column_name} of Fields) {
    const name = row[column_index];
    if (!name || !compareStr(name, column_name)) {
      return `Cột thứ ${column_index + 1} phải là ${column_name}`;
    }
  }
  return null;
};

export function excelDateToJSDate(excelDate: number) {
  const jsDate = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
  return jsDate;
}

export const validateRow = (row: string[]) => {
  const obj: any = {};
  for (const {column_index, key, getError} of Fields) {
    obj[key] = getError(row[column_index]);
  }
  return obj;
};

export const rowsToGoals = (rows: string[][]) => {
  const goals: Partial<Record<keyof GoalEntity, any>>[] = [];
  for (const row of rows) {
    const goal: Partial<Record<keyof GoalEntity, any>> = {};
    for (const {column_index, key, getValue} of Fields) {
      goal[key] = getValue(row[column_index]);
    }
    if (goal['levelCalculated'] === LevelGoalEnum.LEVEL_2) {
      goals.push({...goal, children: []});
      continue;
    }
    goals[goals.length - 1].children?.push(goal);
  }
  console.log('Rows to goals:', goals);
  return goals;
};
