const SentResult = () => {
  return (
    <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="40" height="28" rx="3.5" fill="#EEEEEE"/>
      <rect x="0.5" y="0.5" width="40" height="28" rx="3.5" stroke="#EEEEEE"/>
      <g clipPath="url(#clip0_6356_106541)">
        <rect width="15" height="15" transform="translate(13 7)" fill="white" fillOpacity="0.01"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.75 11.2178H19.25C18.7179 11.2178 17.7776 11.2178 17.5718 10.0187C16.0959 10.1918 15.3438 10.8465 15.3438 13.2493V16.9993C15.3438 19.3118 15.8 20.2805 18.625 20.2805H22.375C25.2 20.2805 25.6562 19.3118 25.6562 16.9993V13.2493C25.6562 10.854 24.9088 10.1959 23.442 10.0203C23.3615 10.5882 23.0394 11.2178 21.75 11.2178ZM22.5312 9.53221C22.531 10.1236 22.5171 10.2803 21.75 10.2803H19.25C18.6413 10.2803 18.4742 10.2803 18.4689 9.53658C18.4695 9.52226 18.4694 9.50776 18.4688 9.4931C18.4696 8.71777 18.6328 8.71777 19.25 8.71777H21.75C22.3656 8.71777 22.5296 8.71777 22.5312 9.48705C22.5305 9.50223 22.5305 9.51729 22.5312 9.53221ZM23.443 9.07704C23.2724 7.78027 22.2962 7.78027 21.75 7.78027H19.25C18.703 7.78027 17.725 7.78027 17.5563 9.08236C15.3235 9.32453 14.4062 10.5615 14.4062 13.2493V16.9993C14.4062 19.6055 15.1125 21.218 18.625 21.218H22.375C25.8875 21.218 26.5938 19.6055 26.5938 16.993V13.243C26.5938 10.5608 25.6763 9.32425 23.443 9.07704ZM19.7563 17.5926C19.6375 17.5926 19.5187 17.5488 19.425 17.4551L18.4875 16.5176C18.3063 16.3363 18.3063 16.0363 18.4875 15.8551C18.6687 15.6738 18.9688 15.6738 19.15 15.8551L19.7563 16.4613L21.925 14.2926C22.1062 14.1113 22.4062 14.1113 22.5875 14.2926C22.7688 14.4738 22.7688 14.7738 22.5875 14.9551L20.0875 17.4551C20 17.5488 19.875 17.5926 19.7563 17.5926Z" fill="#333333"/>
      </g>
      <defs>
        <clipPath id="clip0_6356_106541">
          <rect width="15" height="15" fill="white" transform="translate(13 7)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default SentResult