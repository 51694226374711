const PrevButtonPagination = (props: any) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2757_218152)">
        <rect width="16" height="16" fill="white" fillOpacity="0.01"/>
        <path d="M9.32146 3.54845L7.18146 5.68845L5.86813 6.99512C5.31479 7.54845 5.31479 8.44845 5.86813 9.00178L9.32146 12.4551C9.77479 12.9085 10.5481 12.5818 10.5481 11.9485V8.20845V4.05512C10.5481 3.41512 9.77479 3.09512 9.32146 3.54845Z" fill="#737373"/>
      </g>
      <defs>
        <clipPath id="clip0_2757_218152">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default PrevButtonPagination