import {useNavigate} from "react-router";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {useFilter} from "src/hooks/useFilter";
import {ColumnsType} from "antd/es/table/interface";
import {GroupDetail} from "src/utils/types";
import {Typography} from "antd";
import Button from "src/components/Button";
import Table from "src/components/Table";
import AddRole from "src/components/Form/AddRole";
import {AddEmployee as AddEmployeeIcon, Delete, Edit, Plus} from "src/assets/icons";
import DashboardLayout from "src/layout/DashboardLayout";
import AddEmployee from "src/components/Form/AddEmployee";
import React, {useEffect, useState} from "react";
import Modal from "src/components/Modal";
import {handleDeleteGroup, handleGetListGroup, handleGetListPermission,} from "src/services/permissions.services";
import {toast} from "react-toastify";
import {handleGetUserInformation} from "src/services/user.services";

const RolePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {groupsList, loading} = useAppSelector((state) => state.permission);
  const [openAddRole, setOpenAddRole] = useState(false);
  const [openDeleteRole, setOpenDeleteRole] = useState(false);
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const [selectedRole, setSelectedRole] = useState<GroupDetail | undefined>(undefined);

  const {filter, onFilterToQueryString} = useFilter({
    onFetchData: (filter) => {
      dispatch(handleGetListGroup(filter));
    },
    defaultFilter: {
      page: 1,
      per_page: 10,
    }
  })

  const handleEditRole = (role: any) => {
    setSelectedRole(role);
    setOpenAddRole(true);
  }

  const handleDeleteRole = () => {
    if (!selectedRole) return;
    dispatch(handleDeleteGroup(selectedRole.id))
      .unwrap()
      .then(() => {
        setSelectedRole(undefined);
        setOpenDeleteRole(false);
        dispatch(handleGetListGroup());
        toast.success("Xóa vai trò thành công", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        setSelectedRole(undefined);
        setOpenDeleteRole(false);
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  const columns: ColumnsType<GroupDetail> = [
    {
      title: 'Vai trò',
      dataIndex: 'role',
      key: 'role',
      width: 300,
      render: (_, action) => {
        return (
          <div>{action.name}</div>
        );
      },
    },
    {
      title: 'Danh sách nhân viên',
      dataIndex: 'employees',
      key: 'employees',
      render: (_, action) => {
        return (
          <Button
            onClick={() => navigate(`/role/employees/${action?.id}`)}
            style={{
              padding: '3px 12px',
              border: '1px solid #2560E5',
              color: '#2560E5'
            }}
          >
            Danh sách
          </Button>
        );
      },
      width: 225,
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, action) => {
        return (
          <>
            <Button
              onClick={() => handleEditRole(action)}
              icon={<Edit/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
            <Button
              onClick={() => {
                setSelectedRole(action);
                setOpenDeleteRole(true);
              }}
              icon={<Delete/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
            <Button
              onClick={() => {
                setOpenAddEmployee(true);
                setSelectedRole(action);
              }}
              icon={<AddEmployeeIcon/>}
              style={{
                padding: 0,
                border: 'none',
                boxShadow: 'none',
                width: '28px',
                height: '28px',
                marginRight: '8px'
              }}/>
          </>
        );
      },
      width: 128
    },
  ];

  useEffect(() => {
    dispatch(handleGetListPermission());
  }, [])

  return (
    <DashboardLayout
      header={
        <Typography.Text className="dashboard-title">Phân quyền</Typography.Text>
      }
      actionButtons={[
        <Button
          key="add-role"
          onClick={() => {
            setOpenAddRole(true);
          }}
          style={{padding: '3px 8px', backgroundColor: '#2560E5', display: 'flex', alignItems: 'center', gap: '8px'}}
        >
          <Plus/>
          <Typography.Text style={{color: '#fff'}}>Thêm vai trò</Typography.Text>
        </Button>
      ]}
    >
      <Table
        loading={loading}
        columns={columns}
        data={groupsList.data || []}
        total={groupsList?.pagination?.total || 0}
        pagination={filter}
        handleItemPerPage={(value) => {
          onFilterToQueryString({...filter, per_page: Number(value.value)})
        }}
        handlePageNumber={(value) => {
          onFilterToQueryString({...filter, page: value})
        }}
        rowKey='id'
      />
      <AddRole
        open={openAddRole}
        closeCb={() => {
          setOpenAddRole(false);
          setSelectedRole(undefined);
        }}
        successCb={() => {
          setOpenAddRole(false);
          setSelectedRole(undefined);
          onFilterToQueryString(filter);
          dispatch(handleGetUserInformation());
        }}
        errorCb={() => {
          setOpenAddRole(false);
          setSelectedRole(undefined);
        }}
        selectedRole={selectedRole}
      />

      {/*-------------------- THÊM - CHỈNH SỬA NHÂN VIÊN -----------------------*/}
      <AddEmployee
        open={openAddEmployee}
        closeCb={() => {
          setOpenAddEmployee(false);
          setSelectedRole(undefined);
        }}
        successCb={() => {
          setOpenAddEmployee(false);
          setSelectedRole(undefined);
          dispatch(handleGetUserInformation());
        }}
        errorCb={() => {
          setOpenAddEmployee(false);
          setSelectedRole(undefined);
        }}
        selectedRole={selectedRole}
        roleId={selectedRole?.id}
      />

      {/*-------------------- XÓA VAI TRÒ -----------------------*/}
      <Modal
        open={openDeleteRole}
        modalType='confirm'
        title="Xóa vai trò"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={handleDeleteRole}
        onCancel={() => {
          setSelectedRole(undefined);
          setOpenDeleteRole(false);
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xóa vai trò
        </Typography>
      </Modal>
    </DashboardLayout>
  )
}

export default RolePage
