import {CaretRightOutlined} from '@ant-design/icons';
import type {CSSProperties} from 'react';
import React from 'react';
import type {CollapseProps} from 'antd';
import {Collapse} from 'antd';
import './styles.scss';

interface Props {
  detail: React.ReactNode,
  shortDetail: React.ReactNode,
}


const panelStyle: React.CSSProperties = {
  background: '#fff',
  borderRadius: '6px',
  border: 'none',
};

const CollapseInformation = ({ detail, shortDetail }: Props) => {
  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      label: shortDetail,
      children: detail,
      style: panelStyle,
    }
  ];

  return (
    <Collapse
      collapsible="icon"
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      items={getItems(panelStyle)}
      rootClassName='collapse-custom'
    />
  );
};

export default CollapseInformation;