import {Outlet} from "react-router-dom";
import Sidebar from "../components/Sidebar";
import CustomHeader from "../components/Header";

import {Layout} from 'antd';
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {ToastContainer} from 'react-toastify';
import {LOCALSTORAGE} from "src/services/local.services";
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss'

const {Header} = Layout;

const headerStyle: React.CSSProperties = {
  backgroundColor: '#fff',
  padding: '12px 32px',
  height: 'auto',
  lineHeight: 'unset',
  textAlign: 'end'
};

const MainLayout = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsed = () => {
    setCollapsed(prev => {
      LOCALSTORAGE.setItem('KPI_SIDEBAR', String(!prev));
      return !prev
    });
  }

  useEffect(() => {
    const is_collapse = LOCALSTORAGE.getItem('KPI_SIDEBAR');

    if (is_collapse === 'true') {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }

    if (location?.pathname === '/') {
      navigate('/aims')
    }
  }, [])

  return (
    <Layout rootClassName="main-layout">
      <ToastContainer/>
      <Sidebar collapsed={collapsed} handleCollapsed={handleCollapsed}/>
      <Layout style={{marginLeft: collapsed ? 80 : 256, zIndex: 1000}}>
        <Header style={headerStyle}>
          <CustomHeader/>
        </Header>
        <Outlet/>
      </Layout>
    </Layout>
  )
}

export default MainLayout
