import {Badge, Space, Tooltip, Typography} from "antd";
import Table from "src/components/Table";
import React, {useCallback, useMemo, useState} from "react";
import {ColumnsType} from "antd/es/table/interface";
import {handleDisplayActionButton, handleLevelBadge} from "src/utils/functions";
import {
  AimLevelEnums,
  ChildrenAimStatusType, HavingChildrenEnum,
  LevelGoalEnum,
  ManageTypes,
  SendResultTypeEnum, SentResultActionType,
  StatusTrendEnum,
  UnitSelections
} from "src/utils/enums";
import {useNavigate} from "react-router";
import {AimResponseType, CreateAimResponseType} from "src/utils/types";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import useRole from "src/hooks/useRole";
import {ProgressItem} from "src/pages/AimDetail/AimList/AimListLevel3";
import CreateEditAction from "src/components/Form/ActionLevel3/CreateEdit";
import {handleGetAimDetail} from "src/services/aims.services";
import SendResult from "src/components/Form/ActionLevel3/SendResult";
import Modal from "src/components/Modal";
import Button from "src/components/Button";
import {DeleteWhite, EditVWhite, SentResultWhite} from "src/assets/icons";
import {handleDeleteAction} from "src/services/actions.services";
import {toast} from "react-toastify";
import './styles.scss'
import { formatNumber } from "src/components/Input";

interface Props {
  parentId?: string;
  childAimList?: AimResponseType[];
  aimDetail: CreateAimResponseType | undefined;
  loading: boolean;
  manaType: ManageTypes;
  level: AimLevelEnums;
  isHidingButton: boolean;
}

const ActionTable = ({
                       parentId,
                       childAimList,
                       loading,
                       manaType = ManageTypes.OWNER,
                       aimDetail, level,
                       isHidingButton = false
                     }: Props) => {
  const {isActionWithOwnerGoal} = useRole();

  const [openEditAction, setOpenEditAction] = useState(false);
  const [openDeleteAction, setOpenDeleteAction] = useState(false);
  const [openSentResultAction, setOpenSentResultAction] = useState(false);

  const {aimDetail: aimDetailLevel1} = useAppSelector((state) => state.aim);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedItem, setSelectedItem] = useState<CreateAimResponseType | undefined>();

  const handleAimDetail = useCallback((action: CreateAimResponseType) => {
    if (action.levelGoal === LevelGoalEnum.LEVEL_2) {
      navigate(`/aims/${action?.parent?.id}/${action?.id}`)
    }
    if (action.levelGoal === LevelGoalEnum.LEVEL_3) {
      navigate(`/aims/${parentId}/${action?.parent?.id}/${action.id}`)
    }
  }, [navigate, parentId])

  const { isSentOrApprovedResult } = handleDisplayActionButton(aimDetailLevel1);
  const isHideAddAction = useCallback((action: CreateAimResponseType) => {
    const isSentResult = typeof action?.result === 'number';
    const isApprovedGoal = aimDetail?.parent?.statusGoal === ChildrenAimStatusType.APPROVED_GOAL;
    return isApprovedGoal || isSentResult;
  }, [aimDetail]);

  const columns: ColumnsType<CreateAimResponseType> = useMemo(() => {
    const defaultColumns: ColumnsType<CreateAimResponseType> = [
      {
        title: 'Tên mục tiêu',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (_, action) => {
          const {name, levelGoal, created_at} = action;
          const badgeColor = handleLevelBadge(levelGoal);
          return (
            <Badge
              size='small'
              count={levelGoal}
              style={{
                backgroundColor: badgeColor,
                fontSize: '10px',
                left: '-12px',
                top: '-6px',
                insetInlineEnd: 'unset',
                transform: 'none',
              }}
            >
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  tooltip: true
                }}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleAimDetail(action)}
              >
                {name}
              </Typography.Paragraph>
              <Typography
                style={{
                  color: '#737373',
                  fontSize: '12px'
                }}
              >
                Ngày tạo: {dayjs(created_at).format('DD/MM/YYYY')}
              </Typography>
            </Badge>
          );
        },
      },
      {
        title: 'Tỷ trọng',
        dataIndex: 'proportion',
        key: 'proportion',
        width: 120,
        render: () => <Tooltip placement="bottomRight" title="Hành động không có tỷ trọng">-------</Tooltip>
      },
      {
        title: 'Con số mục tiêu',
        dataIndex: 'measure',
        key: 'measure',
        width: 150,
        render: (_, action) => {
          const {measure, result} = action;
          return (
            action.typeMeasure === SentResultActionType.STATUS ? <Typography>{`${result === 1 ? 'Hoàn thành 100%' : 'Chưa hoàn thành'}`}</Typography> :
            <Typography>{`${formatNumber(measure || 0)}`}</Typography>
          );
        },
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'unit',
        key: 'unit',
        width: 150,
        render: (_, action) => {
          const unit = UnitSelections.find(item => item.value === action?.unit);
          if (!unit?.label) {
            return (
              <Tooltip
                placement="bottomRight"
                title="Đo lường theo kiểu hoàn thành/ không hoàn thành"
              >
                -------
              </Tooltip>
            )
          }
          return (
            <Typography>{unit?.label}</Typography>
          );
        },
      },
      {
        title: 'Xu hướng',
        dataIndex: 'statusTrend',
        key: 'statusTrend',
        width: 150,
        render: (_, action) => {
          const {statusTrend, typeMeasure} = action;

          if (typeMeasure === SentResultActionType.STATUS) {
            return (
              <Tooltip
                placement="bottomRight"
                title="Đo lường theo kiểu hoàn thành/ không hoàn thành"
              >
                -------
              </Tooltip>
            )
          }
          return (
            <Typography>{statusTrend === StatusTrendEnum.DOWN ? 'Giảm' : 'Tăng'}</Typography>
          );
        },
      },
      {
        title: 'Thời gian bắt đầu',
        dataIndex: 'fromDate',
        key: 'fromDate',
        width: 180,
        render: (_, action) => {
          const {fromDate} = action;
          return (
            <Typography>{dayjs(fromDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Thời gian kết thúc',
        dataIndex: 'toDate',
        key: 'toDate',
        width: 180,
        render: (_, action) => {
          const {toDate} = action;
          return (
            <Typography>{dayjs(toDate).format('DD/MM/YYYY')}</Typography>
          );
        },
      },
      {
        title: 'Tỷ lệ hoàn thành',
        dataIndex: 'result',
        key: 'result',
        width: 250,
        render: (_, action) => {
          return (
            <ProgressItem data={action}/>
          );
        },
      },
    ];

    if (manaType === ManageTypes.OWNER) {
      return [
        ...defaultColumns,
        {
          title: 'Thao tác',
          dataIndex: 'actions',
          key: 'actions',
          render: (_, action) => {
            if (!isActionWithOwnerGoal) return null;
            return (
              <>
                {
                  manaType === ManageTypes.OWNER && isActionWithOwnerGoal && !isSentOrApprovedResult && (
                    <Space style={{width: 250, display: 'flex'}}>
                      {
                        !isHidingButton && (
                          <Tooltip placement="bottomRight" title="Chỉnh sửa">
                            <Button
                              style={{display: 'flex', alignItems: 'center', padding: 0, border: 'none'}}
                              onClick={() => {
                                setOpenEditAction(true);
                                setSelectedItem(action);
                              }}
                            >
                              <EditVWhite/>
                            </Button>
                          </Tooltip>
                        )
                      }

                      {
                        !isHidingButton && (
                          <Tooltip placement="bottomRight" title="Xóa">
                            <Button
                              onClick={() => {
                                setOpenDeleteAction(true);
                                setSelectedItem(action);
                              }}
                              style={{display: 'flex', alignItems: 'center', padding: 0, border: 'none'}}
                            >
                              <DeleteWhite/>
                            </Button>
                          </Tooltip>
                        )
                      }

                      {
                        !isSentOrApprovedResult &&
                        !(aimDetailLevel1?.statusGoal === ChildrenAimStatusType.REGISTER_GOAL || aimDetailLevel1?.statusGoal === ChildrenAimStatusType.DRAFT_GOAL) && (
                          <Tooltip placement="bottomRight" title="Nộp kết quả">
                            <Button style={{display: 'flex', alignItems: 'center', padding: 0, border: 'none'}}
                                    onClick={() => {
                                      setOpenSentResultAction(true);
                                      setSelectedItem(action);
                                    }}>
                              <SentResultWhite/>
                            </Button>
                          </Tooltip>
                        )
                      }
                    </Space>
                  )
                }
              </>
            )
          },
          width: 200
        },
      ]
    }

    return defaultColumns
  }, [manaType, handleAimDetail, isActionWithOwnerGoal, isHideAddAction])

  const deleteAction = () => {
    if (!selectedItem) return;
    dispatch(handleDeleteAction(selectedItem.id))
      .unwrap()
      .then(() => {
        setOpenDeleteAction(false)
        dispatch(handleGetAimDetail({id: parentId as string, level}));
        toast.success('Xóa hành động thành công', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        setOpenDeleteAction(false)
        toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
  }

  return (
    <>
      <Table
        showHeader={false}
        loading={loading}
        expandable={{
          expandedRowRender: () => <></>,
          rowExpandable: () => false,
        }}
        data={childAimList || []}
        columns={columns}
        isHidePagination
        rowClassName='row-item'
        rootClassName='table-class'
        rowKey='id'
      />
      {/*---------------------------- Chỉnh sửa hành động trong mục tiêu con cấp 3 -----------------------------------*/}
      <CreateEditAction
        data={selectedItem}
        selectedItem={selectedItem?.id}
        parentId={parentId}
        open={openEditAction}
        errorCb={() => {
          setOpenEditAction(false)
        }}
        successCb={() => {
          setOpenEditAction(false)
          dispatch(handleGetAimDetail({id: parentId as string, level}));
        }}
      />

      {/*---------------------------- Nộp kết quả hành động  -----------------------------------*/}
      <SendResult
        open={openSentResultAction}
        sendResultType={SendResultTypeEnum.ACTION}
        detail={selectedItem}
        successCb={() => {
          dispatch(handleGetAimDetail({id: parentId as string, level}));
          setOpenSentResultAction(false)
        }}
        errorCb={() => {
          setOpenSentResultAction(false)
        }}
      />

      {/*----------------- Xóa hành động ---------------------*/}
      <Modal
        open={openDeleteAction}
        modalType='confirm'
        title="Xóa hành động"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={deleteAction}
        onCancel={() => {
          setOpenDeleteAction(false)
        }}
      >
        <Typography
          style={{
            textAlign: 'center'
          }}
        >
          Xác nhận xoá hành động này
        </Typography>
      </Modal>
    </>
  )
}
export default React.memo(ActionTable)
