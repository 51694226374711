import { isMobile } from "react-device-detect";

import "./styles.scss";

interface Props {
  title: string;
}

const NotSupport = ({ title }: Props) => {
  if (isMobile) {
    return <div className="not-support">{title}</div>;
  }
  return null;
};

export default NotSupport;
