import {Typography} from 'antd';
import {FC, useEffect, useMemo, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import ErrorMessage from 'src/components/ErrorMessage';
import Select from 'src/components/Select';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import {handleGetMyPeriodList} from 'src/services/periods.services';
import {handleGetAllUser} from 'src/services/permissions.services';
import {PermissionListEnum} from 'src/utils/enums';
import {SelectedFile} from './SelectedFile';
import {TemplateFile} from './TemplateFile';

interface Props {
  onExcelError: (a: boolean) => void;
}

const allUserInitial = {
  data: [],
  pagination: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
  },
};
const Form: FC<Props> = ({onExcelError}) => {
  const {userInfo} = useAppSelector(state => state.user);
  const [allUser, setAllUser] = useState<any>(allUserInitial);
  const {control, watch, getValues, setValue} = useFormContext();
  const dispatch = useAppDispatch();

  const {myPeriodList} = useAppSelector(state => state.period);
  const {organizationList} = useAppSelector(state => state.permission);

  const [page, setPage] = useState(2);
  const [searchKey, setSearchKey] = useState('');

  const organization = watch('organization');

  const periodListFilter = useMemo(() => {
    return myPeriodList.data.map(item => ({
      label: item.name || '',
      value: item.id as string,
    }));
  }, [myPeriodList.data]);

  const helpUser = getValues('user');
  const organizationOptions = useMemo(() => {
    const orgList = helpUser ? helpUser.organizations : organizationList.data;
    return (
      orgList?.map((item: any) => ({
        label: helpUser
          ? `${
              item.isLeader !== undefined ? (item.isLeader ? 'Trưởng nhóm:' : 'Nhân viên:') : ''
            }  ${item.name_organization_path ?? ''}`
          : item.position,
        value: helpUser ? item.organization?.id : item.id,
      })) ?? []
    );
  }, [helpUser, organizationList.data]);

  const handleFocus = () => {
    dispatch(handleGetAllUser({page: 1, per_page: 10}))
      .unwrap()
      .then(res => {
        setAllUser(res.data);
      })
      .catch(() => {
        setAllUser([]);
      });
  };

  useEffect(() => {
    if (organizationList.data.length <= 1) {
      return;
    } else {
      dispatch(handleGetMyPeriodList({organization: organization?.value}));
    }
  }, [dispatch, organization, organizationList.data.length]);

  const userOptions = useMemo(() => {
    return (
      allUser?.data?.map((item: any) => ({
        label: `${item.name} / ${item.position} / ${item.company}`,
        organizations: item.organizations,
        value: item.id,
        image: item.avatar,
        helperText: item.company,
      })) ?? []
    );
  }, [allUser]);

  const otherCreateEnable = userInfo?.permission?.goals?.includes(
    PermissionListEnum.IMPORT_SUB_GOALS_FOR_OTHER,
  );
  const selfCreateEnable = userInfo?.permission?.goals?.includes(
    PermissionListEnum.IMPORT_SUB_GOALS_FOR_ME,
  );
  const excelEnable =otherCreateEnable || selfCreateEnable
  return (
    <div>
      {otherCreateEnable && (
        <>
          <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
            {`Tạo mục tiêu cho (nếu tạo cho bản thân thì để trống)`}
          </Typography>
          <Controller
            control={control}
            name="user"
            rules={{}}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <Select
                    style={{
                      width: '45%',
                    }}
                    placeholder="--- Chọn nhân viên ---"
                    options={userOptions}
                    value={value}
                    onSelect={(value: any) => {
                      const selectedUser = userOptions.find(
                        (item: any) => item.value === value?.value,
                      );
                      console.log('Selected user:', userOptions, selectedUser, value);
                      onChange(selectedUser);
                      setValue('organization', undefined);
                    }}
                    onPopupScroll={event => {
                      if (allUser.pagination.last_page < page) return;
                      const target = event.target as HTMLElement;
                      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                        dispatch(
                          handleGetAllUser({
                            page,
                            per_page: 10,
                            search: searchKey,
                          }),
                        )
                          .unwrap()
                          .then(res => {
                            setAllUser({
                              data: [...allUser.data, ...res.data.data],
                              pagination: res.data.pagination,
                            });
                          })
                          .catch(() => {
                            setAllUser(allUserInitial);
                          });
                        setPage(page + 1);
                      }
                    }}
                    onFetchOptions={searchKey => {
                      dispatch(
                        handleGetAllUser({
                          page: 1,
                          per_page: 10,
                          search: searchKey,
                        }),
                      )
                        .unwrap()
                        .then(res => {
                          setAllUser(res.data);
                        })
                        .catch(() => {
                          setAllUser(allUserInitial);
                        });
                      setSearchKey(searchKey);
                    }}
                    onBlur={() => {
                      setAllUser(allUserInitial);
                    }}
                    onFocus={() => handleFocus()}
                  />
                  {fieldState?.error?.message && (
                    <ErrorMessage error={fieldState?.error?.message} />
                  )}
                </div>
              );
            }}
          />
        </>
      )}
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
          <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
            Vai trò <span style={{color: '#FF3141'}}>*</span>
          </Typography>

          <Controller
            control={control}
            name="organization"
            rules={{
              required: 'Vui lòng chọn vai trò',
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    placeholder="--- Chọn phòng vai trò ---"
                    options={organizationOptions}
                    value={value}
                    onSelect={value => {
                      console.log('On select role: ', value);
                      onChange(value);
                    }}
                  />
                  {fieldState?.error?.message && (
                    <ErrorMessage error={fieldState?.error?.message} />
                  )}
                </div>
              );
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginLeft: '20px',
            marginRight: '20px',
          }}>
          <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
            Giai đoạn thực hiện <span style={{color: '#FF3141'}}>*</span>
          </Typography>

          <Controller
            control={control}
            name="phase"
            rules={{
              required: 'Vui lòng chọn giai đoạn',
            }}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    placeholder="--- Chọn giai đoạn ---"
                    options={periodListFilter}
                    value={value}
                    onSelect={value => {
                      if (!value) {
                        onChange(null);
                      } else onChange(value);
                    }}
                  />
                  {fieldState?.error?.message && (
                    <ErrorMessage error={fieldState?.error?.message} />
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
      {excelEnable && (
        <>
          <Typography style={{color: '#525252', marginBottom: '4px', marginTop: '12px'}}>
            Nhập danh sách mục tiêu con
          </Typography>
          <TemplateFile />

          <Controller
            control={control}
            name="children"
            rules={{}}
            render={({field: {onChange, value}, fieldState}) => {
              return (
                <div>
                  <SelectedFile
                    goals={value ?? []}
                    onChange={onChange}
                    onExcelError={onExcelError}
                  />
                </div>
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default Form;
