export const LOCALSTORAGE = {
  setItem: (name: string, value: string) => {
    if (typeof window !== "undefined") {
      return window.localStorage.setItem(name, value);
    }
    return null
  },
  getItem: (name: string) => {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem(name);
    }
    return null
  },
  removeItem: (name: string) => {
    if (typeof window !== "undefined") {
      return window.localStorage.removeItem(name);
    }
    return null
  }
}