import Modal from "src/components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Form from './Form';
import {handleCreateNewGroup, handleEditGroup} from "src/services/permissions.services";
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import {toast} from "react-toastify";

interface Props {
  open: boolean
  closeCb?: () => void;
  successCb?: () => void;
  errorCb?: () => void;
  selectedRole?: any
}

const defaultValues: { name: string, permissions: string[] } = {
  name: '',
  permissions: []
}

const AddRole = ({ open, closeCb, selectedRole, successCb, errorCb }: Props) => {
  const {permissionList} = useAppSelector((state) => state.permission);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const addRoleMethods = useForm({
    defaultValues
  });

  const handleSuccess = () => {
    setLoading(false);
    addRoleMethods.reset(defaultValues);
    if (!selectedRole) {
      toast.success("Tạo vai trò thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.success("Chỉnh sửa vai trò thành công", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    successCb && successCb();
  }

  const handleError = (err: any) => {
    setLoading(false);
    const message = err?.response?.data?.message;
    addRoleMethods.reset(defaultValues)
    toast.error(message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    errorCb && errorCb()
  }

  const addRole = (data: { name: string, permissions: string[] }) => {
    setLoading(true);
    if(!selectedRole) {
      dispatch(handleCreateNewGroup(data))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    } else {
      dispatch(handleEditGroup({...data, id: selectedRole?.id}))
        .unwrap()
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  useEffect(() => {
    if (!selectedRole) return;
    addRoleMethods.reset({
      name: selectedRole.name,
      permissions: selectedRole?.permissions?.map((item: any) => item.id)
    })
  }, [addRoleMethods, permissionList.data, selectedRole])

  return (
    <FormProvider {...addRoleMethods}>
      <form onSubmit={addRoleMethods.handleSubmit(addRole)}>
        <Modal
          open={open}
          loadingSubmitButton={loading}
          title={selectedRole ? "Chỉnh sửa vai trò" : "Thêm vai trò"}
          okText="Lưu thông tin"
          cancelText="Chọn lại"
          onCancel={() => {
            addRoleMethods.reset(defaultValues)
            closeCb && closeCb();
          }}
          style={{
            minWidth: '800px'
          }}
        >
          <Form/>
        </Modal>
      </form>
    </FormProvider>
  )
}

export default AddRole