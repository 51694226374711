import {Avatar, Space, Typography} from "antd";
import {useAppSelector} from "src/redux/hooks";
import useRole from "src/hooks/useRole";
import './styles.scss';

const CustomHeader = () => {
  const {userInfo} = useAppSelector((state) => state.user);
  const {isAdmin} = useRole();

  return (
    <Space className="header-wrapper" style={{ gap: '16px' }}>
      {/*<Notifications/>*/}
      <Space className="user-infor">
        <Avatar src={userInfo?.avatar}>A</Avatar>
        <Space direction='vertical' style={{ alignItems: 'flex-start', gap: 0 }}>
          <Typography.Text className="name">{userInfo?.name || '-'}</Typography.Text>
          <Typography.Text style={{ fontSize: 12, color: '#A3A3A3' }}>{isAdmin ? 'Admin' : 'Nhân viên'}</Typography.Text>
        </Space>
      </Space>
    </Space>
  )
}

export default CustomHeader;
